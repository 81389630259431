import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ResetWarningModal({handleClose, show, updateCategory, cancelUpdate}) {
    const {t} = useTranslation();

    const addClickHandler = () => {
        updateCategory()
        handleClose();
    }

    const cancelClickHandler = () => {
        cancelUpdate();
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t('masterData:groupResetWarning', "Si vous décidez de modifier le type des entreprises, votre précedente selection sera supprimée.")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={cancelClickHandler}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:update', "Modifier")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ResetWarningModal
