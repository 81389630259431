import React, { useEffect, useState, useCallback } from 'react'
import './PublicationRegieModal.css';
import Axios from '../../../../axios-edipub';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import Spinner from '../../../UI/Spinner/Spinner';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { Notyf } from "notyf";

function PublicationRegieModal({ show, handleClose, mandat, nbSelectedRegie, setNbSelectedRegie, updateMandatData, mandatUid }) {
    const { t } = useTranslation();

    const [mediasCodesLabels, setMediasCodesLabels] = useState();
    const [mediasCodes, setMediasCodes] = useState();
    const [regieState, setRegieState] = useState()
    const [updateLoading, setUpdateLoading] = useState(false);
    const [selectedMedias, setSelectedMedias] = useState(mandat.selectedMediasPublication)
    const notyf = new Notyf();

    useEffect(() => {
        setUpdateLoading(true)
        Axios.get("document/" + mandatUid + "/publicationRegieMedias")
            .then(response => {
                setNbSelectedRegie(response?.data?.nbSelectedRegies)
                let regieStateTemp = []
                let mediaCodesTemp = response?.data?.mediasCodes?.split(",")
                let mediasCodesLabelsTemp = response?.data?.mediasCodesLabels
                let regiesTemp = response?.data?.regies
                regiesTemp?.map(regie => {
                    let regieTmp = regie
                    regieTmp.nbSelection = 0
                    mediaCodesTemp.map(mediaCode => {
                        if (regie.medias.includes(mediaCode)) {
                            regieTmp[mediaCode] = true
                            if ((mandat.selectedMediasPublication && mandat.selectedMediasPublication.includes("|" + mediaCode + "|"))) {
                                regieTmp.nbSelection++
                            }
                        }
                        else regieTmp[mediaCode] = false
                    })
                    regieStateTemp.push(regieTmp)
                })
                getNbSelectedRegies(regieStateTemp);
                setMediasCodesLabels(mediasCodesLabelsTemp)
                setRegieState(regieStateTemp)
                setMediasCodes(mediaCodesTemp)
                setUpdateLoading(false)
            }).catch(err => {
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
            })
    }, [])

    const enablePublishBtn = () => {
        let foundChangeRegies = regieState && regieState.findIndex(regie => ((regie.published === "1" && !regie.selected) || ((!regie.published || regie.published === "0") && regie.selected)))
        let foundChangeMedia = selectedMedias !== mandat.selectedMediasPublication
        return foundChangeRegies !== -1 || foundChangeMedia;
    }

    const mediaClickHandler = (code, checked) => {
        const mediaCode = code
        const mediaChecked = !checked
        if (mediaChecked) {
            if (!selectedMedias) {
                setSelectedMedias("|" + mediaCode + "|")
            } else {
                setSelectedMedias(selectedMedias + mediaCode + "|")
            }

        } else {
            setSelectedMedias(selectedMedias.replace("|" + mediaCode + "|", "|"))
        }
        let tempRegieState = regieState
        tempRegieState?.map(regState => {
            if (regState[mediaCode]) {
                if (mediaChecked) {
                    regState.nbSelection = regState.nbSelection + 1
                    regState.selected = true;
                } else {
                    if (regState.nbSelection <= 1) {
                        regState.selected = false;
                    }
                    regState.nbSelection = regState.nbSelection - 1
                }
            }
        })
        setRegieState(tempRegieState)
        getNbSelectedRegies(tempRegieState)
    }


    const getMediaCodeLabel = (code) => {
        return mediasCodesLabels[code].toUpperCase()
    }

    const regieClickHandler = (e) => {

        const regieRef = e.target.name
        const regieAction = e.target.checked ? true : false
        let selectedMediasTmp = "|";
        let tempRegieState = regieState

        //update les colonne en vert ou en bleu en fonction de la regie cliquée  et ajuste le nbSelection 
        mediasCodes.map(mediaCode => {
            let missingRegieCpt = 0
            let foundRegieCpt = 0
            let totalRegieCpt = 0
            regieState.filter(regie => regie[mediaCode] === true).map(regie => {
                totalRegieCpt++
                let regieSelected = (regieRef === regie.uid) ? regieAction : regie.selected
                regieSelected ? foundRegieCpt++ : missingRegieCpt++
            })
            let mediaChecked = false
            let mediaUnchecked = false
            if (totalRegieCpt > 0 && !missingRegieCpt) {
                selectedMediasTmp += mediaCode + '|'
                mediaChecked = selectedMedias && !selectedMedias.includes("|" + mediaCode + "|")
            } else {
                mediaUnchecked = selectedMedias && selectedMedias.includes("|" + mediaCode + "|")
            }
            tempRegieState?.map(regState => {
                if (regState[mediaCode]) {
                    if (mediaChecked)
                        regState.nbSelection = regState.nbSelection + 1
                    else if (mediaUnchecked)
                        regState.nbSelection = regState.nbSelection > 0 ? regState.nbSelection - 1 : 0
                }
            })
        })

        //coche la regie
        tempRegieState?.map(regState => {
            if (regState.uid == regieRef) {
                regState.selected = regieAction;
            }
        })
        setSelectedMedias(selectedMediasTmp)
        setRegieState(tempRegieState)
        getNbSelectedRegies(tempRegieState)
    }

    const getNbSelectedRegies = (tempRegieState) => {
        var count = 0
        tempRegieState?.map(reg => {
            if (reg.selected == true) count += 1
        })
        setNbSelectedRegie(count)
    }

    const savePublication = () => {
        let finalState = {
            selectedRegies: regieState.filter(reg => { return reg.selected == true || reg?.alreadySelected == true }),
            selectedMedias: (selectedMedias === "|" ? null : selectedMedias)
        }
        Axios.put("document/" + mandat.uid + "/publicationRegieMedias", finalState)
            .then(response => {
                if (response) {
                    updateMandatData({ ...mandat, selectedMediasPublication: selectedMedias })
                }
            })
        handleClose();
    }

    const cancelClickHandler = () => {
        handleClose();
    }

    let mediasCodeHeader = mediasCodesLabels && mediasCodes?.map(code => {
        return (
            <th className="rotate">
                <div className="div-rotated">
                    <div className="div-column-title"
                        style={{ color: (selectedMedias !== null && selectedMedias.includes("|" + code + "|")) ? "rgb(77, 202, 118)" : "rgb(91, 155, 213)" }}
                        onClick={(e) => mediaClickHandler(code, (selectedMedias !== null && selectedMedias.includes("|" + code + "|")))}
                    >{getMediaCodeLabel(code)}
                    </div>
                </div>
            </th>
        )
    })

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('default:publication', "Publication")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "100%" }}>
                    <p style={{ marginBottom: "16px" }}>{"Cliquez sur un média en en-tête pour sélectionner toutes les régies associées à ce média, ou cochez des régies en particulier dans la première colonne"}</p>
                    <table className="table-header-rotated">
                        <thead>
                            <tr>
                                <th className="regieTableTd" style={{ color: (nbSelectedRegie !== null && nbSelectedRegie > 0) ? "rgb(77, 202, 118)" : "rgb(91, 155, 213)" }}>{nbSelectedRegie} R</th>
                                <th></th>
                                {mediasCodeHeader}
                            </tr>
                        </thead>
                        <tbody>
                            {(typeof (regieState) != "undefined" && typeof (mediasCodes) != "undefined" && typeof (mediasCodesLabels) != "undefined") &&
                                regieState?.map((regie, index) => {
                                    return (
                                        <tr className="regieTableTr2">
                                            <th className="regieTableTh">
                                                <div className="form-check">
                                                    <input class="form-check-input" type="checkbox" checked={regie.selected}
                                                        onChange={(e) => regieClickHandler(e)} // onChange={(e) => testClickHandler(e)}    // onChange={(e) => regieClickHandler(e)} 
                                                        name={regie.uid} />
                                                </div>
                                            </th>
                                            <td className="row-header2 regieTableNextTh">
                                                {regie.official_name}
                                            </td>
                                            {
                                                mediasCodes?.map(mc => {
                                                    return (<td> {regie[mc] == true ? <CloseIcon fontSize="small" /> : null}</td>)
                                                })
                                            }
                                        </tr>)
                                })
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    {
                        updateLoading ?
                            <Spinner />
                            :
                            <Button
                                variant="primary"
                                onClick={() => savePublication()}
                                disabled={!enablePublishBtn()}
                            >
                                {t('default:publish', "Publier")}
                            </Button>
                    }
                    <Button variant="secondary" onClick={cancelClickHandler}>
                        {t('default:close', "Fermer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData))
})
export default connect(mapStateToProps, mapDispatchToProps)(PublicationRegieModal)