import React, { useCallback, useEffect, useState, useRef } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility';


function ListManagerDirectionFunctions(props) {
    let {
        handleFormChanged,
    } = props

    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [itemsToUpdate, setItemsToUpdate] = useState([]);
    const [itemsToDelete, setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({});
    const [searchSelectItemsValue, setSearchSelectItemsValue] = useState({ departmentName: "", functionName: "" });
    const [departmentList, setDepartmentList] = useState()
    const [updateLoading, setUpdateLoading] = useState(false);

    const getFunctionsList = useCallback(() => {
        Axios.get('fonction', { params: searchSelectItemsValue }
        ).then(response => {
            setItems(response.data?.functions)
            setDepartmentList(response?.data?.departments)
        })
    }, [])

    const searchItemsWithFilterHandle = (e) => {
        const name = e.target?.name
        const value = e?.target?.value
        let tempData = {
            ...searchSelectItemsValue,
            [name]: value
        }
        setSearchSelectItemsValue(tempData)
        Axios.get('fonction', { params: tempData }
        ).then(response => {
            setItems(response.data?.functions)
        })
    }

    const functionAddHandler = () => {
        Axios.post('/fonction', newItem
        ).then(response => {
            setItemsToUpdate([])
            setItemsToDelete([])
            if (response?.data?.functionExist) {
                notyf.error(t("masterData:existFunctionLabel", "Cette fonction existe déjà!"))
            } else {
                getFunctionsList()
                notyf.success('Ajout sauvegardé')

            }
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })
    }

    const functionsUpdateHandler = () => {
        setUpdateLoading(true)
        let data = {
            'functionsToUpdate': itemsToUpdate,
            'functionsToDelete': itemsToDelete
        }
        Axios.put(`/fonction//editall`, data)
            .then(response => {
                setSearchSelectItemsValue({ departmentName: "", functionName: "" })
                setItemsToUpdate([])
                setItemsToDelete([])
                getFunctionsList()
                setUpdateLoading(false)
                handleFormChanged(false)
                notyf.success('Modifications sauvegardées')
            }).catch(err => {
                setUpdateLoading(false)
                notyf.error('Une erreur s\'est produite !')
            })
    }

    return (
        <ListManagerAbstract
            title={t('masterData:directionFunctions', "Fonctions & direction")}
            addNewTitle={t('masterData:addNewFunction', "Ajouter une nouvelle fonction")}
            addNewAlreadyExistLabel={t("masterData:existFunctionLabel", "Cette fonction existe déjà!")}
            fieldColumnNames={{ label_agence: t('masterData:functionName', 'Nom fonction :'), departmentName: t("master:deprtmentName", 'Nom département') }}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            uniqueFields={["Label_agence"]}
            mandatoryFields={["label_agence"]}
            setNewItem={setNewItem}
            getItemList={getFunctionsList}
            itemAddHandler={functionAddHandler}
            itemsUpdateHandler={functionsUpdateHandler}
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading}
            readonlyFields={["departmentName"]}
            selectFields={["departmentName"]}
            itemsForSelectList={[{ departmentName: departmentList }]}
            filterListItemsForSelectList={true}
            searchItemsWithFilterHandle={searchItemsWithFilterHandle}
            searchSelectItemsValue={searchSelectItemsValue}
        />
    )
}

export default ListManagerDirectionFunctions