import React, {useState } from 'react'  
import { connect } from 'react-redux';
import App from './App';
  

function AppBypassForSso(props) {

  const {ssoReconnectUrl, ssoDisconnectUrl} = props;
  const [quitApp, setQuitApp] = useState(false);
  const [quitAppAndDisconnect, setQuitAppAndDisconnect] = useState(false);
 
  const cleanLocalStorageBeforeExit = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate')
        localStorage.removeItem('userId')
        localStorage.removeItem('login')
        localStorage.removeItem('role')
        localStorage.removeItem('isSso')
        localStorage.removeItem('ssoName')  
        localStorage.removeItem('ssoReconnectUrl')  
  } 

  if (quitApp){ 
    let ssoReconnectUrlVar = ssoReconnectUrl ?  ssoReconnectUrl : localStorage.getItem('ssoReconnectUrl') 
    cleanLocalStorageBeforeExit()
    console.log(" load clinic  expiré on  redirige " + ssoReconnectUrlVar) 
    window.location.replace( ssoReconnectUrlVar);  //si ssoReconnectUrlVar est vide cas au limites  faut rediriger vers page blanche please connect to SSO 
    return null 
  } else if (quitAppAndDisconnect){
    let ssoReconnectUrlVar = ssoReconnectUrl ?  ssoReconnectUrl : localStorage.getItem('ssoReconnectUrl')      
    let ssoDisconnectUrlVar =   ssoDisconnectUrl ? ssoDisconnectUrl : ssoReconnectUrlVar  
    cleanLocalStorageBeforeExit()
    console.log("load clinic  deconnecté on redirige " + ssoDisconnectUrlVar) 
    window.location.replace( ssoDisconnectUrlVar);   //si ssoDisconnectUrlVar est vide cas au limites  faut rediriger vers page blanche please connect to SSO 
    return null  
  } else { 
      return (
        <App  
          setQuitApp={setQuitApp}
          setQuitAppAndDisconnect={setQuitAppAndDisconnect} />
      ); 
  } 
} 

const mapStateToProps = state => {
  return { 
    ssoReconnectUrl: state.auth.ssoReconnectUrl,  
    ssoDisconnectUrl: state.auth.ssoDisconnectUrl,
  }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AppBypassForSso);
