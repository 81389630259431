import React, { useCallback, useEffect, useState, useRef } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility';


function ListManagerMedia(props) {
    let {
        handleFormChanged,
    } = props

    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [itemsToUpdate, setItemsToUpdate] = useState([]);
    const [itemsToDelete, setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({});
    const [updateLoading, setUpdateLoading] = useState(false);

    const getMediaList = useCallback(() => {
        Axios.get('media', {
            params: {
                orderBy: "CAST(SUBSTRING_INDEX(`position`,'-',1) AS INT) , IF(`position` LIKE '%-%', 1, 0), CAST(SUBSTRING_INDEX(`position`,'-',-1) AS INT) "
            }
        }).then(response => {
            setItems(response.data)
        })
    }, [])

    const mediaAddHandler = () => {

        Axios.post('/media', newItem
        ).then(response => {
            getMediaList()
            notyf.success('Ajout sauvegardé')
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })
    }

    const mediasUpdateHandler = () => {
        setUpdateLoading(true)
        let data = {
            'mediasToUpdate': itemsToUpdate,
            'mediasToDelete': itemsToDelete
        }
        Axios.put(`/media//editall`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getMediaList()
                setUpdateLoading(false)
                handleFormChanged(false)
                notyf.success('Modifications sauvegardées')
            }).catch(err => {
                setUpdateLoading(false)
                notyf.error('Une erreur s\'est produite !')
            })
    }

    return (
        <ListManagerAbstract
            title={t('masterData:medias', "Médias")}
            addNewTitle={t('masterData:addNewMedia', "Ajouter un nouveau média")}
            addNewAlreadyExistLabel={t("masterData:existMediaLabel", "Ce média existe déjà!")}
            fieldColumnNames={{ label: 'Francais', label_en: 'Anglais', code_media: 'Code', position: 'Position (ex : 1, 1-2)' }}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            uniqueFields={["code_media"]}
            mandatoryFields={["label", "label_en", "code_media"]}
            setNewItem={setNewItem}
            getItemList={getMediaList}
            itemAddHandler={mediaAddHandler}
            itemsUpdateHandler={mediasUpdateHandler}
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading}

        />
    )
}

export default ListManagerMedia