import React, { useCallback, useEffect, useState } from 'react'
import './MarketingModeModal.css';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function MarketingModeModal({agencyUid, handleClose, show, addMarketingModes,lang}) {
    const [entityMarketingModes , setEntityMarketingModes] = useState([]);
    const [selectedMarketingModes, setSelectedMarketingModes] = useState([]);
    const [marketingModesToAdd, setMarketingModesToAdd] = useState(null);
    const [filtredMarketingModes, setFiltredMarketingModes] = useState([]);
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    const getMarketingModesList = useCallback(() => {
        Axios.get('marketingmode').then(response => {
            setEntityMarketingModes(response.data)
            setFiltredMarketingModes(response.data)
        })
    }, [])

    // const addMediaToList = useCallback((e) => {
    //     e.preventDefault();
    //     if(mediaToAdd && mediaToAdd.trim() !== ""){
    //         Axios.post('media', {
    //             label : mediaToAdd
    //         }).then(response => {
    //             getMediasList()
    //             setMediaToAdd('');
    //         })
    //     }
    // }, [mediaToAdd])
    

    useEffect(() => {
        getMarketingModesList()
        return () => {
            setSelectedMarketingModes([]);
        }
    }, [agencyUid])

    const marketingModesFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredMarketingModes;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredMarketingModes(entityMarketingModes)
        } else {
            arrayFiltred = entityMarketingModes.filter(row => {
                if (row.label.toLowerCase().includes(searchQuery.trim().toLowerCase()))
                    return true
                else return false
            })
            setFiltredMarketingModes(arrayFiltred)
        }    
    }

    const SelectMarketingMode = (marketingMode) => {
        let marketingModeIndex = selectedMarketingModes.findIndex(row => row.uid === marketingMode.uid);
        let selectedTemp = [...selectedMarketingModes];
        if(marketingModeIndex >= 0)
            selectedTemp.splice(marketingModeIndex,1)
        else 
            selectedTemp = [...selectedMarketingModes, marketingMode];
        setSelectedMarketingModes(selectedTemp);
    }

    const selectAllMarketingModes = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredMarketingModes.map(marketingMode => ({uid : marketingMode.uid, label: marketingMode.label,label_en: marketingMode.label_en, new: true}))
        }
        setSelectedMarketingModes(selectedTemp)
    }

    const addClickHandler = () => {
        addMarketingModes(selectedMarketingModes)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:markModeModalTitle', "Ajouter des modes de commercialisation")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('default:search',"Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => marketingModesFilterHandler(e.target.value)} 
                            />
                            <div 
                                className="input-group-append" 
                                style={{height: "100%"}}    
                            >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedMarketingModes?.length} {t('mandat:selectedMarkMode', "mode(s) sélectionné(s)")} </p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllMedias" onChange={(e) => selectAllMarketingModes(e)} />
                                <label className="form-check-label" for="selectAllMedias" >
                                    {t('mandat:selectAll', "Selectionner tout")} 
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items">
                        {
                            filtredMarketingModes?.length > 0 ? filtredMarketingModes.map(marketingMode => (
                                <div className="productsModal__item">
                                    <div className="productsModal__itemIcon" onClick={() => SelectMarketingMode({uid : marketingMode.uid, label : marketingMode.label ,label_en : marketingMode.label_en , new : true})}>
                                        {
                                            selectedMarketingModes.some(row => row.uid === marketingMode.uid) ? (
                                                <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                            ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                        }
                                        
                                    </div>
                                    <div className="productsModal__itemName">
                                        {lang==="FR" ? marketingMode.label : marketingMode.label_en }
                                    </div>
                                </div>
                            )) : <div>{t('mandat:noMarkModes','Aucun mode de commercialisation à afficher')} </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t("default:add", "Ajouter")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MarketingModeModal
