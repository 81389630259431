import React, { useState, useEffect } from 'react'
import './TableBody.css'
import EntitySupModal from './EntitySupModal/EntitySupModal'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'; 
import { CTooltip} from '../../../shared/utility';
import Axios from '../../../axios-edipub';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { getNotyfObject } from '../../../shared/utility';
import EditEntityModal from './EditEntityModal/EditEntityModal';

function TableBody(props) {
    const {data, formatDate, refreshContent, filters} = props;
    const {t} = useTranslation(); 
    const notyf = getNotyfObject() 
    const [showSupModal, setShowSupModal] = useState(false); 
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState() 
   
    const deleteHanlder = (row) =>{
        setSelectedRow(row) 
        setShowSupModal(!showSupModal)
    }

    const editHandler = (row) =>{
        setSelectedRow(row) 
        setShowEditModal(!showEditModal)
    } 

    const deleteManualEntityToken = () => {
        Axios.delete('/manualEntityToken/'+ selectedRow.uid+'/drop').
            then(response => {
                if(response.data.Success){
                    setShowSupModal(false)
                    notyf.success(response.data.Success)
                    refreshContent(filters)
                }
                else
                    notyf.error(response.data.Failed)
        })
    }

    return (
        <>    
            {
                data?.map(row => (
                    <tr key={row.id} className="tableBody__tr" style={{ cursor:   row.status == "PENDING"  ? 'pointer' : 'default' }} >   
                        <td onClick={() =>  row.status == "PENDING" && editHandler(row)}>{ row.login }</td>
                        <td onClick={() =>  row.status == "PENDING" &&   editHandler(row)}>{ row.creatorName }</td>
                        <td onClick={() =>  row.status == "PENDING" &&  editHandler(row)}>{ (row?.entity_official_name ? row.entity_official_name : '-')}</td>
                        <td onClick={() =>  row.status == "PENDING" &&  editHandler(row)}>{ formatDate(+row.creationDate) }</td> 
                        <td onClick={() =>  row.status == "PENDING" &&  editHandler(row)}>{ row?.status && ['NEW','PENDING','APPROVED'].includes(row.status)  ? t('manualEntity:'+row.status):'-'  }</td> 
                        <td>
                            <div style={{display:"flex"}}>  
                                <> 
                                {
                                    row.status == "PENDING" ? 
                                    <CTooltip title={t('manualEntity:validateAdvertiser',"Valider l'annonceur")} > 
                                        
                                            <EditIcon className="tableBody_editIcon" 
                                            onClick={() => editHandler(row)} 
                                            fontSize="small"/>  
                                        
                                    </CTooltip> :null
                                }
                                <CTooltip title={t('default:delete', "Supprimer")}> 
                                    <ClearIcon 
                                        className="tableBody_clearIcon" fontSize="small"
                                        onClick={() => deleteHanlder(row)} />     
                                </CTooltip> 
                                </>  
                            </div>
                         </td> 
                    </tr> 
                )) 
            }
            { 
                showSupModal ? <EntitySupModal
                    show={showSupModal}
                    selectedRow={selectedRow}
                    handleClose={() => setShowSupModal(false)}
                    contactReference={selectedRow.login}
                    creatorReference={selectedRow.creatorName}
                    advertiserReference={selectedRow.entity_official_name} 
                    deleteManualEntityToken={deleteManualEntityToken} /> : null
            } 
            {
                showEditModal ? <EditEntityModal
                    show={showEditModal}
                    selectedRow={selectedRow}
                    handleClose={() => setShowEditModal(false)}
                    isAuth = {true}
                    uid = {selectedRow.uid} 
                    parentRefreshContent = {refreshContent}
                 /> : null 
            } 
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
