import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateEntityComponent from '../../../../components/TokenAccess/CreateEntity/CreateEntityComponent.js';
import { useTranslation } from 'react-i18next';
import PageChangeModal from './../PageChangeModal/PageChangeModal'; 

function EditEntityModal({isAuth, uid, show, handleClose, parentRefreshContent}) {
    const {t} = useTranslation(); 
    const wrapperRef = useRef(null);
    const [pageChangeModal, setPageChangeModal] = useState(null); 
    const divRef = useRef(); 

    const clickOutSideHandler = e => { 
        if(divRef.current.contains(e.target)    ||  
           (document.getElementById("manualEntityBandAddressModalId") && document.getElementById("manualEntityBandAddressModalId").contains(e.target)) ||
           (document.getElementById("manualEntityIdentificationNumModalId") && document.getElementById("manualEntityIdentificationNumModalId").contains(e.target))           
          ) { 
            return   // inside the div
        }
        if(e.offsetX > e.target.clientWidth){ 
            return // click scroll bar
        } 
        handlePageChangeModal(() => handleClose()) // outside click
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler); 
        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    const handlePageChangeModal = callback => { 
        const modal = React.createElement(PageChangeModal, {handleClose: confirm => {setPageChangeModal(null)}, callback: callback});
        setPageChangeModal(modal); 
    } 

    return (  
        <>
        <Modal 
            show={show} 
            onHide={handleClose} 
            dialogClassName="modal-xl">
            <div ref={divRef}  >   
            {pageChangeModal} 
                <Modal.Header closeButton>
                    <Modal.Title>   {   t("manualEntity:adveriserCreation") }    </Modal.Title>
                </Modal.Header> 
                <Modal.Body className="ADMCreationModal__body" style={{width: "100%"}}> 
                    <CreateEntityComponent 
                        isAuth = {true}
                        uid = {uid}
                        handleClose = {handleClose}
                        parentRefreshContent={parentRefreshContent}> 
                    </CreateEntityComponent> 
                </Modal.Body> 
            </div> 
        </Modal>
        </>
    )
} 

export default  EditEntityModal
 


