import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import './RegiesModal.css'
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

function RegiesModal({agencyUid, handleClose, show, addRegies, mediaFilters}) {
    const [entityRegies , setEntityRegies] = useState([]);
    const [selectedRegies, setSelectedRegies] = useState([]);
    const [filtredRegies, setFiltredRegies] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const {t} = useTranslation();

    const getRegiesList = useCallback(() => {
        const mediaFilter = mediaFilters.map(mf => "'" + mf.uid + "'").join(", ");
        let filter = {
            category: "regie",
            searchQuery: searchQuery,
            mediaFilter: mediaFilter
        }
        Axios.get('/entity', {
            params: filter
        })
        .then(res => {
            setEntityRegies(res.data.entities)
            setFiltredRegies(res.data.entities)
        })
    }, [searchQuery])

    useEffect(() => {
        getRegiesList()
    }, [agencyUid, searchQuery, mediaFilters])

    const regiesFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredRegies;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredRegies(entityRegies)
        } else {
            arrayFiltred = entityRegies.filter(row => {
                if (row?.commercial_name.toLowerCase().includes(searchQuery.trim().toLowerCase()) || 
                    row?.siret.toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
                    row?.address_line.toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
                    row?.post_code.toLowerCase().includes(searchQuery.trim().toLowerCase()) 
                    )
                    return true
                else return false
            })
            setFiltredRegies(arrayFiltred)
        }    
    }
    

    const SelectRegie = (regie) => {
        let regieIndex = selectedRegies.findIndex(row => row.uid === regie.uid);
        let selectedTemp = [...selectedRegies];
        if(regieIndex >= 0)
            selectedTemp.splice(regieIndex,1)
        else 
            selectedTemp = [...selectedRegies, regie];
        setSelectedRegies(selectedTemp);
    }

    const selectAllRegies = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredRegies.map(regie => ({
                uid : regie.uid, 
                commercial_name: regie.commercial_name, 
                official_name : regie.official_name,
                relatedMedias : regie.relatedMedias,
                new: true}))
        }
        setSelectedRegies(selectedTemp)
    }

    const addClickHandler = () => {
        addRegies(selectedRegies)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:addRegies', "Ajouter des régies")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('default:search', "Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                //onChange={(e) => regiesFilterHandler(e.target.value)} 
                                />
                            <div 
                                className="input-group-append" 
                                style={{height: "100%"}}
                            >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedRegies?.length} régie(s) sélectionnée(s)</p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllRegies" onChange={(e) => selectAllRegies(e)} />
                                <label className="form-check-label" for="selectAllRegies" >
                                    {t('mandat:selectAll', "Selectionner tout")}
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items bg-white">
                        <table className="table">
                            <thead style={{backgroundColor: "rgb(250, 250, 249)"}}>
                                <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('default:name', "NOM")} </th>
                                <th scope="col">{t('default:siret', "SIRET")}</th>
                                <th scope="col">{t('default:addressSS', "Adresse SS")} </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    filtredRegies?.length > 0 ? filtredRegies.map(regie => (
                                        <tr>
                                            <td>
                                                <div 
                                                    className="productsModal__itemIcon" 
                                                    onClick={() => SelectRegie({
                                                        uid : regie.uid, 
                                                        commercial_name: regie.commercial_name, 
                                                        official_name : regie.official_name,
                                                        relatedMedias : regie.relatedMedias, 
                                                        new : true
                                                    })}>
                                                    {
                                                        selectedRegies.some(row => row.uid === regie.uid) ? (
                                                            <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                                        ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                                    }
                                                </div>
                                            </td>
                                            <td>{regie.commercial_name} </td>
                                            <td>{regie.siret} </td>
                                            <td>{regie.address_line} {regie.post_code} </td>
                                        </tr>
                                    )) : <div>{t("mandat:noRegies","Aucune régie à afficher")} </div>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")} 
                </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default RegiesModal
