import React from 'react';
 



// const UploadButton = styled.button`
//     color: ${props => props.theme.colors.primary};
//     border: 1px solid ${props => props.theme.colors.primary};
//     padding: 0.125rem 1rem;
//     border-radius: 8px;
//     background-color: white;
//     font-size: 0.8rem;
//     display: flex;
//     border: ${({border, theme}) => border ? "1px solid theme.colors.primary" : "none"};
//     &:hover {
//         background-color: ${props => props.theme.colors.primary};
//         color: #ffffff;
//     }
// `;

//function ModificationsChat({document, timelineData, getMandatTimelineEvents, createMandatTimelineEvent, admStatus, type}) {
 
  function FileUploaderBtn ({btnLabel, name, handleChange, icon ,border})   {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <button  className="btn btn-secondary btn-sm"  onClick={handleClick}>
        <span>
            {btnLabel || null}
        </span>
        {icon || null}
      </button>
      <input 
        type="file"
        accept=".pdf"
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e)}
        style={{display:'none'}} 
        name={name}
      /> 
    </>
  );

};
export default FileUploaderBtn;