import React, { useCallback, useState } from 'react' 
import { Button, Modal } from 'react-bootstrap';   
import { useTranslation } from 'react-i18next';  
 
function EditClauseModal({handleClose, show, clauseUid,clauseLabel, updateClauseLabel}) {
    const {t} = useTranslation(); 
    const [label, setLabel] = useState(clauseLabel) 

    const editClickHandler = () => {
        updateClauseLabel(clauseUid, label)
        handleClose();
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:clauseModal_title', "Modification de la clause")}  
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div className="ADMCreationModal__description__container">
                        <h5>{t('default:clause', "Clause")}</h5>
                        <textarea 
                            className="form-control" 
                            name="description"
                            rows="3"
                            maxLength={1024}
                            value={label || ''}
                            onChange={(e) => setLabel(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => editClickHandler()}>
                    {t('default:update', "Modifier")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
 
 
export default EditClauseModal