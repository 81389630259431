import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

function SupportsModal({agencyUid, handleClose, show, addSupports, regies, medias}) {
    const [entitySupports , setEntitySupports] = useState([]);
    const [selectedSupports, setSelectedSupports] = useState([]);
    const [supportToAdd, setSupportToAdd] = useState("");
    const [filtredSupports, setFiltredSupports] = useState([]);
    const {t} = useTranslation();

    const getSupportsList = useCallback(() => {
        const mediasFilter = medias.filter(m => !m.add).map(m => "'"+m.uid+"'").join(", ");
        const regiesFilter = regies.map(r => "'"+r.uid+"'").join(", ");
        Axios.get('entity//support', {
            params: {
                regiesFilter: regiesFilter,
                mediasFilter
            }
        }).then(response => {
            setFiltredSupports(response.data)
            setEntitySupports(response.data)
        })
    }, [regies, medias])

    const addSupportToList = useCallback((e) => {
        e.preventDefault();
        if(supportToAdd && supportToAdd.trim() !== ""){
            let newSupport = {add: true, label: supportToAdd, new: true};
            addSupports([newSupport]);
            setSupportToAdd('');
            handleClose();
        }
    }, [supportToAdd])
    

    useEffect(() => {
        getSupportsList()
    }, [agencyUid])

    const supportsFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredSupports;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredSupports(entitySupports)
        } else {
            arrayFiltred = entitySupports.filter(row => 
                row?.supportLabel?.toLowerCase()?.includes(searchQuery?.trim()?.toLowerCase()) ||
                row?.supportCode?.toLowerCase()?.includes(searchQuery?.trim()?.toLowerCase())
            )
            setFiltredSupports(arrayFiltred)
        }    
    }

    const SelectSupport = (support) => {
        let supportIndex = selectedSupports.findIndex(row => row.uid === support.uid);
        let selectedTemp = [...selectedSupports];
        if(supportIndex >= 0)
            selectedTemp.splice(supportIndex,1)
        else 
            selectedTemp = [...selectedSupports, support];
        setSelectedSupports(selectedTemp);
    }

    const selectAllSupports = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredSupports.map(support => ({
                uid : support.uid, 
                label: support.supportLabel, 
                code: support.supportCode, 
                relatedMedia : support.relatedMedia,
                relatedRegie : support.relatedRegie,
                new: true}))
        }
        setSelectedSupports(selectedTemp)
    }

    const addClickHandler = () => {
        addSupports(selectedSupports)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:addSupports', "Ajouter des supports")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('default:search', "Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => supportsFilterHandler(e.target.value)} />
                            <div 
                                className="input-group-append" 
                                style={{height: "100%"}}
                            >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedSupports?.length} support(s) sélectionné(s)</p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllSupports" onChange={(e) => selectAllSupports(e)} />
                                <label className="form-check-label" for="selectAllSupports" >
                                    {t('mandat:selectAll', "Selectionner tout")}
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items">
                        {
                            filtredSupports?.length > 0 ? filtredSupports.map(support => (
                                <div className="productsModal__item" key={support.id}>
                                    <div 
                                        className="productsModal__itemIcon" 
                                        onClick={() => SelectSupport({
                                            uid : support.uid, 
                                            label : support.supportLabel, 
                                            code : support.supportCode, 
                                            new : true,
                                            relatedMedia : support.relatedMedia,
                                            relatedRegie : support.relatedRegie
                                            })}>
                                        {
                                            selectedSupports.some(row => row.uid === support.uid) ? (
                                                <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                            ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                        }
                                        
                                    </div>
                                    <div className="productsModal__itemName">
                                        {support.supportLabel + (support.supportCode ? " ("+support.supportCode+")" : "")}
                                    </div>
                                </div>
                            )) : <div>{t('mandat:noSupports', "Aucun support à afficher")} </div>
                        }
                    </div>
                    <div className="row" style={{width: "100%", margin: "0", padding: "1rem"}}>
                        <form className="d-flex" style={{height: "30px"}}>
                            <div className="form-group mr-2" style={{height: "100%"}}>
                                {/* <label for="mediaToAddInput">Champ libre</label> */}
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="mediaToAddInput" 
                                    aria-describedby="emailHelp" 
                                    placeholder={t("mandat:supportToAdd","Support à ajouter")} 
                                    autoComplete="off"
                                    style={{height: "100%"}}
                                    onChange={(e) => setSupportToAdd(e.target.value)} value={supportToAdd} />
                            </div>
                        
                            <button 
                                type="submit" 
                                className="btn btn-primary btn-sm" 
                                style={{height: "100%"}} 
                                onClick={(e) => addSupportToList(e)} >
                                    {t("mandat:addSupportPerso", "Ajouter support personnalisé")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")}
                </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default SupportsModal
