import React, { useEffect, useState } from 'react'
import './ADMSide.css';
import Axios from '../../../../axios-edipub'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import SignatureTab from './SignatureTab/SignatureTab';
import OfflineTab from './OfflineTab/OfflineTab';

import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMSide({ admData, isCreator, mandat, connectedUser, admStatus, admScrolledEnd, pdfPages, setAdmScrolledEnd, mandatUid, getMandatData, updateMandatData, setTabActive, setTabSideActive, tabSideActive, role, error }) {
    let notyf = getNotyfObject();
    const { t } = useTranslation();

    useEffect(() => {
        if (tabSideActive === "signature") {
            if (pdfPages === 1)
                setAdmScrolledEnd(true)
            else
                setAdmScrolledEnd(false)
        }
        if (tabSideActive === "timeline" && "RG, RC".includes(role))
            setTabSideActive(admStatus === "SIGNED" ? "postSign" : "modifications")
    }, [tabSideActive])


    const generateAdmPdf = (uid) => {
        let mandatTemp = { ...admData }
        Axios.get('/document/' + uid + '/admPdf').then(response => {
            // mandatTemp = {
            //     ...mandatTemp,
            //     pdfFile : response.data.content
            // }
            getMandatData(uid)
            //updateMandatData(mandatTemp)
            notyf.success(t("mandat:generatedSucccessMessage"))
            setTabActive("mandat");
        }).catch(response => notyf.error(t("mandat:generatedErrorMessage")))
    }

    const signatureSuccess = () => {
        getMandatData(admData.uid)
        notyf.success(t("mandat:generatedSucccessMessage"))
        setTabActive("mandat");
    }

    const offlineSignatureSuccess = () => {
        signatureSuccess();
        setTabSideActive('timeline')
    }

    if (error) {
        if (error === 'Expired token')
            notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    }


    return (
        <div className="timeLine">
            <ul className="nav nav-tabs">
                {!("RG, RC".includes(role)) && <li className="nav-item">
                    <a className={"nav-link" + (tabSideActive === "timeline" ? " active" : "")} data-toggle="tab" href="" onClick={() => setTabSideActive("timeline")}>{t("mandat:timeline", "TimeLine")} </a>
                </li>}
                {admStatus === "SIGNED" ? <li className="nav-item">
                    <a className={"nav-link" + (tabSideActive === "postSign" ? " active" : "")} data-toggle="tab" href="" onClick={() => setTabSideActive("postSign")}>{t('mandat:postSignChatTab', "Remarques post signature et partage de fichiers")} </a>
                </li> :
                    <li className="nav-item">
                        <a className={"nav-link" + (tabSideActive === "modifications" ? " active" : "")} data-toggle="tab" href="" onClick={() => setTabSideActive("modifications")}>{t('mandat:chatTab', "Demandes de modification et partage de fichiers")} </a>
                    </li>}
                {
                    admStatus === "TO_BE_SIGNED" && +connectedUser?.is_signataire && !connectedUser?.signedDoc && admData?.offline != "1" ? (
                        <li className="nav-item">
                            <a className={"nav-link" + (tabSideActive === "signature" ? " active" : "")} data-toggle="tab" href="" onClick={() => { setTabSideActive("signature") }} >Signatures </a>
                        </li>
                    ) : null
                }
                {
                    admData?.offline == "1" && (admStatus === "TO_BE_SIGNED" && isCreator && admData?.mandataireUsersSigned == "1" || admStatus === "SIGNED" && role == "AE") ? (
                        <li className="nav-item">
                            <a className={"nav-link" + (tabSideActive === "offline" ? " activered" : " inactivered")} data-toggle="tab" href="" onClick={() => { setTabSideActive("offline") }} >{(role == "AE" ? "Réimport post " : "") + "Signature Offline"} </a>
                        </li>
                    ) : null
                }

            </ul>
            <div id="myTabContent" className="tab-content time-Line-Content" >
                {tabSideActive === "timeline" && <div className="tab-pane fade show active time-line-bloc">
                    <TimeLine status={mandat?.status} origine={""} />
                </div>}
                {tabSideActive === "modifications" && <div className="tab-pane show active fade modification-bloc">
                    <ModificationsChat documentObj={mandat} type={"USER_CHAT_EVENT"} />
                </div>}
                {tabSideActive === "postSign" && <div className="tab-pane show active fade modification-bloc">
                    <ModificationsChat documentObj={mandat} type={"POST_SIGN_CHAT"} />
                </div>}
                {tabSideActive === "signature" && <div className="tab-pane fade show active modification-bloc">
                    <SignatureTab
                        user={connectedUser}
                        admScrolledEnd={admScrolledEnd}
                        pdfPages={pdfPages}
                        mandatUid={mandatUid}
                        getMandatData={getMandatData}
                        generateAdmPdf={generateAdmPdf}
                        setTabSideActive={setTabSideActive}
                        admData={admData}
                        signatureSuccess={signatureSuccess}
                    />
                </div>}
                {tabSideActive === "offline" && <div className="tab-pane fade show active modification-bloc">
                    <OfflineTab
                        user={connectedUser}
                        role={role}
                        mandatUid={mandatUid}
                        getMandatData={getMandatData}
                        generateAdmPdf={generateAdmPdf}
                        setTabSideActive={setTabSideActive}
                        admData={admData}
                        signatureSuccess={offlineSignatureSuccess}
                    />
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    admData: state.mandat.admData,
    error: state.mandat.error,
    connectedUser: state.mandat?.admData?.connectedUser,
    admStatus: state.mandat.admData.status,
    admScrolledEnd: state.mandat.admScrolledEnd,
    pdfPages: state.mandat.pdfPages,
    mandatUid: state.mandat.admData.uid,
    role: state.auth.role,
    tabSideActive: state.mandat.tabSideActive,
    isCreator: state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData)),
    setTabActive: (tabActive) => dispatch(actions.setTabActive(tabActive)),
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    setAdmScrolledEnd: (scrolledAnd) => dispatch(actions.setAdmScrolledEnd(scrolledAnd))
})
export default connect(mapStateToProps, mapDispatchToProps)(ADMSide)
