import React, { useEffect, useRef, useState } from 'react'
import './Profile.css';
import avatar from '../../assets/images/avatar.svg'
import Axios from '../../axios-edipub';
import {getNotyfObject} from '../../shared/utility';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import SignatureCanvas from '../../components/Profile/SignatureCanvas/SignatureCanvas';
import ConfirmDeleteModal from '../../components/Profile/ConfirmDeleteModal/ConfirmDeleteModal';
import { Tooltip, withStyles } from '@material-ui/core';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import Spinner from 'react-bootstrap/Spinner';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json'
import { checkValidity } from '../../shared/utility'
import iconEntity from '../../assets/images/icon-entity.png'
import InputInfo from './InputInfo/InputInfo';
const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function Profile(props) {
    let notyf = getNotyfObject();
    const {t} = useTranslation();

    const uploadBtn = useRef(null)
    const [user, setUser] = useState(null);
    const [userNotification, setUserNotification] = useState(false);
    const [userLang, setUserLang] = useState("");
    const [userTelVisible, setUserTelVisible] = useState(null)
    const [passwords, setPasswords] = useState({password: '', newPassword: '', confirmPassword: ''});
    const [signaturesLoading, setSignaturesLoading] = useState(null)
    const [userSignatures, setUserSignatures] = useState(null)
    const [signatureDataB64, setSignatureDataB64] = useState(null);
    const [showDeleteConfirmModal , setShowDeleteConfirmModal] = useState(false)
    const [signatureUidToDelete , setSignatureUidToDelete] = useState(null)
    const [saveUserConfigLoading, setSaveUserConfigLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({});
    const [inputPrenomChange, setInputPrenomChange] = useState()
    const [inputNomChange, setInputNomChange] = useState()
    const [inputNumChange, setInputNumChange] = useState(null)
    const [inputDepartement, setInputDepartement] = useState(null)
    const [inputFunction, setInputFunction] = useState(null)
    const [inputFunctionLabel, setInputFunctionLabel] = useState(null)
    const [valideNum, setValideNum] = useState(true)
    const [saveDataUserLoading, setSaveDataUserLoading] = useState(false)
    const [functionsList, setFunctions] = useState([]);
    const [departmentsList, setDepartements] = useState([]);
    
    const getUserDetails = () => {
        Axios.get("user//current").then( response => {
            setUser(response?.data?.user);
            getFunctions(+response?.data?.user?.departementId, response?.data?.user?.role, false)
            setUserLang(response?.data?.user?.language)
            setUserTelVisible(response?.data?.user?.telVisible)
            setUserNotification(response?.data?.user?.notification == 0 ? false : true)
            setUserInfo({firstName: response?.data?.user.first_name, lastName: response?.data?.user.last_name, phoneNumber: response?.data?.user.telephone_number});
            setInputNomChange(response?.data?.user?.last_name)
            setInputPrenomChange(response?.data?.user?.first_name)
            setInputNumChange(response?.data?.user?.telephone_number)
            setInputDepartement(response?.data?.user?.departementId)
            setInputFunction(response?.data?.user?.functionId)
            setInputFunctionLabel(response?.data?.user?.fonctionLabel)
            
        }).catch(response => {
            notyf.error(t("default:errorMessage"));
        })
    }

    const getFunctions = (departement, role, initFunction = false) =>{
        Axios.get("/user/"+departement+"/usersFunctions").then( response => {
            let functions = [];
            let departements = [];
            if( response?.data?.functions && response?.data?.departements){ 
                functions = response?.data?.functions;
                departements = response?.data?.departements;
                let filtredFunctions = []
                if("MR,MG,MC".includes(role)){
                    filtredFunctions = functions.filter(f => f.label_agence !== "");
                } else if ("RG,RC".includes(role)){
                    filtredFunctions = functions.filter(f => f.label_regie !== "");
                }
                setFunctions(filtredFunctions);
                setDepartements(departements);
                if(initFunction)
                    setInputFunction(filtredFunctions[0]?.functionId)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const getUserSignatures = () => {
        setSignaturesLoading(true)
        Axios.get('/signature2user//userSignatures')
            .then(response => {
                setSignaturesLoading(false)
                setUserSignatures(response?.data)
            })
            .catch(err => {
                setSignaturesLoading(false)
                //notyf.error(t("mandat:savedSignature", "Signature enregistr�e"))
            })
    }

    const deleteUserSignature = () => {
        Axios.delete(`/signature2user/${signatureUidToDelete}/userSignature`)
            .then(res => {
                getUserSignatures()
            })
            .catch(err =>
                notyf.error("une erreur s\'est produite ! ")
            )
    }

    const deleteSignatureClickHandler = (signatureUid) => {
        setSignatureUidToDelete(signatureUid)
        setShowDeleteConfirmModal(true)
    }

    const saveSignature = () => {
        Axios.post('/signature2user', {
            signatureDataB64
        }).then(response => {
            notyf.success(t("mandat:savedSignature", "Signature enregistr�e"))
            setSignatureDataB64(null)
            getUserSignatures()
        }).catch(err => {
            //notyf.error(t("mandat:savedSignature", "Signature enregistr�e"))
        })
    }

    const handlePictureChange = (e) => {
        //setSignatureDataB64(null)
        
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if(file.size > 2000000){
                notyf.error('La taille du fichier doit étre inférieur à 2 M');
                e.target.value = null
            }else if (["image/jpeg", "image/png"].indexOf(file.type) !== -1) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    const newLogo = {content: fr.result, name: file.name};
                    setSignatureDataB64(newLogo?.content);
                    e.target.value = null
                }
            } else
                notyf.error('Le fichier doit être au format PNG ou JPG');
        }
    }
    
    useEffect(() => {
        getUserDetails();
        getUserSignatures();
        // getFunctions(1);
    },[saveDataUserLoading])

    const inputPasswordChange = (field, value) => {
        let passwordsTemp = {
            ...passwords,
            [field] : value
        } 
        setPasswords(passwordsTemp)
    }

    const capitalizeFirstLetter = string => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }

    const notificationChange = () => {
        setUserNotification( userNotification == 0  ?  1 : 0 )
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newInfo = {
            ...userInfo,
            [key]: e.target.value
        }
        setUserInfo(newInfo)
    }

    const handleChangeLanguage = (e) => {
        setUserLang(e.target.value)
    }



    const editUserData=()=>{
        setSaveDataUserLoading(true);
        let generatePDF = 0;
        if(inputPrenomChange !== user?.first_name  || inputNomChange !== user?.last_name || inputFunction !== user?.functionId ){
            generatePDF = 1;
        }
        
        Axios.put("user//userdata",  
            {
                telephone_number: inputNumChange, 
                last_name: inputNomChange, 
                first_name:inputPrenomChange, 
                telVisible: userTelVisible,  
                role: user?.role,
                departementId : inputDepartement,
                functionId: inputFunction,
                functionLabel: inputFunctionLabel,
                generatePDF: generatePDF
            }).then(response => {  
                    getUserDetails();
                    notyf.success(t("default:editConfig"))
                    setSaveDataUserLoading(false);

            }).catch(error => {
                setSaveDataUserLoading(false);
                notyf.error(error?.response?.data?.error);
            })
    }

    const saveUserConfig = () => {
        setSaveUserConfigLoading(true)
        Axios.put("user//config",  
                {lang: userLang, notification: userNotification, telVisible: userTelVisible}
            ).then(response => {
                setSaveUserConfigLoading(false)
                notyf.success(t("default:editConfig"))
        }).catch(error => {
            setSaveUserConfigLoading(false)
            notyf.error(error.response.data.error);
        })
    }

    const saveUserInfo = () => {
        Axios.put("user//info", userInfo).then(response => {
            notyf.success(t("default:editConfig"))
        }).catch(error => {
            notyf.error(error.response.data.error);
        })
    }

    const editPassword = () => {
        Axios.put("user//password", passwords).then(response => {
            notyf.success(t("default:passwordUpdate"))
            setPasswords({password: '', newPassword: '', confirmPassword: ''})
        }).catch(error => {
            notyf.error(error.response.data.error);
        })
    }
    const NumChange=(phone)=>{  
        if (!phone || checkValidity(phone, {required: false, isPhone: true}))
         {
            setValideNum(true);
            setInputNumChange(phone)
         }else
         {
           setValideNum(false);
           setInputNumChange(phone)
         }  
    }

    const inputDepartementChangeHandler = (value) => {
        setInputDepartement(value)
        setInputFunction("")
        getFunctions(value, user?.role, true)
    }

    const inputFunctionChangeHandler = (value) => {
        setInputFunction(value)
    }
  
    const editUserDisableState = () => {
        let firstNameChanged = inputPrenomChange && (inputPrenomChange?.trim() !== user?.first_name?.trim());
        let lastNameChanged = inputNomChange && (inputNomChange?.trim() !== user?.last_name?.trim())
        let telNumberChanged = inputNumChange !== user?.telephone_number
        let telVisibleChanged = (userTelVisible !== null) && (Number(userTelVisible) !== Number(user?.telVisible))
        let departementChanged = inputDepartement !== user?.departementId
        let functionChanged = +inputFunction !== +user?.functionId
        let functionLabelChanged = inputFunctionLabel !== user?.fonctionLabel

        return !firstNameChanged && !lastNameChanged && !telNumberChanged && !telVisibleChanged && !departementChanged && !functionChanged && !functionLabelChanged
    }

    const displaySignature = () => { 
        return (user != null)  && "AE,AG,AC,MG,MC,MR".includes(user.role) 
    } 
    const displayPersonalDataCheckboxes = () => { 
        return (user != null)  && "AE,AG,AC,MG,MC,MR".includes(user.role) 
    } 

    return (
        <div className="userProfile_container">
            <div className="userProfile_sides_container">
                <div className="userProfile_rightSide_container" >
                    <div className="rightSide_header_container">
                        <div className="header_infos_container">  
                            <img className="rightSide_header_logo" src={avatar} />  
                            <div className="header_labels_container">
                                {
                                  //<strong>{ user ? capitalizeFirstLetter(user?.last_name) : '' } </strong>
                                  //<strong>{ user ? capitalizeFirstLetter(user?.first_name) : '' } </strong>     
                                }   
                                 <strong>{ user ? capitalizeFirstLetter(inputNomChange) : '' } </strong>
                                  <strong>{ user ? capitalizeFirstLetter(inputPrenomChange) : '' } </strong>                  
                            </div>
                        </div>
                    </div>
                

                    <div className="rightSide_form_container">
                        <fieldset className="form_element_config">
                        <legend style={{width: "auto"}}>{t("default:upProfileHeadername")}</legend>
                            <div className="personnalData__container">
                                <div className="personnalData__editableSide">
                                    <div className="form_threeElements_container">
                                        <div className="form_element__container">
                                            <label htmlFor="nom">{ t("default:name") } *</label>
                                            <input type="texte" className="form-control"  id="nom" name="nom" 
                                            placeholder={ t("default:name") } value={inputNomChange} onChange={(e) => setInputNomChange(e.target.value) }></input>
                                        </div>
                                        <div className="form_element__container">
                                            <label htmlFor="prenom">{ t("default:firstName") } *</label>
                                            <input type="text" className="form-control" required minLength="1" id="prenom" name="prenom" 
                                            placeholder={ t("default:firstName") } value={inputPrenomChange} onChange={(e) => setInputPrenomChange(e.target.value) }></input>
                                        </div>

                                        <div className="form_element__container ">
                                            <div className="notEditableSide__label_container">
                                                <label htmlFor="num">{t('default:mobileNumber', "Numéro de portable")}</label>
                                                <InputInfo infoMessage={t('masterData:mobileNumberTooltip', "Le numéro de téléphone n’est pas obligatoire ici, mais il est préconisé de le saisir pour des signataires, pour éviter sa saisie au moment de la signature électronique. Il est possible de le masquer")} />
                                            </div>
                                            <div style={{width: "100%"}}>
                                                <PhoneInput
                                                        inputStyle={{
                                                            width: "100%"
                                                        }}
                                                        localization={fr}
                                                        country={'fr'}
                                                        //inputClass={"form-control w-100"}
                                                        dropdownStyle={{width: "auto"}}
                                                        preferredCountries={['fr', 'us']}
                                                        enableSearch={false}
                                                        inputProps={{name: "telephone_number"}}
                                                        placeholder={"33 0 00 00 00 00"}
                                                        value={inputNumChange?.substr(2) || ''}
                                                        onChange={(phone) => NumChange( phone ? "00" + phone : null ) }
                                                    />
                                            </div>
                                        </div>
                                        <div className=" row">{valideNum === false && <p style={{color:"red" ,marginLeft: "180px"}}> {t('default:NumNoteValid', "Numer")} </p>}</div>
                                        <div className="form_element_config__showTel">
                                            <div className="form_element_config__showTel_label"> {t('default:TelephoneVisibleParTous', "Télephone visible par tous")} </div>
                                            <div className="form_element_config__showTel_value">
                                                {
                                                    Number(userTelVisible) === 1 ? (
                                                        <CTooltip title={"Rendre invisible"}  className="tableActions__icon">
                                                            <ToggleOnIcon 
                                                                className="toggleOnIcon" 
                                                                // fontSize="small"
                                                                onClick={() => setUserTelVisible(0)}
                                                            />
                                                        </CTooltip>
                                                    ) : 
                                                        <CTooltip title={"Rendre visible"}  className="tableActions__icon">
                                                            <ToggleOffIcon 
                                                                className="toggleOffIcon" 
                                                                // fontSize="small"
                                                                onClick={() => setUserTelVisible(1)}
                                                            />
                                                        </CTooltip>
                                                }
                                            
                                            </div>
                                        </div>
                                        {
                                            "MR,MG,MC,RG,RC".includes(user?.role) ? (
                                                <>
                                                    <div className="form_element__container" >
                                                        <label htmlFor="departementId">{t('default:departement', "Département")} </label>
                                                        <select 
                                                            className="form-control" 
                                                            name="departementId"
                                                            onChange={(e) => inputDepartementChangeHandler(e.target.value)}
                                                            value={inputDepartement|| "" }
                                                        >
                                                            <option value=""></option>
                                                            {
                                                                departmentsList?.map( d => (
                                                                    d.nbAgenceF > 0 ?
                                                                        <option key={d.id} value={d.id}>{d.name}</option>
                                                                    : ""
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    {
                                                        (inputDepartement && inputDepartement !== "") && 
                                                            <div className="form_element__container" >
                                                                <label htmlFor="functionId">{t('default:function', "Fonction")} </label>
                                                                <select 
                                                                    className="form-control" 
                                                                    name="functionId" 
                                                                    onChange={(e) => inputFunctionChangeHandler(e.target.value)}
                                                                    value={ inputFunction || "" }
                                                                >
                                                                    {/* <option value=""></option> */}
                                                                {
                                                                    functionsList?.sort((functionA, functionB) => functionA.label_agence?.toLowerCase().localeCompare(functionB.label_agence?.toLowerCase()))
                                                                    .map(f => ( 
                                                                        <option key={f.functionId} value={f.functionId}>{f.label_agence}</option>
                                                                    ))
                                                                }
                                                                </select>
                                                            </div>
                                                    }
                                                </>
                                            ) : 
                                                <div className="form_element__container">
                                                    <label htmlFor="fonction">{t('default:function', "Fonction")}</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="fonction" 
                                                        minLength="1" 
                                                        name="fonctionLabel" 
                                                        value={inputFunctionLabel|| ''} 
                                                        onChange={(e) => setInputFunctionLabel(e.target.value) } 
                                                    />
                                                </div> 
                                        }
                                        
                                        <p>{t("default:mandatoryFields")}</p>
                                        <div className="rightSide_form_submit__container">
                                            { 
                                                saveDataUserLoading ?
                                                    <Spinner animation="border" variant="primary" />
                                                :
                                                    <button 
                                                        type="button" 
                                                        onClick={e => editUserData()} 
                                                        className="btn btn-primary rightSide_form_submitBtn" 
                                                        disabled={editUserDisableState()}
                                                    >
                                                        { t("default:save")}
                                                    </button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="personnalData__notEditableSide">
                                    <div className="form_element__container">
                                        <div className="notEditableSide__label_container">
                                            <label htmlFor="mail">{t('default:mailAddress', "Adresse mail")}</label>
                                            <InputInfo infoMessage={t('profile:editEmailInfo', "Votre email est votre identifiant dans MyMandat. En cas de changement d'adresse email, contacter le support MyMandat")} />
                                        </div>
                                        
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="mail" 
                                            required 
                                            minLength="1" 
                                            name="email" 
                                            value={user?.email || ''}
                                            // onChange={(e) => emailChangeHandler(e)} 
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="form_element__container">
                                        <div className="notEditableSide__label_container">
                                            <label >
                                                {t('default:society', "Société")}  
                                            </label>
                                            <InputInfo infoMessage={t('profile:editInputInfo', "Pour modifier ce champ, contacter votre gestionnaire")} />
                                        </div>
                                        <div className="form_pillsElements_container" style={{height: "117px", maxHeight: "117px"}} >
                                            {
                                                (user?.entities && user?.entities?.length  > 0 )  ?  user?.entities.map(entity => (
                                                    <div className="form_pillElement_container" key={entity.uid}>
                                                        <div className="pillElement_infos">
                                                            <img className="pillElement_icon" src={iconEntity} ></img>
                                                            <div className="pillElement_label">{entity.commercial_name}</div>
                                                        </div>
                                                    </div> 
                                                )) : null
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className="form_element__container">
                                        <div className="notEditableSide__label_container">
                                            <label>{t('default:role', "Rôle")}  </label>
                                            <InputInfo infoMessage={t('profile:editInputInfo', "Pour modifier ce champs, contacter votre gestionnaire")} />
                                        </div>
                                        <select className="form-control" required id="role" aria-label="Default select example" name="role" 
                                            value={user?.role || ''}
                                            disabled={true} 
                                        >
                                            <option value=""></option>
                                            <option value="AE">{t('masterData:roleAE', "Administrateur EdiPub")} </option>
                                            <option value="MC">{t('masterData:roleMC', "Mandataire contributeur")}</option>
                                            <option value="MR">{t('masterData:roleMR', "Mandataire responsable")}</option>
                                            <option value="MG">{t('masterData:roleMG', "Mandataire gestionnaire")}</option>
                                            <option value="AC">{t('masterData:roleAC', "Annonceur contributeur")}</option>
                                            <option value="AG">{t('masterData:roleAG', "Annonceur gestionnaire")}</option>
                                            <option value="RC">{t('masterData:roleRC', "Consultation régie")}</option>
                                            <option value="RG">{t('masterData:roleRG', "Gestionnaire régie")}</option>
                                        </select>
                                        {
                                            displayPersonalDataCheckboxes() ? (
                                                <div className="entityUser_roles" >
                                                    <div className="">
                                                        <input 
                                                            style={{margin:"6px 8px 0px 2px"}}
                                                            className="form-check-input" 
                                                            type="checkbox"   
                                                            name="is_signataire" 
                                                            value="" 
                                                            id="is_signataire" 
                                                            checked={user?.is_signataire && +user?.is_signataire === 1}
                                                            disabled={true}
                                                        />
                                                        <label className="form-check-label" htmlFor="is_signataire">
                                                            {t('default:signatory', "Signataire")} 
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input 
                                                            style={{margin:"6px 8px 0px 2px"}}
                                                            className="form-check-input" 
                                                            type="checkbox"  
                                                            name="is_legal_representative" 
                                                            value="" 
                                                            id="is_legal_representative" 
                                                            checked={user?.is_legal_representative && +user?.is_legal_representative === 1} 
                                                            disabled={true}
                                                        />
                                                        <label className="form-check-label" htmlFor="is_legal_representative">
                                                            {t('mandat:legalRepresentative', "Représentant légal")} 
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : null                                            
                                        }
                                    </div>
                                </div>
                            </div>
                            
                        </fieldset>
                    </div>


                    <fieldset className="form_element_config">
                        <legend style={{width: "auto"}}>{t("default:setting")}</legend>
                        <div className="form_element_config_body">
                            <div className="form_element_config__container">
                                <label htmlFor="language">{ t("default:language") }</label>
                                <select className="form-select" value={userLang} onChange={(e) => handleChangeLanguage(e)}>
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                                </select>
                            </div>
                            <div className="form_element_config__container">
                                <label htmlFor="notifications" >{ t("default:userNotifications") }</label>
                                <input type="checkbox" onChange={(e) => notificationChange(e)} className="form-control" name="notifications"
                                    checked={userNotification ? true : false}/>
                            </div>
                            {/* <div className="form_element_config__showTel">
                                <div className="form_element_config__showTel_label">Télephone visible par tous </div>
                                <div className="form_element_config__showTel_value">
                                    {
                                        Number(userTelVisible) === 1 ? (
                                            <CTooltip title={"Rendre invisible"}  className="tableActions__icon">
                                                <ToggleOnIcon 
                                                    className="toggleOnIcon" 
                                                    // fontSize="small"
                                                    onClick={() => setUserTelVisible(0)}
                                                />
                                            </CTooltip>
                                        ) : 
                                            <CTooltip title={"Rendre visible"}  className="tableActions__icon">
                                                <ToggleOffIcon 
                                                    className="toggleOffIcon" 
                                                    // fontSize="small"
                                                    onClick={() => setUserTelVisible(1)}
                                                />
                                            </CTooltip>
                                    }
                                </div>
                            </div> */}
                        </div>
                        <div className="saveButton">
                            {
                                saveUserConfigLoading ?
                                    <Spinner animation="border" variant="primary" />
                                :   <button type="button" onClick={(e) => saveUserConfig(e)} className="btn btn-primary " >
                                        { t("default:save") }
                                    </button>
                            }
                        </div>
                    </fieldset>


                    <div className="rightSide_form_container">
                        <fieldset className="form_element_config">
                        <legend style={{width: "auto"}}>{t("default:upProfileHeader")}</legend>
                            <div className="form_threeElements_container">
                                {/* <h5>{ t("default:upProfileHeader") }</h5> */}
                                <br/>
                                <div className="form_element__container">
                                    <label htmlFor="password">{ t("default:upProfilePass") }</label>
                                    <input type="password" className="form-control" required minLength="1" id="password" name="password" 
                                    placeholder={ t("default:upProfilePass") } value={passwords.password} onChange={e => inputPasswordChange(e.target.name, e.target.value) }></input>
                                </div>
                                <div className="form_element__container">
                                    <label htmlFor="newPassword">{ t("default:upProfileNewPass") }</label>
                                    <input type="password" className="form-control" required minLength="1" id="newPassword" name="newPassword" 
                                    placeholder={ t("default:upProfileNewPass") } value={passwords.newPassword} onChange={e => inputPasswordChange(e.target.name, e.target.value) }></input>
                                </div>
                                <div className="form_element__container">
                                    <label htmlFor="confirmPassword">{ t("default:upProfilePassConfirm") }</label>
                                    <input type="password" className="form-control" required minLength="1" id="confirmPassword" name="confirmPassword" 
                                    placeholder={ t("default:upProfilePassConfirm") } value={passwords.confirmPassword} onChange={e => inputPasswordChange(e.target.name, e.target.value) }></input>
                                </div>
                            <div className="rightSide_form_submit__container">
                                <button type="button" onClick={e => editPassword()} className="btn btn-primary rightSide_form_submitBtn" disabled={!passwords.newPassword || !passwords.confirmPassword || !passwords.password}>
                                { t("default:upProfilePassTextButton") }</button>
                            </div>
                            </div>
                        </fieldset>
                    </div>
                    { 
                        displaySignature() ? (   
                            <fieldset className="form_element_config">
                                <legend style={{width: "auto"}}>{t("default:signatures", "Signatures")}</legend>
                                <div className="signatures_body_container">
                                    <div className="signatures_list_container">
                                        {
                                            userSignatures && userSignatures?.length > 0 ? userSignatures?.map(signature => (
                                                <div 
                                                    className="signature_item_container"
                                                >
                                                    <img src={signature?.imageData}/>
                                                    <CancelIcon 
                                                        className="signature_delete_icon" 
                                                        onClick={() => deleteSignatureClickHandler(signature.uid)}
                                                    />
                                                </div>
                                            )) : signaturesLoading ? (
                                                <div 
                                                    className="spinner-border text-primary" 
                                                    role="status"
                                                >
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            ) : <p
                                                    style={{
                                                        display: "flex",
                                                        alignSelf: "self-start"
                                                    }}
                                                >Aucune</p>
                                        } 
                                        {
                                            showDeleteConfirmModal ? (
                                                <ConfirmDeleteModal
                                                    show={showDeleteConfirmModal}
                                                    handleClose={() => setShowDeleteConfirmModal(false)}
                                                    deleteSignature={() => deleteUserSignature()}
                                                    resetSignatureUidToDel={() => setSignatureUidToDelete(null)}
                                                />
                                            ) : null
                                        }
                                    </div>

                                    <div 
                                        className="signatures_canvas_container" 
                                    >
                                        <SignatureCanvas 
                                            setSignatureDataB64={setSignatureDataB64}
                                            signatureDataB64={signatureDataB64}
                                            saveSignature={saveSignature}
                                            canvasClickHandler={() => uploadBtn?.current?.click()}
                                        />
                                        <div>
                                            <input 
                                                type="file" 
                                                className="emailSettings__formInput" 
                                                style={{display: "none", width:"300px", verticalAlign: "top"}} 
                                                name="mailLogo" 
                                                onChange={(e) => handlePictureChange(e)} 
                                                ref={uploadBtn}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        ) : null
                    }
                </div>

                
            </div>            
        </div>
    )
}

export default Profile
