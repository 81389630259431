import React, { useEffect, useRef, useState } from 'react'
import './ChangesList.css'

function ChangesList({values, show, close, field, click}) {

    const changesRef = useRef();

    function handleClickOutside(event) {
        if (changesRef.current && !changesRef.current.contains(event.target)) {
            close()
        }
      }
    useEffect(() => {
        if(show)
            document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [show])


    const valueClickHandler = (e, val) => {
        click(field, val);
        close(field)
    }

    return (
        <div ref={changesRef} className="changesListDropDown_container" style={{display: show ? "block" : "none"}}>
            {
                (values && values?.length > 0) ? values.map((val, index) => (
                    <div key={index} className="changesElement_container" onClick={(e) => valueClickHandler(e, val?.value)}>
                        {val?.value}
                    </div>
                )) : null
            }
            
        </div>
    )
}
export default ChangesList
