import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';  
 
function CantUpdateValidatorModal({handleClose, show}) {
    const {t} = useTranslation();  

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t("mandat:cantUpdateContributor", "Un contributeur l'ayant déjà approuvée, il est impossible de retirer un contributeur de l'attestation de mandat à moins de la repasser à l'état 'Brouillon'.")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button> 
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CantUpdateValidatorModal
