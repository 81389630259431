//_______Authentication Actions_______
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SUCCESS_SSO = 'AUTH_SUCCESS_SSO';
export const AUTH_VIS_SUCCESS = 'AUTH_VIS_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_VISUALISATION_USER = 'SET_VISUALISATION_USER';


//________MANDAT Actions__________
export const SET_TABACTIVE = "SET_TABACTIVE";
export const SET_TAB_SIDEACTIVE = "SET_TAB_SIDEACTIVE";
export const MANDAT_START = 'MANDAT_START';
export const MANDAT_SUCCESS = 'MANDAT_SUCCESS';
export const MANDAT_FAIL = 'MANDAT_FAIL';
export const SET_COMMON_PURPOSES = 'SET_COMMON_PURPOSES';
export const UPDATEMANDAT_START = 'UPDATEMANDAT_START';
export const UPDATEMANDAT_SUCCESS = 'UPDATEMANDAT_SUCCESS';
export const UPDATEMANDAT_FAIL = 'UPDATEMANDAT_FAIL';
export const UPDATE_MANDAT_DATA = 'UPDATE_MANDAT_DATA';
export const UPDATE_MANDAT_ADDRESSES = 'UPDATE_MANDAT_ADDRESSES';
export const ADD_MANDAT_USER = 'ADD_MANDAT_USER';
export const ADD_MANDAT_USER_TO_UPDATE = 'ADD_MANDAT_USER_TO_UPDATE';
export const UPDATE_MANDAT_USER_TO_ADD = 'UPDATE_MANDAT_USER_TO_ADD';
export const UPDATE_MANDAT_USER_TO_UPDATE = 'UPDATE_MANDAT_USER_TO_UPDATE';
export const DELETE_MANDAT_USER = 'DELETE_MANDAT_USER';
export const DELETE_MANDAT_USER_TO_ADD = 'DELETE_MANDAT_USER_TO_ADD';
export const ADD_MANDAT_SUBAGENCY = 'ADD_MANDAT_SUBAGENCY';
export const DELETE_MANDAT_SUBAGENCY = 'DELETE_MANDAT_SUBAGENCY';
export const DELETE_MANDAT_SUBAGENCY_TO_ADD = 'DELETE_MANDAT_SUBAGENCY_TO_ADD';
export const RESET_MANDAT_TO_UPDATE_DATA = 'RESET_MANDAT_TO_UPDATE_DATA'
export const ADD_MANDAT_PRODUCT = 'ADD_MANDAT_PRODUCT'
export const DELETE_MANDAT_PRODUCT = 'DELETE_MANDAT_PRODUCT'
export const ADD_MANDAT_MEDIA = 'ADD_MANDAT_MEDIA'
export const DELETE_MANDAT_MEDIA = 'DELETE_MANDAT_MEDIA'
export const ADD_MANDAT_REGIE = 'ADD_MANDAT_REGIE'
export const DELETE_MANDAT_REGIE = 'DELETE_MANDAT_REGIE'
export const ADD_MANDAT_SUPPORT = 'ADD_MANDAT_SUPPORT'
export const DELETE_MANDAT_SUPPORT = 'DELETE_MANDAT_SUPPORT'
export const ADD_MANDAT_MARKETINGMODE = 'ADD_MANDAT_MARKETINGMODE'
export const DELETE_MANDAT_MARKETINGMODE = 'DELETE_MANDAT_MARKETINGMODE'
export const ADD_MANDAT_GEOGRAPHICALAREA = 'ADD_MANDAT_GEOGRAPHICALAREA'
export const DELETE_MANDAT_GEOGRAPHICALAREA = 'DELETE_MANDAT_GEOGRAPHICALAREA'
export const UPDATE_MANDAT_OBJECTS = 'UPDATE_MANDAT_OBJECTS'
export const UPDATE_MANDAT_ADMOBJECTS_AGENCY = 'UPDATE_MANDAT_ADMOBJECTS_AGENCY'
export const ADD_MANDAT_ADMOBJECTS = "ADD_MANDAT_ADMOBJECTS"
export const DELETE_MANDAT_ADMOBJECTS = "DELETE_MANDAT_ADMOBJECTS"
export const UPDATE_MANDAT_ADMOBJECTS = "UPDATE_MANDAT_ADMOBJECTS"
export const UPDATE_MANDAT_CLAUSES = "UPDATE_MANDAT_CLAUSES"
export const DELETE_MANDAT_CLAUSE = "DELETE_MANDAT_CLAUSE"
export const UPDATE_MANDAT_DESCRIPTION = "UPDATE_MANDAT_DESCRIPTION"
export const UPDATE_MANDAT_PERIOD = "UPDATE_MANDAT_PERIOD"
export const UPDATE_MANDAT_CREATOR = "UPDATE_MANDAT_CREATOR"
export const SET_ADM_SCROLLEDEND = "SET_ADM_SCROLLEDEND"
export const SET_ADM_PDFPAGES = "SET_ADM_PDFPAGES"
export const RESET_ADM_TO_INITIAL_STATE = "RESET_ADM_TO_INITIAL_STATE"
// export const GET_COUNT_MASTERDATAS_PENDING = "GET_COUNT_MASTERDATAS_PENDING"    //pas utilisé car on utilise pas flag pending
export const UPDATE_MANDAT_DEPARTMENTS = "UPDATE_MANDAT_DEPARTMENTS"
export const UPDATE_MANDAT_SHOW_COMMERCIAL_NAME = "UPDATE_MANDAT_SHOW_COMMERCIAL_NAME"

export const MANDAT_GET_TIMELINE_START = 'MANDAT_GET_TIMELINE_START';
export const MANDAT_GET_TIMELINE_SUCCESS = 'MANDAT_GET_TIMELINE_SUCCESS';
export const MANDAT_GET_TIMELINE_FAIL = 'MANDAT_GET_TIMELINE_FAIL';
export const MANDAT_POST_EVENT_START = 'MANDAT_POST_EVENT_START';
export const MANDAT_POST_EVENT_FAIL = 'MANDAT_POST_EVENT_FAIL';

export const ADD_RECIPIENT_USER = 'ADD_RECIPIENT_USER';
export const DELETE_RECIPIENT_USER = 'DELETE_RECIPIENT_USER';
export const RESET_RECIPIENT_USERS = 'RESET_RECIPIENT_USERS';

export const UPDATEADMPAYER_SUCCESS = 'UPDATEADMPAYER_SUCCESS';
export const UPDATEADMPAYER_FAIL = 'UPDATEADMPAYER_FAIL'; 
export const SET_ADM = 'SET_ADM';
