import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: null,
    userId: null,
    visualisation : false,
    connectedUserUid : null,
    connectedUserLogin : null,
    login: null,
    error: null,
    loading: false,
    role: null,
    authRedirectPath: null,
    isSso:false,
    ssoName: null,
    ssoReconnectUrl: null,
    ssoDisconnectUrl: null
}

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true, authRedirectPath: null});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        login: action.login,
        token: action.token,
        userId: action.userId,
        error: null,
        loading: false,
        visualisation: false,
        connectedUserUid : null,
        connectedUserLogin : null,
        role: action.role,
        authRedirectPath: null
    })
}

const authSuccessSso = (state, action) => {
    return updateObject(state, {
        login: action.login,
        token: action.token,
        userId: action.userId, 
        ssoName: action.ssoName,
        ssoReconnectUrl: action.ssoReconnectUrl,
        ssoDisconnectUrl: action.ssoDisconnectUrl,
        isSso: true,
        error: null,
        loading: false,
        visualisation: false,
        connectedUserUid : null,
        connectedUserLogin : null,
        role: action.role,      
    })
}

const authVisSuccess = (state, action) => {
    return updateObject(state, {
        login: action.login,
        token: action.token,
        userId: action.userId,
        visualisation : true,
        connectedUserUid : action.connectedUserUid,
        connectedUserLogin : action.connectedUserLogin,
        error: null,
        loading: false,
        role: action.role,
        authRedirectPath: null
    })
}

const setUserVisualisation = (state, action) => updateObject(state, {
    token : action.userVisToken,
    userId : action.userVisUid,
    role : action.userVisRole,
    login : action.userVisLogin,
    visualisation : true,
    connectedUserUid : state.userId,
    connectedUserLogin : state.login
})

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const authLogout = (state, action) => {
    //window.location.pathname = "/"
    return updateObject(state, {
        token: null, 
        userId: null, 
        login: null,
        error: null,
        loading: false,
        role: null,
        visualisation: false,
        connectedUserUid : null,
        connectedUserLogin : null,
        authRedirectPath: "/"})
}

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {authRedirectPath: action.path})
}


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.AUTH_START: return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_SUCCESS_SSO: return authSuccessSso(state, action)
        case actionTypes.AUTH_VIS_SUCCESS: return authVisSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action)
        case actionTypes.SET_VISUALISATION_USER: return setUserVisualisation(state, action)
        default:
            return state
    }
}

export default reducer;