import React, { useCallback, useState } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility'; 


function ListManagerMission({handleFormChanged,entitiesList, role}) {
    const notyf = getNotyfObject();  
    const {t} = useTranslation(); 
    const [itemsReadonly, setItemsReadonly] = useState([]);
    const [items , setItems] = useState([]);
    const [itemsToUpdate , setItemsToUpdate] = useState([]);
    const [itemsToDelete , setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({}); 
    const [updateLoading , setUpdateLoading] = useState(false);       
    const [entity, setEntity] = useState((typeof entitiesList !== 'undefined' && entitiesList !== null && entitiesList.length > 0 )   ?  entitiesList[0].uid:null )


    /*useEffect(() => {   
        if(entitiesList!=null && entitiesList.length > 0){
             setEntity( entitiesList[0].uid)
             getMissionList(entitiesList[0].uid)
         } 
     },[entitiesList]) */

    //  const getClauseList = useCallback((entityp = entity) => {
    //     if(entityp!=null){            
    //         let params = {'entity': entityp};   
    //         Axios.get('clause', {'params' : params }).then(response => {
    //             setItems(response.data) 
    //         })
    //     }
    // }, [entity])

    const entityChangeHandler =  (uid) => { 
        setEntity(uid)            
        getMissionList(uid)  
        setItemsToUpdate([])
        setItemsToDelete([]) 
        setNewItem([])  
} 
    const getMissionList = useCallback((entityp = entity) => { 
      
        Axios.get('purpose', {params: {includingNoProxy: true, entity: entityp}}).then(response => {
            setItemsReadonly(role !== "AE" ? response.data.filter(i => i.proxy === null) : []) 
            setItems(role !== "AE" ? response.data.filter(i => i.proxy !== null) : response.data.filter(i => i.proxy === null)) 
        })
    }, [])
 
    const missionAddHandler = () => {
        let tmpNewItem = {...newItem}
        tmpNewItem['agency_uid'] = entity
        Axios.post('/purpose', tmpNewItem
        ).then (response => {
            getMissionList(entity)
            notyf.success('Ajout sauvegardé') 
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })   
    }

    const missionsUpdateHandler = ( ) => {
        setUpdateLoading(true)
        let data = { 
            'missionsToUpdate' : itemsToUpdate,
            'missionsToDelete' : itemsToDelete
        }  
        Axios.put(`/purpose//editall`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getMissionList(entity)
                setUpdateLoading(false)
                handleFormChanged(false)  
                notyf.success('Modifications sauvegardées')
            }).catch(err => { 
                setUpdateLoading(false)   
                if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else 
                    notyf.error('Une erreur s\'est produite !')
            }) 
    } 

    return (
        <ListManagerAbstract 
            title = {t('masterData:missions',"Missions")}    
            addNewTitle =  {t('masterData:addNewMission', "Ajouter une nouvelle mission" )}      
            addNewAlreadyExistLabel = {t("masterData:existMissionLabel", "cette mission existe déja!")}  
            fieldColumnNames= {{label:'Francais', label_en:'Anglais', real_code:'Code'}}
            readonlyFields={'real_code'}
            positionField= {'position'}
            itemsReadonly={itemsReadonly}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            entitiesList={entitiesList}  
            setNewItem={setNewItem} 
            getItemList={getMissionList}
            itemAddHandler={missionAddHandler}
            itemsUpdateHandler={missionsUpdateHandler}         
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading}
            entityChangeHandler={entityChangeHandler} 
            entity={entity} 
        />
     ) 
}

export default ListManagerMission