import React, { useEffect, useState } from 'react'
import './ApprouveMandat.css'
import Axios from '../../../axios-edipub';
import { Notyf } from 'notyf';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import ADMTopCard from '../../../components/TokenAccess/ApprouveMandat/ADMTopCard/ADMTopCard';
import ADMProgressLine from '../../../components/TokenAccess/ApprouveMandat/ADMTopCard/ADMProgressLine/ADMProgressLine';
import TimeLine from '../../../components/ADMContext/ADMBody/ADMSide/Timeline/TimeLine';
import PdfViewer from '../../../components/ADMContext/ADMBody/ADMMain/ADMView/PdfViewer/PdfViewer';
import ADMTabAction from '../../../components/TokenAccess/ApprouveMandat/ADMSide/TabAction';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import ModificationsChat from '../../../components/TokenAccess/ApprouveMandat/ADMSide/ModificationsChat/ModificationsChat';


function ApprouveMandat({ tabSideActive, pdfPages, setAdmScrolledEnd, setTabSideActive }) {
    let { token } = useParams();
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [admData, setAdmData] = useState(null);
    const [errorMesssage, setErrorMessage] = useState(null);
    const [signedSuccess, setSignedSuccess] = useState(false);
    const [unknownSignatorySign, setUnknownSignatorySign] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [approveAdmLoading, setApproveAdmLoading] = useState(false);
    const getMandatData = () => {
        Axios.get(`/document/${token}/offlineDocumentByToken`).then(response => {
            setAdmData(response?.data);
            setPdfFile(response?.data?.pdfFile);
        }).catch(error => {
            setErrorMessage(t("mandat:errorMessage"))
        })
    }

    const approveMandatHandle = (mandatUid) => {
        setApproveAdmLoading(true)
        Axios.put(`/document/${mandatUid}/approveDocument`, { "accessToken": true, "userUid": admData?.connectedUser?.userUid })
            .then(response => {
                if (response) {
                    setApproveAdmLoading(false)
                    getMandatData(mandatUid)
                    setTabSideActive('timeline')
                }
            }).catch(err => {
                setApproveAdmLoading(false)
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
            })
    }

    useEffect(() => {
        getMandatData()
    }, [token])



    let signDocJsx = null;

    if (admData && !errorMesssage) {
        signDocJsx = (
            <>
                <ADMTopCard
                    admData={admData}
                    name={'AM-' + admData?.id}
                    description={admData?.description}
                    advertiser={admData?.annonceur?.official_name}
                    period={formatDate(+admData?.start_period) + "-" + formatDate(+admData?.end_period)}
                    isCreator={false}
                    role={admData?.connectedUser?.role}
                    creationDate={formatDate(+admData?.creation_date)}

                />
                <ADMProgressLine status={admData?.status}
                    getMandatData={getMandatData}
                    updateMandatStatus={true}
                    mandatUid={admData?.uid}
                    createMandatTimelineEvent={true}
                    role={"MG"}
                    isCreator={false}
                    connectedUser={admData?.connectedUser}
                    pdfFile={admData?.pdfFile}
                    admData={admData}
                    admUpdate={true}
                    error={null}
                    setTabSideActive={setTabSideActive}
                    approveAdmLoading={approveAdmLoading}
                />
                {/* <ADMProgressLine setShowSignatureTab={handleSignatureButton}/> */}
                <div className="MandatBody">
                    <div className="MandatEdition w-100">
                        {
                            signedSuccess ? (
                                <div class="alert alert-warning" role="alert">
                                    {t("default:signMandatTokenAccess")}
                                </div>
                            ) : null
                        }
                        {
                            !unknownSignatorySign ?
                                <div class="alert alert-warning" role="alert">
                                    {t("default:canceledUnknownSignatory")}
                                </div>
                                : null
                        }
                        {
                            pdfLoading ?
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                    <Spinner animation="border" variant="primary" style={{ width: "100px", height: "100px" }} />
                                </div>
                                :
                                <PdfViewer
                                    setAdmPdfPages={() => { }}
                                    pdfFile={pdfFile}
                                    setAdmScrolledEnd={() => setAdmScrolledEnd(true)}
                                    pdfFileName={admData?.pdfFileName}
                                />
                        }
                    </div>
                    {<>

                        <div className="timeLine" style={{ width: "70%" }}>
                            <ul className="nav nav-tabs">

                                <li className="nav-item">
                                    <a className={"nav-link" + (tabSideActive === "timeline" ? " active" : "")} data-toggle="tab" href="" onClick={() => { setTabSideActive("timeline") }}>TimeLine</a>
                                </li>
                                {
                                    !"TO_BE_SIGNED SIGNED EXPIRED CANCELED DRAFT".includes(admData?.status) &&
                                    <li className="nav-item">
                                        <a className={"nav-link" + (tabSideActive === "Action" ? " active" : "")} data-toggle="tab" href="" onClick={() => { setTabSideActive("Action") }}>Action</a>
                                    </li>
                                }
                                <li className="nav-item">
                                    <a className={"nav-link" + (tabSideActive === "modifications" ? " active" : "")} data-toggle="tab" href="" onClick={() => setTabSideActive("modifications")}>{t('mandat:chatTab', "Demandes de modification et partage de fichiers")} </a>
                                </li>

                            </ul>
                            <div id="myTabContent" className="tab-content time-Line-Content" >
                                {tabSideActive === "timeline" && <div className="tab-pane fade show active time-line-bloc">
                                    <TimeLine status={admData?.status} origine={"offlineApprouve"} uidDoc={admData?.uid} />
                                </div>}
                                {
                                    !"TO_BE_SIGNED SIGNED EXPIRED CANCELED DRAFT".includes(admData?.status) ?
                                        tabSideActive === "Action" && <div className="tab-pane show active fade modification-bloc">
                                            <ADMTabAction
                                                admData={admData}
                                                accessToken={true}
                                                user={admData?.connectedUser}
                                                status={admData?.status}
                                                origine={"offlineApprouve"}
                                                uidDoc={admData?.uid}
                                                actionTitle={"Approuver le mandat"}
                                                tabSideActive={tabSideActive}
                                                setTabSideActive={setTabSideActive}
                                                getMandatData={getMandatData}
                                                approveMandatHandle={approveMandatHandle}
                                            />
                                        </div> : null
                                }


                                {tabSideActive === "modifications" && <div className="tab-pane show active fade modification-bloc">
                                    <ModificationsChat token={token} documentObj={admData} type={"USER_CHAT_EVENT"} />
                                </div>}


                                {/* <div className="tab-pane fade show active time-line-bloc">
                                    <TimeLine status={admData?.status} origine={"offlineApprouve"} uidDoc={admData?.uid} />
                                </div>
                                <div className="tab-pane fade time-line-bloc">
                                    <ADMTabAction status={admData?.status} origine={"offlineApprouve"} uidDoc={admData?.uid} />
                                </div> */}
                            </div>
                        </div>
                    </>
                    }

                </div>
            </>
        )
    } else if (errorMesssage) {
        signDocJsx = (<div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ backgroundColor: "#FFFFFF", width: "400px", padding: "1rem" }}>
                <h4>{errorMesssage} </h4>
                <Link className="btn btn-primary" to="/">{t("default:acceuil")}</Link>
            </div>
        </div>)
    }
    else signDocJsx = null

    return (
        <>
            <div className="mandat_container">
                {signDocJsx}

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    status: state.mandat?.admData?.status,
    admScrolledEnd: state.mandat.admScrolledEnd,
    pdfPages: state.mandat.pdfPages,
    tabSideActive: state.mandat.tabSideActive
})
const mapDispatchToProps = dispatch => ({
    setTabActive: (tabActive) => dispatch(actions.setTabActive(tabActive)),
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setAdmScrolledEnd: (scrolledAnd) => dispatch(actions.setAdmScrolledEnd(scrolledAnd))
})
export default connect(mapStateToProps, mapDispatchToProps)(ApprouveMandat)