import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {checkValidity, uuidv4} from '../../../../../shared/utility'
import './AddressModal.css'
import countries from '../../../../../assets/countries.json'
import { useTranslation } from 'react-i18next';

function AddressModal({entityCategory, handleClose, show, address, entityInputChangeHandler, addressType, addCompAddress, updateCompAddress}) {
    const [addressLoc, setAddressLoc] = useState(address);
    const [errors, setErrors] = useState(null);
    const [countriesLocal, setCountriesLocal] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if( (i18n.language || 'fr') == 'fr')
            setCountriesLocal(countries.sort(function(a, b){return  ((a.name > b.name) ? 1 : -1)}))
        else 
            setCountriesLocal(countries.sort(function(a, b){return  ((a.nameEn > b.nameEn) ? 1 : -1)}))
    }, [])

    useEffect(() => {
        setErrors(null)
    }, [addressLoc])
    
    const addressInputChangeHandler = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let addressTemp = {
            ...addressLoc, 
            [field] : value
        }
        setAddressLoc(addressTemp)
    }

    const addAddressClickHandler = () => {
        let addressTemp = {
            new : true,
            uidTemp : uuidv4(),
            iso_country: addressLoc?.iso_country || addressType === "address_mail" ? addressLoc.iso_country : "FR",
            ...addressLoc
        }
        let errors = checkAddressFormValidity(addressType, addressTemp);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            if(addressType === "compAddress") {
                addCompAddress(addressTemp)
            } else {
                entityInputChangeHandler(addressType, addressTemp)
            }
            handleClose();
        }
    }

    const updateAddressClickHandler = () => {
        let addressTemp = {
            ...addressLoc,
            iso_country: addressLoc?.iso_country || addressType === "address_mail" ? addressLoc.iso_country : "FR",
        } 
        let errors = checkAddressFormValidity(addressType, addressTemp);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            if(addressType === "compAddress") {
                updateCompAddress(addressTemp)
            } else {
                entityInputChangeHandler(addressType, addressTemp)
            }
            handleClose();
        }
    }

    const checkAddressFormValidity = (addressType, address) => {
        let errors = []
        // if(!address.hasOwnProperty('label_primary') || !checkValidity(address?.label_primary, {required: true})){
        //     errors.push("Le champ 'Service ou identité du destinataire' est vide ") ;
        // }
        if(addressType == "address_mail" && address.hasOwnProperty('email') && address?.email!='' && address?.email && (entityCategory=="Annonceur" || entityCategory=="Agence")) {
            if(!checkValidity(address?.email, {isEmail: true})){
                errors.push(t("masterData:empty_email_service"));
            }
        }else{
            if(!address.hasOwnProperty('address_line') || !checkValidity(address?.address_line, {required: true})){
                errors.push(t("masterData:empty_street_numberLabel")) ;
            }
            if(!address.hasOwnProperty('post_code') || !checkValidity(address?.post_code, {required: true})){
                errors.push(t("masterData:empty_postal_code")) ;
            }
            if(!address.hasOwnProperty('city_cedex') || !checkValidity(address?.city_cedex, {required: true})){
                errors.push(t("masterData:empty_locality_field")) ;
            }
            if(!address.hasOwnProperty('iso_country') || !checkValidity(address?.iso_country, {required: true})){
                errors.push(t("masterData:empty_country_field")) ;
            }
            if(address.hasOwnProperty('email') && address?.email!='' && address?.email) {
                if(!checkValidity(address?.email, {isEmail: true})){
                    errors.push(t("masterData:empty_email_service"));
                }
            }
        }

        return errors;
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:addAddress', "Ajouter une adresse")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="addressModal_container" style={{backgroundColor: "#ffffff", minWidth: "40rem"}}>
                {
                    errors && errors.length > 0 ? (
                        <div class="alert alert-danger mt-2" role="alert">
                            <ul>
                                {
                                    errors.map(error => (<li key={error}>{error}</li>))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                        <form  >
                            <div className="form-row justify-content-center">
                                <div className="form-group col-5">
                                    <label for="">{t('masterData:corporatName', "Dénomination sociale")} </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" name="corporate_name" 
                                        value={addressLoc?.corporate_name || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)} 
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-5">
                                    <label for="identity">{t('masterData:identityService', "Service ou identité du destinataire")} </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="identity" 
                                        name="label_primary" 
                                        value={addressLoc?.label_primary || ''}
                                        onChange={(e) => addressInputChangeHandler(e)}  
                                        autoComplete="off" />
                                </div>                                
                                <div className="form-group col-2">
                                </div>
                            </div>
                            <div className="form-row justify-content-center">
                                <div className="form-group col-5">
                                    <label for="">{t('masterData:addressComplement', "Complément de localisation de l'adresse")} </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" name="label_secondary" 
                                        value={addressLoc?.label_secondary || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)} 
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-5">
                                    <label for="">{t('masterData:street', "Numéro et libellé de voie")} </label>
                                    {addressType !== "address_mail" && <span className="form_required_asterisk"> *</span>}
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="address_line" 
                                        value={addressLoc?.address_line || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)}
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-2">
                                </div>
                            </div>
                            <div className="form-row justify-content-center">
                                <div className="form-group col-5">
                                    <label for="">{t('masterData:distributionService', "Service de distribution")} </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="distribution_service" 
                                        value={addressLoc?.distribution_service || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)}
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-2">
                                    <label for="">{t('masterData:postalCode', "Code postal")} </label>
                                    {addressType !== "address_mail" && <span className="form_required_asterisk"> *</span>}
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="post_code" 
                                        value={addressLoc?.post_code || ''}  
                                        onChange={(e) => addressInputChangeHandler(e)}
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-5">
                                    <label for="Pays">{t('masterData:country', "Pays")} </label>
                                    <select style={{height: '33px'}} class="form-control" name="iso_country" value={addressLoc?.iso_country || addressType !== "address_mail" && 'FR'} onChange={(e) => addressInputChangeHandler(e)} aria-label=".form-select-lg example">
                                        { addressType == "address_mail" && <option value={null}></option> }
                                        {
                                            countriesLocal?.map(country => (
                                                <option key={country.alpha2} value={country.alpha2}>{ ((i18n.language || 'fr') === 'fr' ? country.name : country.nameEn)}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-row ">
                                <div className="form-group col-5">
                                    <label for="commercialName">{t('masterData:localisationCedex', "Localité / Cedex")} </label>
                                    {addressType !== "address_mail" && <span className="form_required_asterisk"> *</span>}
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="city_cedex" 
                                        value={addressLoc?.city_cedex || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)}
                                        autoComplete="off"></input>
                                </div>
                                <div className="form-group col-5">
                                    <label for="">{t('masterData:serviceEmail', "Email du service")} </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="email" 
                                        value={addressLoc?.email || ''} 
                                        onChange={(e) => addressInputChangeHandler(e)}
                                        autoComplete="off"></input>
                                </div>
                                {/* <div className="form-group col-5">
                                    {
                                        addressType !== "address_invoice" ? (
                                            <>
                                                <label for="Libellé commercial">Autre identifiant (SIRET)</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="additional_id" 
                                                    value={addressLoc?.additional_id || ''} 
                                                    onChange={(e) => addressInputChangeHandler(e)}
                                                    id="additional_id" 
                                                    autoComplete="off"/>
                                            </>
                                        ) : null
                                    }
                                    
                                </div> */}
                                <div className="form-group col-2">
                                </div>
                            </div>
                        </form>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                {
                    (addressLoc?.id || (addressLoc?.index >= 0) ) ? (
                        <Button variant="primary" onClick={() => updateAddressClickHandler()} >
                            {t('default:update', "Modifier")}
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={() => addAddressClickHandler()} >
                            {t('default:add', "Ajouter")}
                        </Button>
                    )
                }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddressModal
