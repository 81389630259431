import React from 'react';
import './TableHead.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function TableHead(props) {
    const {columns,refreshContent, sortOrder, sortQuery, setSortOrder, reverse, setReverse, setSortQuery, filters, setFilters, selectAllRowsClickHandler, isSelectedAllPageDocs} = props;

    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ?  reverse[field] : false
        sortQuery != field ? setSortOrder("ASC") : setSortOrder( sortOrder == "DESC" ? "ASC" : "DESC" ) 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse)
        let key = field;
        let newFilters = {
            ...filters,
            orderBy: field,
            sortOrder: sortQuery != field ? "ASC" : sortOrder == "DESC" ? "ASC" : "DESC"  
        }
        setFilters(newFilters)
        refreshContent(newFilters)
    }


    return (
        <thead className="">
            <tr>
                {columns.map(column => (
                    <th key={column.field} scope="col" id={column.field} className="columnHead"
                        style={{borderTop: "none", width: column.width}} onClick={() => columnClickHandler(column.field)} >
                        <div className="columnHead__container">
                            <div className="columnHead__title">
                                {column.title}
                            </div>
                            <ArrowUpwardIcon style={{fontSize: 17, display: reverse[column.field] === true && sortQuery==column.field ? 'block' : 'none' }} />
                            <ArrowDownwardIcon style={{fontSize: 17, display: reverse[column.field] === false && sortQuery==column.field ? 'block' : 'none' }}/>
                        </div> 
                    </th>
                ))}
                <th className="columnHead" style={{borderTop: "none", width: "80px"}} >Relancer</th>
            </tr>
        </thead>
    )
}

export default TableHead
