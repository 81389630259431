import React from 'react'
import './StepProgressBar.css';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { useTranslation } from 'react-i18next';

function StepProgressBar(props) {
    const {t} = useTranslation();
    const {brouillons, AAprouver, aprouve, signe, role} = props

    return (
        <div className="stepProgressBar" >
            {/* <div id="divRadiusLeft"></div> */}
                {
                    !"AG, AC".includes(role) ? (
            
                        <div className="stepProgressBar_element ">
                            <div id="divRadiusLeft"></div>
                            <div className="stepProgressBar_elementContainer">
                                <h4 className="stepPBElement__title">
                                    {t('default:DRAFT', "Brouillon")}  ({brouillons})</h4>  
                            </div>
                            <div id="triangle"></div>
                        </div>
                    ): ""
                }
                 
            
            <div className="stepProgressBar_element" style={{marginLeft: (!"AC".includes(role))? "-45px" : "-2px", width: (!"AG, AC".includes(role))? "" : "39%"}}>
                <div className="stepProgressBar_elementContainer">
                {
                    !"AG, AC".includes(role) ? (
                            <div id="reverseTriangle"></div>
                        ) : (
                            <div id="divRadiusLeft"></div>
                    )
                }
                    <h4 className="stepPBElement__title">
                        {t('default:TO_BE_APPROVED', "A approuver")}  ({AAprouver})</h4>
                </div>
                <div id="triangle"></div>
            </div>

            <div className="stepProgressBar_element" style={{marginLeft: "-45px", width: (!"AG, AC".includes(role))? "" : "36%"}}>
                <div className="stepProgressBar_elementContainer">
                    <div id="reverseTriangle"></div>
                    <h4 className="stepPBElement__title">
                        {t('default:APPROVED', "Approuvé")}  ({aprouve})
                    </h4>
                </div>
                <div id="triangle"></div>
            </div>

            <div className="stepProgressBar_element" style={{marginLeft: "-45px", width: (!"AG, AC".includes(role))? "" : "36%"}}> 
                <div className="stepProgressBar_elementContainer radiusStyleRight ">
                    <div id="reverseTriangle"></div>
                    <h4 className="stepPBElement__title">
                        {t('default:SIGNED', "Signé")}  ({signe})
                    </h4>
                </div> 
                <div id="divRadiusRight"></div>
            </div>

            {/* <div id="divRadiusRight"></div> */}

        </div>
    )
}

const mapStateToProps = (state) => ({ 
    role: state.auth.role,
    login: state.auth.login,
})
const mapDispatchToProps = dispatch => ({     
})

export default connect(mapStateToProps, mapDispatchToProps)(StepProgressBar)
