import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function MediasModal({agencyUid, handleClose, show, addMedias,lang}) {
    const [entityMedias , setEntityMedias] = useState([]);
    const [selectedMedias, setSelectedMedias] = useState([]);
    // const [mediaToAdd, setMediaToAdd] = useState("");
    const [filtredMedias, setFiltredMedias] = useState([]);
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    const getMediasList = useCallback(() => {
        Axios.get('media', {params : { orderBy: "CAST(SUBSTRING_INDEX(`position`,'-',1) AS INT) , IF(`position` LIKE '%-%', 1, 0), CAST(SUBSTRING_INDEX(`position`,'-',-1) AS INT) " }}).then(response => {
            setEntityMedias(response.data)
            setFiltredMedias(response.data)
            
        })
    }, [])

    // const addMediaToList = useCallback((e) => {
    //     e.preventDefault();
    //     if(mediaToAdd && mediaToAdd.trim() !== ""){
    //         const newMedia = {add: true, label: mediaToAdd, new: true};
    //         addMedias([newMedia]);
    //         setMediaToAdd('');
    //         handleClose();
    //     }
    // }, [mediaToAdd])
    

    useEffect(() => {
        getMediasList()
        return () => {
            setSelectedMedias([]);
        }
    }, [agencyUid])

    const mediasFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredMedias;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredMedias(entityMedias)
        } else {
            arrayFiltred = entityMedias.filter(row => row.label.toLowerCase().includes(searchQuery.trim().toLowerCase()))
            setFiltredMedias(arrayFiltred)
        }    
    }

    const SelectMedia = (media) => {
        let mediaIndex = selectedMedias.findIndex(row => row.uid === media.uid);
        let selectedTemp = [...selectedMedias];
        if(mediaIndex >= 0)
            selectedTemp.splice(mediaIndex,1)
        else 
            selectedTemp = [...selectedMedias, media];
        setSelectedMedias(selectedTemp);
    }

    const selectAllMedias = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredMedias.map(media => ({uid : media.uid, label: media.label, label_en : media.label_en, new: true}))
        }
        setSelectedMedias(selectedTemp)
    }

    const addClickHandler = () => {
        addMedias(selectedMedias)
        handleClose()
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:addMedias', "Ajouter des médias")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t("default:search", "Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => mediasFilterHandler(e.target.value)} 
                            />
                            <div 
                                className="input-group-append" 
                                style={{height: "100%"}}    
                            >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedMedias?.length} média(s) sélectionné(s)</p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllMedias" onChange={(e) => selectAllMedias(e)} />
                                <label className="form-check-label" for="selectAllMedias" >
                                    {t('mandat:selectAll', "Selectionner tout")} 
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items">
                        {
                            filtredMedias?.length > 0 ? filtredMedias.map(media => (
                                <div className="productsModal__item" key={media.id}>
                                    <div className="productsModal__itemIcon" onClick={() => SelectMedia({uid : media.uid, label : media.label, label_en : media.label_en, new : true})}>
                                        {
                                            selectedMedias.some(row => row.uid === media.uid) ? (
                                                <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                            ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                        }
                                        
                                    </div>
                                    <div className="productsModal__itemName">
                                        {lang==="FR" ? media.label : media.label_en}
                                    </div>
                                </div>
                            )) : <div>{t('mandat:noMedias', "Aucun média à afficher")}  </div>
                        }
                    </div>
                    {/* <div className="row" style={{width: "100%", margin: "0", padding: "1rem "}}>
                        <form className="d-flex" style={{height: "30px"}}>
                            <div className="form-group mr-2" style={{height: "100%"}}>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="mediaToAddInput" 
                                    aria-describedby="emailHelp" 
                                    placeholder="Média à ajouter"
                                    autoComplete="off"
                                    style={{height: "100%"}} 
                                    onChange={(e) => setMediaToAdd(e.target.value)} value={mediaToAdd} />
                            </div>
                        
                            <button type="submit" className="btn btn-primary btn-sm" style={{height: "100%"}} onClick={(e) => addMediaToList(e)} >Ajouter média personnalisé</button>
                        </form>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MediasModal
