import React, { useEffect, useState } from 'react'
import './SignMandat.css'
import Axios from '../../../axios-edipub';
import { Notyf } from 'notyf';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import ADMTopCard from '../../../components/TokenAccess/SignMandat/ADMTopCard/ADMTopCard';
import PdfViewer from '../../../components/ADMContext/ADMBody/ADMMain/ADMView/PdfViewer/PdfViewer';

import SignatureTab from '../../../components/ADMContext/ADMBody/ADMSide/SignatureTab/SignatureTab';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next';
import SecretSignatureTab from '../../../components/ADMContext/ADMBody/ADMSide/SignatureTab/SecretSignatureTab/SecretSignatureTab';

function SignMandat() {
    let { token } = useParams();
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [admData, setAdmData] = useState(null);
    const [admScrolledEnd, setAdmScrolledEnd] = useState(null);
    const [errorMesssage, setErrorMessage] = useState(null);
    const [signedSuccess, setSignedSuccess] = useState(false);
    const [unknownSignatorySign, setUnknownSignatorySign] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfLoading, setPdfLoading] = useState(false);

    const getMandatData = () => {
        Axios.get(`/document/${token}/documentByToken`).then(response => {
            setAdmData(response?.data);
            setPdfFile(response?.data?.pdfFile);
            // setSignedSuccess(response?.data?.connectedUser?.signedDoc !== null)
            setSignedSuccess(response?.data?.hasSignedUser)
        }).catch(error => {
            setErrorMessage(t("mandat:errorMessage"))
            // notyf.error({
            //     message : error?.response?.data?.error || "Une erreur s'est produite!",
            //     duration: 0,
            //     dismissible: true
            // })
        })
    }

    useEffect(() => {
        getMandatData()
    }, [token])

    const generateAdmPdf = (uid) => {
        setPdfLoading(true)
        Axios.get('/document/' + uid + '/admPdf').then(response => {
            notyf.success(t("mandat:signedAdm"));
            setSignedSuccess(true)
            setPdfLoading(false)
            setPdfFile(response?.data?.content);
        }).catch(response => {
            setPdfLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const signatureSuccess = response => {
        notyf.success(t("mandat:signedAdm"));
        setSignedSuccess(true)
        setPdfFile(response?.data?.content);
    }

    let signDocJsx = null;

    if (admData && !errorMesssage) {
        signDocJsx = (
            <>
                <ADMTopCard
                    name={'AM-' + admData?.id}
                    description={admData?.description}
                    advertiser={admData?.advertiserName}
                    period={formatDate(+admData?.start_period) + "-" + formatDate(+admData?.end_period)}
                    isCreator={false}
                    role={admData?.connectedUser?.role}
                    creationDate={formatDate(+admData?.creation_date)}
                />

                <div className="MandatBody">
                    <div className="MandatEdition w-100">
                        {
                            signedSuccess ? (
                                <div className="alert alert-warning" role="alert">
                                    {t("default:signMandatTokenAccess")}
                                </div>
                            ) : null
                        }
                        {
                            !unknownSignatorySign ?
                                <div className="alert alert-warning" role="alert">
                                    {t("default:canceledUnknownSignatory")}
                                </div>
                                : null
                        }
                        {
                            pdfLoading ?
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                    <Spinner animation="border" variant="primary" style={{ width: "100px", height: "100px" }} />
                                </div>
                                :
                                <PdfViewer
                                    setAdmPdfPages={() => { }}
                                    pdfFile={pdfFile}
                                    setAdmScrolledEnd={() => setAdmScrolledEnd(true)}
                                    pdfFileName={admData?.pdfFileName}
                                />
                        }
                    </div>
                    {
                        !signedSuccess ? (
                            unknownSignatorySign ? (
                                <div className="TimeLine">
                                    <div className="signMandat_signatureTab">
                                        {

                                            admData.offline === '0' ? (
                                                admData?.connectedUser ? (
                                                    <SignatureTab
                                                        accessToken={true}
                                                        setSignedSuccess={setSignedSuccess}
                                                        user={admData?.connectedUser}
                                                        admData={admData}
                                                        admScrolledEnd={admScrolledEnd}
                                                        mandatUid={admData?.uid}
                                                        mandatId={admData?.id}
                                                        getMandatData={getMandatData}
                                                        tokenSignature={true}
                                                        generateAdmPdf={generateAdmPdf}
                                                        signatureSuccess={signatureSuccess}
                                                        setPdfLoading={setPdfLoading}
                                                    />
                                                ) : (
                                                    <SecretSignatureTab
                                                        setUnknownSignatorySign={setUnknownSignatorySign}
                                                        accessToken={true}
                                                        setSignedSuccess={setSignedSuccess}
                                                        user={admData?.connectedUser}
                                                        admData={admData}
                                                        admScrolledEnd={admScrolledEnd}
                                                        mandatUid={admData?.uid}
                                                        mandatId={admData?.id}
                                                        getMandatData={getMandatData}
                                                        tokenSignature={true}
                                                        generateAdmPdf={generateAdmPdf}
                                                        signatureSuccess={signatureSuccess}
                                                        setPdfLoading={setPdfLoading}
                                                    />
                                                )
                                            ) :
                                                (
                                                    <div className="alert alert-light" role="alert">
                                                        <ul className="mt-3">
                                                            <li>
                                                                <span style={{ color: "red" }}>{t("mandat:offlineAdmNotAuthorizedOnline")}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            ) : null
                        ) : null
                    }

                </div>
            </>
        )
    } else if (errorMesssage) {
        signDocJsx = (<div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ backgroundColor: "#FFFFFF", width: "400px", padding: "1rem" }}>
                <h4>{errorMesssage} </h4>
                <Link className="btn btn-primary" to="/">{t("default:acceuil")}</Link>
            </div>
        </div>)
    }
    else signDocJsx = null

    return (
        <>
            <div className="mandat_container">
                {signDocJsx}

            </div>
        </>
    )
}

export default SignMandat
