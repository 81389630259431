import React from 'react'
import './NotificationCard.css';
import {Link} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next'

function NotificationCard(props) {
    
    const {userRole, icon,label, adm, docUid, docId, company, message, eventId, time, children, deleteEvent, creator, contributors, signataires} = props;
    const {t} = useTranslation()

    return (
        <div className="homeNotifications__element">
            <div className="homeNotifications__elementHeader">
                <div className="homeNotifications__elementLabel">
                    {icon}
                    <h6>{label}</h6>
                </div>
                <CancelIcon
                    style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#d0d1d3"
                    }} 
                    onClick={() => deleteEvent(eventId)}
                />
            </div>
            <div className="homeNotifications_adm">
                {
                    <>
                        { docUid && !"RG, RC".includes(userRole) && 
                        <Link to={{
                            pathname: "/mandat/"+docUid
                        }} className="homeNotifications_mandatLink"> 
                        </Link>}
                        <div className="homeNotifications__elementInfo">
                            <h6>ADM-{docId}<span style={{color: "#007bff"}}>&nbsp;{adm} </span></h6>
                            <h6>{company} </h6>
                        </div>
                    </>
                }
                <div style={{color: "gray"}}>  
                    {(label=="Message") ? 
                        t("newHome:De", "De")+": "+creator 
                            : ((!message?.includes("modifié") && 
                                !message?.includes("validé")  && 
                                !message?.includes("partagé") && 
                                !message?.includes("signé") && 
                                !message?.includes("refusé") && 
                                !message?.includes("refused") && 
                                !message?.includes("modified") && 
                                !message?.includes("signed") &&  
                                !message?.includes("shared") &&  
                                !message?.includes("validated") 
                                    ? ( t("notification:Par", "Par")+": "+creator ) 
                                    : ""
                            )) 
                    }
                </div>
                <div className="homeNotifications__elementBody">
                    {children}
                    {
                        message !== "Message" && !message.includes("création du support") 
                        ? ( message?.includes("Publication") 
                            ? (
                                <div className="elementBody_message" style={{border:"0px", background:"white"}}>
                                    {message}
                                    {contributors}
                                </div>
                            ) : (
                                (message?.includes("modifié")   || message?.includes("modified") || 
                                 message?.includes("Transfert") || message?.includes("transfer") || 
                                 message?.includes("validé")    || message?.includes("validated") || 
                                 message?.includes("partagé")   || message?.includes("shared") || 
                                 message?.includes("signé")     || message?.includes("signed") || 
                                 message?.includes("refusé")    || message?.includes("refused") || 
                                 message?.includes("destinataire") || message?.includes("recipient") ||  
                                 message?.includes("supprimé")  || message?.includes("deleted")  
                                 ?  ( 
                                        <div className="elementBody_message" style={{border:"0px", background:"white"}}>
                                            
                                            {message?.includes("Tous") || message?.includes("All") || message?.includes("destinataires") || message?.includes("recipients") 
                                                ? message
                                                : creator+" "+message
                                            }
                                        </div>
                                    ) : (message?.includes("modifié") 
                                        ? (
                                            <div className="elementBody_message" style={{border:"0px", background:"white"}}>
                                                {message}
                                                {signataires}
                                            </div>
                                        ) : "" 
                                    )
                                ) 
                            )
                        ) : message
                    }
                </div>
                <div className="homeNotifications__elementFooter">
                        <AccessTimeIcon
                            style={{
                                fontSize: "0.9rem",
                                color: "green",
                                marginRight: "5px"
                            }}
                        />
                        <span>{time} </span>
                </div>
            </div>
        </div>
    )
}

export default NotificationCard
