import React, { useEffect, useState } from 'react'
import './ADMSignature.css';
import Axios from '../../../../../axios-edipub'
import { Notyf } from 'notyf';
import UserSignatureCard from './UserSignatureCard/UserSignatureCard';
import { getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMSignature({mandatUid, mandatid, userRole,isCreator}) {
    const notyf = getNotyfObject();
    const [usersSignedDoc, setUsersSignedDoc] = useState(null)
    const {t} = useTranslation();
    const getUsersSignedDoc = () => {
        Axios.get(`/document/${mandatUid}/signedUsers`)
            .then(response => {
                setUsersSignedDoc(response?.data?.signataires)
            } )
            .catch(response => {
                notyf.error(t("mandat:receivingSignatureError"))
            })
    }

    useEffect(() => {
        if (mandatUid)
            getUsersSignedDoc()
    }, [mandatUid])

    return (
        <div className="ADMSignature__container">
            {
                usersSignedDoc && usersSignedDoc?.length > 0 ? usersSignedDoc?.map(user => (
                    <UserSignatureCard
                        isCreator={isCreator}
                        userRole={userRole}
                        signUser={user}
                        mandatid={mandatid}  // j'ai ajouter l uid de mandat pour pouvoir recuperer le dossier de preuve
                        mandatUid={mandatUid}
                       
                    />
                )) : null
            }             
            

        </div> 
    )
}

export default ADMSignature