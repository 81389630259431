import React, { useState, useEffect } from 'react'
import AdmInfoCard from '../../components/Home/HomeBody/AdmInfoCard/AdmInfoCard';
import RegieMandat from '../../components/HomeRegie/RegieMandat/RegieMandat';
import { formatDate } from '../../shared/utility';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Spinner from '../../components/UI/Spinner/Spinner';
import './HomeSearchView.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Axios from '../../axios-edipub';
import { getNotyfObject } from '../../shared/utility'
import { useTranslation } from 'react-i18next';

function HomeSearchView({stepsInitialStatus, getMandatData, documents, loading, role, refreshAdmList, refreshAdmListAndResetFilters}) {
    const {t} = useTranslation();
    const [selectedAdm, setSelectedAdm] = useState(null);
    const [groupedDocuments, setGroupedDocuments] = useState([]);
    const [advertisers, setAdvertisers] = useState([])
    const [searchQuery, setSearchQuery] = useState(null);
    const notyf = getNotyfObject();

    useEffect(() => {
        // if (["RG", "RC"].includes(role)) {
        //     const newGroupedDocuments = [];
        //     documents.forEach(d => {
        //         const index = newGroupedDocuments.findIndex(gd => d.mandataire === gd.mandataire);
        //         if (index === -1)
        //             newGroupedDocuments.push({mandataire: d.mandataire, documents: [d]});
        //         else
        //             newGroupedDocuments[index].documents.push(d);
        //     })
        //     setGroupedDocuments(newGroupedDocuments.sort((a, b) => a.mandataire.toLowerCase().localeCompare(b.mandataire.toLowerCase())));
        // }
        if (selectedAdm) {
            const found = documents.find(d => selectedAdm.uid === d.uid);
            setSelectedAdm(found ? found : null);
        }
    }, [documents])

    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        })
        .then(res => {
            setAdvertisers(res.data.entities)
        })
        .catch(err => notyf.error(t("mandat:generatedErrorMessage")))           
    }, [searchQuery])

    const selectMandat = mandat => {
        setSelectedAdm(mandat);
        getMandatData(mandat.uid);
    }

    return (
        <div className="homeSearchView__homeBodyContainer">
            <div className="homeSearchView__AdmList">
                {
                    loading ? <Spinner /> : 
                    (["RG", "RC"].includes(role) ? 
                    // <div id="accordion">
                    //     {groupedDocuments?.map((g, index) => 
                    //         <div className="card border-light">
                    //             <div className="card-header bg-transparent border-light" id={"heading"+index}>
                    //                 <h5 className="mb-0">
                    //                     <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index}>
                    //                         {g.mandataire} 
                    //                         <ExpandMoreIcon className="homeSearchView__expand" />
                    //                         <ChevronRightIcon className="homeSearchView__collapse" />
                    //                     </button>
                    //                 </h5>
                    //             </div>
            
                    //             <div id={"collapse"+index} className="collapse" aria-labelledby={"heading"+index} data-parent="#accordion" >
                                    <div className="card-body bg-light p-1">
                                        {documents?.map(adm => (
                                            <AdmInfoCard 
                                                adm={adm}
                                                uid={adm.uid}
                                                documentId={adm.id}
                                                company={adm.official_name}
                                                mandataire={adm.mandataire}
                                                period={formatDate(parseInt(adm.start_period)) + " - " + formatDate(parseInt(adm.end_period))}
                                                products={adm.products}
                                                medias={adm.medias}
                                                highlighted={adm === selectedAdm}
                                                admStatus={adm.status}
                                                clicked={e => selectMandat(adm)}
                                                stepsInitialStatus={stepsInitialStatus}
                                                published = {adm.published}
                                                refreshContent={refreshAdmList}
                                                notyf = {notyf} />))}
                                    </div>
                    //             </div>
                    //         </div>
                    //     )}
                    // </div>
                    : documents?.map(adm => (
                        <AdmInfoCard 
                            adm={adm}
                            advertisers={advertisers}
                            setSearchQuery={setSearchQuery}
                            key={adm.id}
                            uid={adm.uid}
                            documentId={adm.id}
                            company={adm.official_name}
                            mandataire={adm.mandataire}
                            period={formatDate(parseInt(adm.start_period)) + " - " + formatDate(parseInt(adm.end_period))}
                            products={adm.products}
                            medias={adm.medias}
                            highlighted={adm === selectedAdm}
                            admStatus={adm.status}
                            clicked={e => selectMandat(adm)}
                            stepsInitialStatus={stepsInitialStatus}
                            published = {adm.published}
                            refreshContent={refreshAdmList}
                            refreshContentForDupplicate={refreshAdmListAndResetFilters}
                            advertiserUid={adm.advertiserUid}
                            isCreator={+adm.isCreator}
                            notyf = {notyf} />)))
                }
            </div>
            {selectedAdm && <div className="homeSearchView__AdmDetail">
                <div className="homeRegieBodyAdm__view">
                    <RegieMandat />
                </div>
                
            </div>}
            {/* <div className="homeRegieBody__notifications">
                <HomeNotifications />
            </div> */}
        </div>
    )
}

const mapStateToProps = state => ({
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
    getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid))
})
export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchView)

