import React, { useCallback, useEffect, useState } from 'react'
import './ListManagerAbstract.css';
import { useTranslation } from 'react-i18next';
import { getNotyfObject } from '../../../../shared/utility';
import AddListItemModal from '../AddListItemModal/AddListItemModal'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Spinner from 'react-bootstrap/Spinner'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { connect } from 'react-redux';

function ListManagerAbstract(props) {
    let {
        fieldColumnNames,
        uniqueFields,
        mandatoryFields,
        readonlyFields,
        positionField,
        textFields,
        fieldsMaxLen,
        title,
        addNewAlreadyExistLabel,
        addNewTitle,
        itemsReadonly,
        items,
        setItems,
        itemsToUpdate,
        setItemsToUpdate,
        itemsToDelete,
        setItemsToDelete,
        newItem,
        setNewItem,
        getItemList,
        itemAddHandler,
        itemsUpdateHandler,
        entitiesList,
        entity,
        entityChangeHandler,
        handleFormChanged,
        updateLoading,
        role,
        selectFields,
        itemsForSelectList,
        filterListItemsForSelectList,
        searchItemsWithFilterHandle,
        searchSelectItemsValue
    } = props

    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const uniqueFieldsProp = (typeof uniqueFields !== 'undefined') ? uniqueFields : []
    const textFieldsProp = (typeof textFields !== 'undefined') ? textFields : []
    const readonlyFieldsProp = (typeof readonlyFields !== 'undefined') ? readonlyFields : []
    const fieldsMaxLenProp = (typeof fieldsMaxLen !== 'undefined') ? fieldsMaxLen : []
    const mandatoryFieldsProp = (typeof mandatoryFields !== 'undefined') ? mandatoryFields : []
    const entitiesListProp = (typeof entitiesList !== 'undefined') ? entitiesList : []



    useEffect(() => {
        getItemList()
    }, [])

    const localEntityChangeHandler = (e) => {
        entityChangeHandler(e.target?.value)
    }

    const updateItemsToUpdate = (uid, field, value) => {
        let itemsToUpdateTemp = [...itemsToUpdate];
        itemsToUpdate.filter(row => {
            return (row?.uid === uid || row?.id == uid)
        })[0][field] = value;
        setItemsToUpdate(itemsToUpdateTemp)
        handleFormChanged(true)
    }

    const addItemToUpdate = (item) => {
        let itemsToUpdateTemp = [
            ...itemsToUpdate,
            item
        ];
        setItemsToUpdate(itemsToUpdateTemp)
        handleFormChanged(true)
    }

    const itemDeleteHandler = (itemIndex) => {
        let itemsTemp = [...items]
        let item = items[itemIndex]
        itemsTemp.splice(itemIndex, 1);
        setItems(itemsTemp)

        let itemsToUpdateTemp = [...itemsToUpdate];
        let indexInToUpd = itemsToUpdateTemp.findIndex(row => row?.uid === item?.uid || row?.id === item?.id)
        if (indexInToUpd >= 0)
            itemsToUpdateTemp.splice(indexInToUpd, 1)
        setItemsToUpdate(itemsToUpdateTemp)

        let itemsToDeleteTemp = [...itemsToDelete, (item?.uid || item?.id)]
        setItemsToDelete(itemsToDeleteTemp)
        handleFormChanged(true)
    }

    const itemUpdateHandler = (e, itemIndex) => {
        let field = e.target.name
        let value = e.target.value
        if (field == "mandatory")
            value = e.target.checked == true ? 1 : 0

        let item = items[itemIndex]
        let itemsTemp = [...items]
        itemsTemp[itemIndex][field] = value
        setItems(itemsTemp)
        let existInItemsToUpdate = itemsToUpdate.findIndex(row => row?.uid === item?.uid || row?.id == item?.id) >= 0;
        if (existInItemsToUpdate) {
            if (item?.uid)
                updateItemsToUpdate(item?.uid, field, value)
            else if (item?.id) updateItemsToUpdate(item?.id, field, value)
        } else {
            let itemTemp = { ...item }
            addItemToUpdate(itemTemp);
        }
    }

    const newItemUpdateHandler = (e) => {
        let field = e.target.name
        let value = e.target.value
        let newItemTemp = { ...newItem }
        newItemTemp[field] = value
        setNewItem(newItemTemp)
    }

    const listItems = items?.map((item, index) => (
        <>
            <div className="form_twoElements_container" style={{ marginTop: '10px' }}>
                {
                    Object.keys(fieldColumnNames).map(field => {
                        return (
                            textFieldsProp.includes(field) ? (
                                <textarea
                                    name={field}
                                    className="form-control"
                                    value={item[field]}
                                    style={{ height: "100px" }}
                                    maxLength={Object.keys(fieldsMaxLenProp).includes(field) ? fieldsMaxLenProp[field] : "128"}
                                    onChange={(e) => { itemUpdateHandler(e, index) }}
                                    disabled={readonlyFieldsProp.includes(field)}
                                ></textarea>
                            ) : (
                                <input
                                    type="text"
                                    name={field}
                                    className="form-control"
                                    value={item[field]}
                                    style={{ height: "100%" }}
                                    maxLength={Object.keys(fieldsMaxLenProp).includes(field) ? fieldsMaxLenProp[field] : "128"}
                                    onChange={(e) => { itemUpdateHandler(e, index) }}
                                    disabled={readonlyFieldsProp.includes(field)}
                                />
                            )
                        )
                    })
                }
                <DeleteForeverIcon className="ListManager_clearIcon" onClick={() => onClickDelete(index)} />
            </div>
        </>
    ))

    const listItemsDragable = (readonly) => {
        return (
            <DragDropContext onDragEnd={handleOnDragEnd} >
                <Droppable droppableId={"items" + (readonly ? "-readonly" : "")}>
                    {(provided) => (
                        <div  {...provided.droppableProps} ref={provided.innerRef}  >
                            {
                                (readonly ? itemsReadonly : items)?.map((item, index) => (
                                    <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={readonly}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"form_twoElements_container" + (readonly ? " form_twoElements_container_readonly" : "")} >
                                                {!readonly && <DragHandleIcon className="listManagerClause__dragIcon" fontSize="small" />}
                                                {
                                                    Object.keys(fieldColumnNames).map(field => {
                                                        return (
                                                            textFieldsProp.includes(field) ? (
                                                                <>
                                                                    <textarea
                                                                        name={field}
                                                                        className="form-control"
                                                                        value={item[field]}
                                                                        style={{ height: "100px" }}
                                                                        maxLength={Object.keys(fieldsMaxLenProp).includes(field) ? fieldsMaxLenProp[field] : "128"}
                                                                        onChange={(e) => { itemUpdateHandler(e, index) }}
                                                                        disabled={readonly || readonlyFieldsProp.includes(field)}
                                                                    ></textarea>
                                                                </>
                                                            ) : (
                                                                <input
                                                                    type="text"
                                                                    name={field}
                                                                    className="form-control"
                                                                    value={item[field]}
                                                                    style={{ height: "100%" }}
                                                                    maxLength={Object.keys(fieldsMaxLenProp).includes(field) ? fieldsMaxLenProp[field] : "128"}
                                                                    onChange={(e) => { itemUpdateHandler(e, index) }}
                                                                    disabled={readonly || readonlyFieldsProp.includes(field)}
                                                                />
                                                            )
                                                        )
                                                    })
                                                }

                                                {
                                                    !readonly && (title == "Clauses" || title == t('masterData:missions', "Missions") && role !== 'AE') ?
                                                        <input style={{ width: "48px", margin: "0px 39px" }}
                                                            className="form-control" type="checkbox"
                                                            disabled={readonly}
                                                            name={"mandatory"}
                                                            checked={(item.mandatory == null || item.mandatory == 0) ? false : true}
                                                            onChange={(e) => itemUpdateHandler(e, index)}
                                                        />
                                                        : null
                                                }
                                                {!readonly && <DeleteForeverIcon className="ListManager_clearIcon" onClick={() => onClickDelete(index)} />}
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

            </DragDropContext>

        )
    }

    const onClickAdd = () => {
        if (itemsToUpdate.length + itemsToDelete.length > 0) {
            notyf.open({
                closeWith: ['click'],
                type: 'warning',
                background: 'orange',
                message: t('default:askSaveBeforeAddNew', "Veuillez sauvegarder vos modifications avant de créer un nouvel élément")
            });
        } else {
            let tmp = {}
            Object.keys(fieldColumnNames).forEach(element => {
                tmp[element] = null
            });
            setNewItem(tmp)
            setShowAddItemModal(true)
        }
    }
    const onClickDelete = (itemIndex) => {
        itemDeleteHandler(itemIndex)
    }

    const updateClickHandler = () => {
        let emptyValue = false
        let emptyAllValues = false
        let emptyValueField = ''
        let double = false
        let doubleOnUnique = false
        let doubleOnUniqueField = ''

        items.forEach((item, index) => {

            let emptyAllValuesTmp = true

            items.forEach((parsedItem, parsedIndex) => {
                Object.keys(fieldColumnNames).forEach(field => {
                    if (parsedItem[field] === null || parsedItem[field] === '') {
                        emptyValue = true
                        emptyValueField = field
                    } else {
                        emptyAllValuesTmp = false
                    }
                })
            });
            if (emptyAllValuesTmp)
                emptyAllValues = true

            items.forEach((parsedItem, parsedIndex) => {
                if (index === parsedIndex)
                    return

                let differentCounter = 0
                Object.keys(fieldColumnNames).forEach(field => {
                    if (
                        (
                            (parsedItem[field] !== null && item[field] === null)
                            || (parsedItem[field] === null && item[field] !== null)
                        )
                        || (
                            parsedItem[field] !== null && item[field] !== null
                            && parsedItem[field].toUpperCase() !== item[field].toUpperCase()
                        )
                    ) {
                        differentCounter++
                    }
                })
                if (differentCounter === 0)
                    double = true
            });
        });

        uniqueFieldsProp.forEach(uniqueField => {
            var tabDoubles = items.reduce(function (acc, valCourante, index, items) {
                let filteredItems = items.filter(
                    fieldValueEquals(uniqueField, valCourante[uniqueField], true)
                ).filter(
                    fieldValueNotEquals("uid", valCourante.uid, false)
                );
                if (filteredItems.length > 0) {
                    acc.push(valCourante);
                }
                return acc
            }, []);
            if (tabDoubles.length > 0) {
                doubleOnUnique = true
                doubleOnUniqueField = uniqueField;
            }
        })

        if (emptyValue && (role === 'AE'))
            notyf.error(t("default:emptyField", { "field": fieldColumnNames[emptyValueField] }))
        if (emptyAllValues && (role !== 'AE'))
            notyf.error(t("default:emptyAllFields"))
        else if (double)
            notyf.error(t("default:dontDoDuplicatesMessage"))
        else if (doubleOnUnique)
            notyf.error(t("default:dontDoDuplicatesOnUniqueFieldMessage", { "field": fieldColumnNames[doubleOnUniqueField] }))
        else
            itemsUpdateHandler(items)
    }

    let fieldValueEquals = (fieldsToCompare, valueToCompare, toUpperCase) => (element, index, array) => {
        return (
            (element[fieldsToCompare] === null && valueToCompare === null)
            || (element[fieldsToCompare] && valueToCompare !== null
                && (toUpperCase ? element[fieldsToCompare].toUpperCase() :
                    element[fieldsToCompare]) === (toUpperCase ? valueToCompare.toUpperCase() : valueToCompare)
            )
        );
    }
    let fieldValueNotEquals = (fieldsToCompare, valueToCompare, toUpperCase) => (element, index, array) => {
        return ((
            (element[fieldsToCompare] !== null && valueToCompare === null)
            || (element[fieldsToCompare] === null && valueToCompare !== null)
        ) || (
                element[fieldsToCompare] && valueToCompare !== null
                && (toUpperCase ? element[fieldsToCompare].toUpperCase() :
                    element[fieldsToCompare]) !== (toUpperCase ? valueToCompare.toUpperCase() : valueToCompare)
            )
        )
    }

    const handleOnDragEnd = (result) => {
        if (result.destination) {
            if (result.source.index === result.destination.index)
                return
            const itemsTemp = [...items];
            const [reorderedItem] = itemsTemp.splice(result.source.index, 1);
            itemsTemp.splice(result.destination.index, 0, reorderedItem);
            let itemsToUpdate = itemsTemp.map((item, index) => {
                let itemTemp = { ...item, [positionField]: index }
                return itemTemp
            })
            setItemsToUpdate(itemsToUpdate)
            setItems(itemsToUpdate)
        }
    }

    return (
        <>
            <div className="mandatEdition">
                <ul className="nav nav-tabs bg-light mandatEdition_navTabs">
                    <li className="nav-item"><a className="nav-link ">{title}</a></li>
                </ul>
                <div className="tab-content">


                    {role !== "AE" && <div className="listManager__actionsSelectContainer" >
                        <div className="listManager__actionsLeft">
                            {
                                entitiesListProp?.length > 0 ? (
                                    <label style={{ margin: "5px", flex: "90%" }}> {t("default:company")} : </label>
                                ) : null
                            }
                        </div>
                        <div className="listManager__actionsRight">
                            {
                                entitiesListProp?.length > 0 ? (
                                    <select className="form-control"
                                        name="entity"
                                        style={{ padding: "6px", height: "30px", marginRight: "8px" }}
                                        placeholder="Choisir  Entité"
                                        value={entity || ''}
                                        onChange={(e) => localEntityChangeHandler(e)}
                                    >{
                                            entitiesListProp?.map((x) =>
                                                <option value={x.uid} key={x.id}>{x.commercial_name}</option>)
                                        }
                                    </select>
                                ) : null
                            }
                        </div>
                    </div>}

                    <div className="listManager__actionsContainer" style={entitiesListProp?.length > 0 ? ({ paddingTop: "2px" }) : {}}     >
                        <div className="listManager__actionsLeft">
                            <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target="#subMandataireModal" onClick={onClickAdd}>{addNewTitle}</button>
                        </div>
                        <div className="listManager__actionsRight">
                            {
                                updateLoading ?
                                    <Spinner animation="border" variant="primary" />
                                    :
                                    <button type="button" className="btn btn-outline-secondary btn-sm saveBtn mr-2" onClick={() => updateClickHandler()} disabled={itemsToUpdate.length + itemsToDelete.length === 0} >{t('default:save', "Sauvegarder")}</button>
                            }
                        </div>
                    </div>
                </div>

                {
                    filterListItemsForSelectList &&
                    <>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            {/* <label >Chercher : </label> */}
                            <div style={{ marginRight: "5px" }}>
                                {/* <label htmlFor={"functionFilter"}>{t("masterData:filterSelectForItems", "")}</label> */}
                                <input
                                    type="text"
                                    name={"functionName"}
                                    className="form-control"
                                    value={searchSelectItemsValue?.functionName}
                                    placeholder='Nom fonction'
                                    style={{ height: "100%" }}
                                    maxLength={"200"}
                                    onChange={(e) => { searchItemsWithFilterHandle(e) }}
                                />
                            </div>

                            <select
                                style={{ width: "200px", height: "28px", marginleft: "5px" }}
                                className="form-control"
                                name={"departmentName"}
                                onChange={(e) => searchItemsWithFilterHandle(e)}
                                value={searchSelectItemsValue?.departmentName}
                            >
                                <option value={""}>-- Select département --</option>
                                {
                                    itemsForSelectList[0]["departmentName"]?.map(item => {
                                        return <option value={item?.id} key={item?.id}>{item?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </>
                }

                <div className="form_twoElements_container" style={{ padding: '5px', marginTop: '10px' }}>
                    {
                        Object.keys(fieldColumnNames).map(fieldKey => (
                            <div style={{ width: (title == "Clauses" || title == t('masterData:missions', "Missions") && role !== 'AE') ? '40%' : '50%', paddingLeft: positionField != null ? "20px" : null }}>
                                {fieldColumnNames[fieldKey]}</div>
                        ))
                    }
                    {
                        (title == "Clauses" || title == t('masterData:missions', "Missions") && role !== 'AE') &&
                        <div style={{ width: '13%', textTransform: "capitalize" }}>
                            {t('default:mandatory', "Obligatoire")}
                        </div>
                    }
                </div>

                {
                    (positionField != null) ?
                        <>
                            {listItemsDragable(true)}
                            {listItemsDragable(false)}
                        </>
                        :
                        <div style={{ maxHeight: "800px", overflow: "auto" }}>
                            {listItems}
                        </div>
                }
            </div>
            {
                showAddItemModal ? (
                    <AddListItemModal
                        fieldColumnNames={fieldColumnNames}
                        uniqueFields={uniqueFieldsProp}
                        mandatoryFields={mandatoryFieldsProp}
                        textFields={textFieldsProp}
                        fieldsMaxLen={fieldsMaxLenProp}
                        addNewTitle={addNewTitle}
                        addNewAlreadyExistLabel={addNewAlreadyExistLabel}
                        items={items}
                        show={showAddItemModal}
                        handleClose={() => setShowAddItemModal(false)}
                        newItem={newItem}
                        itemAddHandler={itemAddHandler}
                        newItemUpdateHandler={newItemUpdateHandler}
                        selectFields={selectFields}
                        itemsForSelectList={itemsForSelectList}
                    />
                ) : null
            }
        </>
    )

}

const mapStateToProps = (state) => ({
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(ListManagerAbstract)