import React, { useEffect, useState, useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import avatar from '../../../assets/images/avatar.svg'
import iconEntity from '../../../assets/images/icon-entity.png'
import EntityUserModal from './EntityUserModal/EntityUserModal'
import AddUser2EntityAgencyModal from './AddUser2EntityAgencyModal/AddUser2EntityAgencyModal';
import EntityAgenciesUserModal from './EntityAgenciesUserModal/EntityAgenciesUserModal'
import ConfirmRoleModal from './ConfirmRoleModal/ConfirmRoleModal';
import DepartmentFunctions from './DepartmentFunctions/DepartmentFunctions';
import { Notyf } from 'notyf';
import CloseIcon from '@material-ui/icons/Close';
import Axios from '../../../axios-edipub';
import { checkValidity, getNotyfObject, phonenumberValidation } from '../../../shared/utility'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ConfirmDeleteModal from './ConfirmDeleteModal/ConfirmDeleteModal'
import DeleteUserModal from './DeleteUserModal/DeleteUserModal'
import Spinner from 'react-bootstrap/Spinner';
import EditEntityCreatorModal from './EditEntityCreatorModal/EditEntityCreatorModal'
import EditCreatorAndRoleModal from './EditCreatorAndRoleModal/EditCreatorAndRoleModal'
import { useHistory } from 'react-router'
import VisibilityIcon from '@material-ui/icons/Visibility';
import TooltipInfo from '../../UI/TooltipInfo/TooltipInfo'
import AEListGuide from '../../../containers/MasterData/AEListGuide/AEListGuide'

function UserForm(props) {
    let {
        activeValidation,
        user,
        mapUserRole2CompanyCategory,
        setUser,
        getUsersList,
        addUser,
        inputUserChange,
        updateUser,
        deleteUser,
        validateUser,
        desactivateUser,
        userCopy,
        addUserCopy,
        inputUserCopyChange,
        updateUserCopy,
        entitiesList,
        reloadUserList,
        reloadUserDetails,
        role,
        authUserId,
        fonctions,
        getFunctions,
        departements,
        setUserRoleChanged,
        userRoleChanged,
        handleFormChanged,
        loading,
        setUserVisualisation
    } = props
    let notyf = getNotyfObject();
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [showAddUser2EntityAgencyModal, setShowAddUser2EntityAgencyModal] = useState(false);
    const [showConfirmRoleModal, setShowConfirmRoleModal] = useState(false);
    const [showEntityAgenciesModal, setShowEntityAgenciesModal] = useState(false);
    const [errors, setErrors] = useState(null);
    const [mailError, setMailError] = useState(null);
    const [activeFunctions, setActiveFunctions] = useState(false);
    const { t, i18n } = useTranslation();
    const [userClone, setUserClone] = useState(null)
    const [roleTemp, setRoleTemp] = useState(null);
    const [userSuspendedDocs, setUserSuspendedDocs] = useState(false)
    const [creatorUserSuspendedDocs, setCreatorUserSuspendedDocs] = useState(false)

    const [isCheckedUuserSuspendedDocs, setIsCheckedUuserSuspendedDocs] = useState(false)

    const [noPossibleCreators, setNoPossibleCreators] = useState(false)
    const [notPossibleHasImposed, setNotPossibleHasImposed] = useState(false)
    const [imposedEntities, setImposedEntities] = useState(null)
    const [notPossibleHasAdvertiserContactDocs, SetNotPossibleHasAdvertiserContactDocs] = useState(false)
    const [advertiserContactDocs, setAdvertiserContactDocs] = useState(null)
    const [notPossibleHasnoApproSignRepr, setNotPossibleHasnoApproSignRepr] = useState(false)
    const [noApproSignReprEntities, setNoApproSignReprEntities] = useState(null)


    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const [showCreatorModal, setShowCreatorModal] = useState(false)
    const [showCreatorAndRoleModal, setShowCreatorAndRoleModal] = useState(false)
    const [userRoleToEdit, setUserRoleToEdit] = useState(null)
    const [entityToDelete, setEntityToDelete] = useState(null)
    const [showEntityCreatorModal, setShowEntityCreatorModal] = useState(false)
    const [entityPossibleCreators, setEntityPossibleCreators] = useState(null)
    //const [isCreatorInUserGroup, setIsCreatorInUserGroup] = useState(false)
    const userRolesList = useMemo(() => ([
        // {key: "empty", value: ""},
        { key: "AE", value: t('masterData:roleAE', "Administrateur EdiPub") },
        { key: "MG", value: t('masterData:roleMG', "Mandataire gestionnaire") },
        { key: "MR", value: t('masterData:roleMR', "Mandataire responsable") },
        { key: "MC", value: t('masterData:roleMC', "Mandataire contributeur") },
        { key: "AG", value: t('masterData:roleAG', "Annonceur gestionnaire") },
        { key: "AC", value: t('masterData:roleAC', "Annonceur contributeur") },
        { key: "RG", value: t('masterData:roleRG', "Gestionnaire régie") },
        { key: "RC", value: t('masterData:roleRC', "Consultation régie") }
    ]), [i18n.language])

    const uidUser = localStorage.getItem('userId');
    const history = useHistory();

    // const checkIsCreatorInUserGroup = (userUid) => {
    //     Axios.get(`/user/${userUid}/isCreatorInUserGroup`).then(response => {
    //         if(response?.data?.isCreatorInUserGroup){
    //             setIsCreatorInUserGroup(true)
    //         }
    //     }).catch(response => {
    //         notyf.error(t("mandat:generatedErrorMessage"));
    //     }) 
    // }


    useEffect(() => {
        setErrors(null)
        setUserSuspendedDocs(false)
        setCreatorUserSuspendedDocs(false)
        setNoPossibleCreators(false)
        setNotPossibleHasImposed(false)
        setNotPossibleHasnoApproSignRepr(false)
        SetNotPossibleHasAdvertiserContactDocs(false)
        setIsCheckedUuserSuspendedDocs(false)
        setUserRoleToEdit(null)
        setMailError(null)
        setImposedEntities(null)
        setNoApproSignReprEntities(null)
        setAdvertiserContactDocs(null)
        //setIsCreatorInUserGroup(false)
        // if(user?.userUid && (user?.role === "AC" || user?.role === "AG"  ) && ["MR", "MG"].includes(role)){
        //     checkIsCreatorInUserGroup(user?.userUid)
        // }
        if (showDeleteUserBtn())
            checkUserSuspendedDocs(user?.userUid)

        //set first role in the select list as default role if we don't have a role
        if (!user?.hasOwnProperty("role") || user?.role === "") {
            const firstRoleInSelect = getUserRoleOptions()[0]?.key;
            // roleUpdateHandler(firstRoleInSelect);
            setUser(user => ({
                ...user,
                role: firstRoleInSelect
            }))
        }
    }, [user, userCopy, userRoleChanged])

    useEffect(() => {
        setMailError(null)
    }, [user?.email])

    const showDeleteUserBtn = () => {

        if (user?.userUid) {
            switch (role) {
                case "MG":
                    if ("MC, MR, MG, AC, AG".includes(user?.role))
                        return true
                    else
                        return false
                    break;
                case "AG":  //le AG ne voit que des AC AG de la meme agence 
                    if ("AC, AG".includes(user?.role))
                        return true
                    else
                        return false
                    break;
                case "RG":   //le RG  ne voit que des RG RC de la meme regie
                    if ("RC, RG".includes(user?.role))
                        return true
                    else
                        return false
                    break;
                case "AE":
                    return true
                    break;
                default:
                    return false
                    break;
            }
        } else {
            return false
        }
    }
    // useEffect(() => {
    //     setErrors(null)
    // }, [userCopy, userRoleChanged])

    // useEffect(() => {
    //     if(props.role === "MG" && user?.email && !userCopy?.userId && !user?.userId && (!user?.role || user?.role == "AC" ) && checkValidity(user?.email, {required: true, isEmail: true})){
    //         handleUserEmailForAgency(null);
    //     }
    // }, [user?.email])  

    const checkUserSuspendedDocs = (userUid) => {
        Axios.get(`/user/${userUid}/checkUserSuspendedDocs`).then(response => {
            setIsCheckedUuserSuspendedDocs(true)
            if (response?.data?.notPossibleHasImposed) {
                setNotPossibleHasImposed(true)
                setImposedEntities(response?.data?.imposedEntities)
            }
            if (response?.data?.notPossibleHasnoApproSignRepr) {
                setNotPossibleHasnoApproSignRepr(true)
                setNoApproSignReprEntities(response?.data?.noApproSignReprEntities)
            }
            if (response?.data?.noPossibleCreators)
                setNoPossibleCreators(true)
            if (response?.data?.notPossibleHasAdvertiserContactDocs) {
                SetNotPossibleHasAdvertiserContactDocs(true)
                setAdvertiserContactDocs(response?.data?.advertiserContactDocs)
            }
            if (response?.data?.userSuspendedDocs)
                setUserSuspendedDocs(true)
            if (response?.data?.creatorUserSuspendedDocs)
                setCreatorUserSuspendedDocs(true)
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const handleUserEmailForAgency = (e) => {
        Axios.get("/user/" + user?.email + "/grantedFindByLogin").then(response => {
            if (response?.data?.user && response?.data?.user?.role === 'AC') {
                user.uid = response?.data?.user.uid;
                setShowAddUser2EntityAgencyModal(true);
            } else {
                if (e) {
                    //  creation pure, todo mettre etat a valider en plus par l'AG , pas seulement par l'AE !!!
                    addUser(e)
                }
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const checkEmailForAdd = (email) => {
        setMailError(null)
        Axios.get("/user/" + email + "/checkEmailForAdd", {
            params: {
                userUid: user?.userUid || null
            }
        }).then(response => {
            let responseData = response?.data;
            if (responseData && responseData?.hasOwnProperty('existEmail')) {
                if (responseData.existEmail && !responseData?.toAdd) {
                    if (responseData?.hasOwnProperty('toInvit') && responseData.toInvit) {
                        setUser({
                            ...user,
                            email,
                            uid: responseData?.userUid
                        })
                        //user.uid = responseData?.uid
                        setShowAddUser2EntityAgencyModal(true)
                    } else if (responseData?.hasOwnProperty('isVisible') && responseData.isVisible) {
                        setMailError(t("masterData:emailAlreadyVisible", "Cet utilisateur figure déjà dans votre liste"))
                    } else if (responseData?.hasOwnProperty('isDesactivated') && responseData.isDesactivated) {
                        setMailError(t("masterData:emailDesactivated", "Cet email est déjà attribué à un utilisateur qui a été desactivé, pour le réactiver contactez un administrateur Edipub"))
                    } else {
                        setMailError(t("default:emailAlreadyExist"))
                    }
                }
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const emailChangeHandler = (e) => {
        let email = e.target.value
        inputUserChange(e.target.name, e.target.value)
        if (checkValidity(email, { required: true, isEmail: true }))
            checkEmailForAdd(email)
    }

    const checkUserEntitySuspendedDocs = (entity) => {
        Axios.get(`/user/${user?.userUid}/userEntitySuspendedDocs`, {
            params: {
                entityUid: entity.uid
            }
        }).then(response => {
            const countDocs = response?.data?.countDocs;
            const possibleCreators = response?.data?.possibleCreators;

            if (+countDocs > 0) {
                if (possibleCreators) {
                    setEntityToDelete({
                        ...entity,
                        suspendedDocs: countDocs
                    })
                    setShowEntityCreatorModal(true)
                    setEntityPossibleCreators(possibleCreators)
                } else
                    notyf.error(t("masterData:deleteUniqueREntityError", "Cet utilisateur ne peut pas être supprimé car il est l'unique Mandataire (responsable/gestionaire) de cette agence pour laquelle il a des mandats en cours"))
            } else {
                deleteEntity(entity)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const checkUserEntityRequiredAtEntityLevel = (entity) => {
        Axios.get(`/user/${user?.userUid}/userEntitySuspendedDocs`, {
            params: {
                entityUid: entity.uid
            }
        }).then(response => {
            const countDocs = response?.data?.countDocs;
            const possibleCreators = response?.data?.possibleCreators;

            if (+countDocs > 0) {
                if (possibleCreators) {
                    setEntityToDelete({
                        ...entity,
                        suspendedDocs: countDocs
                    })
                    setShowEntityCreatorModal(true)
                    setEntityPossibleCreators(possibleCreators)
                } else
                    notyf.error(t("masterData:deleteUniqueMREntityError", "Cet utilisateur ne peut pas être supprimé car il est l'unique Mandataire (responsable/gestionaire) de cette agence pour laquelle il a des mandats en cours"))
            } else {
                deleteEntity(entity)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const deleteEntityclickHandler = (entity) => {
        if (user?.userUid && (user?.role === "MR" || user?.role === "MG" || user?.role === "MC") && (entity.mandatoryContributor == '1' || entity.mandatorySignatory == '1' || entity.mandatoryLegalRepresentative == '1' || entity.publicationRepresentative == '1')) {
            if (entity.mandatoryContributor == '1')
                notyf.error(t("masterData:errorDeleteMandatoryContributor", "Cet utilisateur ne peut pas être supprimé de cette société car il en est actuellement le contributeur obligatoire dans le processus d'approbation. Vous pouvez en changer sur la page de cette entité"))
            if (entity.mandatorySignatory == '1')
                notyf.error(t("masterData:errorDeleteMandatorySignatory", "Cet utilisateur ne peut pas être supprimé de cette société car il en est actuellement le signataire obligatoire dans le processus d'approbation. Vous pouvez en changer sur la page de cette entité"))
            if (entity.mandatoryLegalRepresentative == '1')
                notyf.error(t("masterData:errorDeleteMandatoryLegalRepresentative", "Cet utilisateur ne peut pas être supprimé de cette société car il en est actuellement le representant légal obligatoire dans les éditions de mandats. Vous pouvez en changer sur la page de cette entité"))
            if (entity.publicationRepresentative == '1')
                notyf.error(t("masterData:errorDeletePublicationRepresentative", "Cet utilisateur ne peut pas être supprimé de cette société car il en est actuellement le référent MyMandat / référent publication et reçois donc une copie des demandes d'accès aux mandats. Vous pouvez en changer dans la configuration de cette société."))
        } else if (user?.userUid && (user?.role === "MR" || user?.role === "MG")) {
            checkUserEntitySuspendedDocs(entity);
        } else {
            deleteEntity(entity)
        }
    }

    const deleteEntity = (entity, creator = null) => {
        let userTemp = { ...user }
        if (user?.userId && !entity?.new) {
            if (creator) {
                userTemp = {
                    ...userTemp,
                    creators: {
                        ...userTemp?.creators,
                        ...creator
                    },
                    entitiesToDelete: userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
                }
            } else {
                userTemp = {
                    ...userTemp,
                    entitiesToDelete: userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
                }
            }
        }
        let userEntities = [...user?.entities]
        let entityIndex = user?.entities.findIndex(ent => ent.uid === entity.uid);
        if (entityIndex >= 0) {
            userEntities.splice(entityIndex, 1)
        }

        if (userEntities.length == 1) {  //si plus que une entité , alors il faut mettre group_entities_roles a true
            userTemp = {
                ...userTemp,
                group_entities_roles: "1",
                is_signataire: userEntities[0].is_signataire,
                is_legal_representative: userEntities[0].is_legal_representative,
            }
        }
        setUser({
            ...userTemp,
            entities: userEntities
        })
        handleFormChanged(true);
    }

    const checkUserFormValidity = (user) => {
        let errors = []
        if (user?.first_name) {
            if (!user.hasOwnProperty('last_name') || !checkValidity(user?.last_name, { required: true })) {
                errors.push(t("masterData:error_userLastName"));
            }
            if (!user.hasOwnProperty('first_name') || !checkValidity(user?.first_name, { required: true })) {
                errors.push(t("masterData:error_userFirstName"));
            }
            // if(!user.hasOwnProperty('telephone_number') || !checkValidity(user?.telephone_number, {required: true, isPhone: true})){
            //     errors.push(t("masterData:error_userPhoneNumber")) ;
            // }
            if (!user.hasOwnProperty('role') || !checkValidity(user?.role, { required: true })) {
                errors.push(t("masterData:error_userRole"));
            }
            if (!user.hasOwnProperty('email') || !checkValidity(user?.email, { required: true, isEmail: true })) {
                errors.push(t("masterData:error_userEmail"));
            }
            if (user?.role != 'AE' && (!user.hasOwnProperty('entities') || user?.entities.length == 0)) {
                errors.push(t("masterData:error_userEntity"));
            }
        } else {
            errors.push(t("masterData:error_userFirstName"));
        }
        return errors;
    }

    const inputDepartementChange = (name, value) => {
        if (value != "") {
            getFunctions(value)
            setActiveFunctions(true)
        } else
            setActiveFunctions(false)

    }

    const checkUserCopyFormValidity = (userCopy) => {
        let errors = []
        if (!userCopy.hasOwnProperty('last_name') || !checkValidity(userCopy?.last_name, { required: true })) {
            errors.push(t("masterData:error_userFirstName"));
        }
        if (!userCopy.hasOwnProperty('first_name') || !checkValidity(userCopy?.first_name, { required: true })) {
            errors.push(t("masterData:error_userLastName"));
        }
        if (!userCopy.hasOwnProperty('telephone_number') || !checkValidity(userCopy?.telephone_number, { required: true, isPhone: true })) {
            errors.push(t("masterData:error_userPhoneNumber"));
        }
        return errors;
    }

    const addUserclickHandler = (e) => {
        e.preventDefault();
        let checkErrors = checkUserFormValidity(user);
        checkErrors = mailError ? [mailError, ...checkErrors] : checkErrors;
        if (checkErrors && checkErrors.length > 0) {
            setErrors(checkErrors)
        } else {
            setErrors(null)
            addUser(e)
            // if(props.role === "MG" && (user.role === "AC" || user.role === "AG")){
            //     handleUserEmailForAgency(e);
            // } else {                 
            //     addUser(e)
            // }
        }
        handleFormChanged(false);
    }

    const updateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        let checkErrors = checkUserFormValidity(user);
        checkErrors = mailError ? [mailError, ...checkErrors] : checkErrors;
        if (checkErrors && checkErrors.length > 0) {
            setErrors(checkErrors)
        } else {
            setErrors(null)
            updateUser(e, userUid)
        }
        handleFormChanged(false);
    }

    const deleteUserClickHandler = (e, userUid) => {
        e.preventDefault();

        if (!isCheckedUuserSuspendedDocs) {
            setErrors(["erreur bouton supprimer affiche à tors sans vérification préalable"])
        } else if (notPossibleHasImposed) {
            let msg = ' '
            let cpt = 0
            imposedEntities.forEach((e) => {
                cpt++
                let tempRoles = []
                if (e.is_contributor)
                    tempRoles.push(t('masterData:contributorImposed', "Vérificateur imposé"))
                if (e.is_signatory)
                    tempRoles.push(t('masterData:signatoryImposed', "Signataire imposé"))
                if (e.is_legal_representative)
                    tempRoles.push(t('masterData:legalRepresentativeImposed', "Représentant légal imposé"))
                msg += cpt == 1 ? '' : ', '
                msg += t('default:agency', "AGENCE").toUpperCase() + " " + e.official_name + " (" + tempRoles.join(', ') + ")"
            });
            setErrors([t("masterData:deleteImposedUserError", "Cet utilisateur ne peut pas être supprimé car il est désigné dans les choix imposés pour le workflow d’approbation de certaines agences. Veuillez éditer les agences suivantes avant de le supprimer") + msg])
        }
        else if (noPossibleCreators) {
            setErrors([t("masterData:deleteUniqueMRError", "Cet utilisateur ne peut pas être supprimé car il est l'unique Mandataire responsable d'une agence pour laquelle il a des mandats en cours")])
        }
        else if (notPossibleHasnoApproSignRepr) {
            // si il n'est pas créateur (exemple unique user sous mandataire , sous mandataire ayant été ajouté par user mandataire principal précédement supprimé )   
            let msg = ' '
            let cpt = 0
            noApproSignReprEntities.forEach((e) => {
                cpt++
                msg += cpt == 1 ? '' : ', '
                msg += t('default:agency', "AGENCE").toUpperCase() + " " + e.official_name
            });
            setErrors([t("masterData:deleteNoApproSignReprUserError", "Cet utilisateur ne peut pas être supprimé car il est l'unique signataire / contributeur / représentant légal de certaines agences pour lesquelles il a des mandats en cours.") + msg])
        }
        else if (notPossibleHasAdvertiserContactDocs) {
            let msg = ' '
            let cpt = 0
            advertiserContactDocs.forEach((d) => {
                cpt++
                msg += cpt === 1 ? '' : ', '
                msg += "ADM-" + d.id
            });
            setErrors([t("masterData:deleteHasAdvertiserContactError", "Cet utilisateur ne peut pas être supprimé car il est le contact d'un signataire annonceur inconnu pour des mandats en cours.") + msg])
        }
        else {
            if (userSuspendedDocs)
                setShowCreatorModal(true)
            else
                setShowDeleteConfirmModal(true)
        }
    }


    const validateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        handleFormChanged(false);
        let errors = checkUserFormValidity(user);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            validateUser(e, userUid)
        }
    }

    const desactivateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        handleFormChanged(false);
        let errors = checkUserFormValidity(user);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            desactivateUser(e, userUid)
            props.reloadUserList()
        }
    }

    const addUserCopyclickHandler = (e, userUid) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            addUserCopy(e, userUid)
        }
        handleFormChanged(false);
    }

    const updateUserCopyclickHandler = (e, userCopyUid) => {
        e.preventDefault();
        let errors = checkUserCopyFormValidity(userCopy);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            updateUserCopy(e, userCopyUid)
            props.reloadUserList()
        }
        handleFormChanged(false);
    }

    const User2EntityAgencyAddHandler = () => {
        if (user.uid)
            Axios.post("entity//user2EntityAgency", { newUserUid: user.uid }).then(response => {
                props.reloadUserList()
                props.reloadUserDetails(user.uid, true)
                notyf.success(t("masterData:newUser"));
            }).catch(response => {
                notyf.error("mandat:generatedErrorMessage");
            })
    }

    const roleClickHandler = (e) => {
        let roleChangeEntities = false;
        if (user?.entities && e.target.value !== "") {
            let companyCategory = props.mapUserRole2CompanyCategory.get(e.target.value)
            user.entities.forEach(element => {
                if (element.category != companyCategory)
                    roleChangeEntities = true;
            });
        }
        if (roleChangeEntities) {
            setRoleTemp(e.target.value)
            setShowConfirmRoleModal(true);
        }
        else {
            if ((user?.userId || userCopy?.userId) && ["MG", "MR"].includes(user?.role) && e.target.value === "MC") {
                if (noPossibleCreators) {
                    setErrors([t("masterData:changeRoleUniqueMRError", "Le role de cet utilisateur ne peut pas être modifié car il est l'unique Mandataire (responsable | géstionnaire) d'une agence pour laquelle il a des mandats en cours")])
                } else {
                    if (creatorUserSuspendedDocs) {
                        if (!isCheckedUuserSuspendedDocs) {
                            setErrors(["erreur changement role autorisé à tors sans vérification préalable"])
                        } else {
                            setUserRoleToEdit(e.target.value)
                            setShowCreatorAndRoleModal(true)
                        }
                    } else
                        roleUpdateHandler(e.target.value)
                }
            } else
                roleUpdateHandler(e.target.value)

        }
    }

    const roleUpdateHandler = (role) => {
        inputUserChange("role", role)
    }

    const disableRoleInupt = () => {
        switch (role) {
            case "MC":
                return true;
                break;
            case "AG":
            case "RG":
            case "MG":
            case "MR":
            case "AE":
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    const getUserRoleOptions = () => {
        switch (role) {
            case "AE":
                return userRolesList;
                break;
            case "MG":
                if (user?.userId || userCopy?.userId) {
                    if (user?.role === "AC" || user?.role === "AG")
                        return userRolesList.filter(option => ["AC", "AG"].includes(option.key))
                    else
                        return userRolesList.filter(option => ["MG", "MR", "MC"].includes(option.key))
                } else
                    return userRolesList.filter(option => ["AC", "AG", "MG", "MR", "MC"].includes(option.key))
                break;
            case "AG":
                if (user?.userId || userCopy?.userId)
                    return userRolesList.filter(option => ["AC", "AG"].includes(option.key))
                else
                    return userRolesList.filter(option => ["AC", "AG"].includes(option.key))
                break;
            case "RG":
                if (user?.userId || userCopy?.userId)
                    return userRolesList.filter(option => ["RC", "RG"].includes(option.key))
                else
                    return userRolesList.filter(option => ["RC", "RG"].includes(option.key))
                break;
            case "MR":
                if (user?.userId || userCopy?.userId) {
                    if (user?.role === "AC" || user?.role === "AG") {
                        return userRolesList.filter(option => ["AC", "AG"].includes(option.key))
                    }
                } else
                    return userRolesList.filter(option => ["empty", "AC", "AG"].includes(option.key))
            default:
                return userRolesList;
                break;
        }
    }

    const capitalizeFirstLetter = (string) => {
        if (string != null) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else
            return ''
    }

    const deleteAuthorizedAgencyClickHandler = (entity) => {
        let userTemp = { ...user }
        if (user?.userId && !entity?.new) {
            userTemp = {
                ...userTemp,
                authorizedAgenciesToDelete: userTemp?.authorizedAgenciesToDelete ? [...userTemp?.authorizedAgenciesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userAuthorizedAgencies = [...user?.authorizedAgencies]
        let entityIndex = user?.authorizedAgencies.findIndex(ent => ent.uid === entity.uid);
        if (entityIndex >= 0) {
            userAuthorizedAgencies.splice(entityIndex, 1)
        }
        setUser({
            ...userTemp,
            authorizedAgencies: userAuthorizedAgencies
        })
        handleFormChanged(true);
    }

    let userRolesDetails = {
        "AE": t("masterData:roleAE"),
        "MC": t("masterData:roleMC"),
        "MR": t("masterData:roleMR"),
        "MG": t("masterData:roleMG"),
        "AC": t("masterData:roleAC"),
        "AG": t("masterData:roleAG"),
        "RC": t("masterData:roleRC"),
        "RG": t("masterData:roleRG")
    }
    let userRolesFilter = [
        { key: "AE", value: "AE" },
        { key: "AE,MG", value: "MC,MR,MG" },
        { key: "AE,AG,MG,MR", value: "AC,AG" },
        { key: "AE,RG", value: "RG,RC" }
    ]

    const getTokenVisualisation = () => {
        const userUid = userCopy?.userUid ? userCopy?.userUid : user?.userUid;
        Axios.get('/user/' + userUid + '/userTokenVisualisation')
            .then(response => {
                const userVisToken = response?.data?.token;
                const userVisUid = response?.data?.userVisUid;
                const userVisRole = response?.data?.userVisRole;
                const userVisLogin = response?.data?.userVisEmail;
                setUserVisualisation(userVisToken, userVisUid, userVisRole, userVisLogin);
                history.push("/")
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage"));
            })
    }

    const showVisualisationBtn = () => {
        const isAdmin = role === "AE";
        const selectedUser = user?.userUid || userCopy?.userUid;
        const isSameUser = user?.userUid === authUserId;
        if (isAdmin && selectedUser && !isSameUser) {
            return true
        } else {
            return false
        }
    }

    const checkEntityField = (entity, field, value) => {
        let entities = userCopy?.entities?.length > 0 ? userCopy?.entities : user?.entities
        let entityIndex = entities.findIndex(row => row.uid === entity.uid);
        let entitiesTemp = [...entities];
        entitiesTemp[entityIndex][field] = (value ? "1" : "0")
        return entitiesTemp;
    }


    const checkGroupEntitiesRoles = (entity, value) => {
        let userTemp = { ...user }
        let entitiesTemp = [...user.entities];
        entitiesTemp.forEach(element => {
            element.is_signataire = value ? user.entities[0].is_signataire : user.is_signataire
            element.is_legal_representative = value ? user.entities[0].is_legal_representative : user.is_legal_representative
        });
        userTemp = {
            ...userTemp,
            entities: entitiesTemp,
            group_entities_roles: value
        }
        if (value)
            userTemp = {
                ...userTemp,
                is_signataire: user.entities[0].is_signataire,
                is_legal_representative: user.entities[0].is_legal_representative,
            }
        setUser(userTemp);
        handleFormChanged(true);
    }

    let filtredUserRoles = userRolesFilter.filter(uRoles => uRoles.key.includes(role));
    let nbRolesForUser = filtredUserRoles.filter(uRoles => uRoles.value.includes(user?.role || userCopy?.role))


    return (
        <>
            {/* Partie droite */}

            <div className="rightSide_header_container">
                <div className="header_infos_container">
                    <img className="rightSide_header_logo" src={avatar} />
                    <div className="header_labels_container">
                        <strong>{userCopy?.last_name ? capitalizeFirstLetter(userCopy?.last_name) : (user ? capitalizeFirstLetter(user?.last_name) : '')} </strong>
                        <strong>{userCopy?.first_name ? capitalizeFirstLetter(userCopy?.first_name) : (user ? capitalizeFirstLetter(user?.first_name) : '')} </strong>
                    </div>
                </div>
                <div className="form_element_container">
                    {
                        user?.userId && (user?.status.toLowerCase() === "invitation") && (+user?.pending === 0) ? (
                            <div className="rightSide_header_sentInvitation">
                                <div style={{ paddingRight: "10px" }}><i style={{ margin: "5px" }} className="fas fa-check"></i>{t('masterData:sentInvitation', "Invitation envoyée")} </div>
                            </div>
                        ) : user?.userId && (user?.status.toLowerCase() === "invitation") && (+user?.pending === 1) ? (
                            <div className="rightSide_header_pendingValidation">
                                <div >
                                    <i style={{ marginRight: "5px" }} className="fa fa-spinner"></i>
                                    {t('masterData:userValidationPending', "Invitation envoyée, en attente de validation")}
                                </div>
                            </div>
                        ) : (user?.userId || userCopy?.userId) && (+userCopy?.pending === 1) ? (
                            <div className="rightSide_header_pendingValidation">
                                <div >
                                    <i style={{ marginRight: "5px" }} className="fa fa-spinner"></i>
                                    {t('masterData:validationPending', "en attente de validation")}
                                </div>
                            </div>
                        ) : null
                    }
                </div>

            </div>
            {
                errors && errors.length > 0 ? (
                    <div className="alert alert-danger mt-2" role="alert">
                        <ul>
                            {
                                errors.map(error => (<li key={error}>{error}</li>))
                            }
                        </ul>
                    </div>
                ) : null
            }

            <div className="rightSide_form_container">
                <form>
                    <div className="form_required_container">
                        <p className="form_required_label">
                            <span className="form_required_asterisk">*</span>
                            {t("masterData:requiredFields", "Champs obligatoires")}
                        </p>
                    </div>
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label htmlFor="first_name">{t('default:firstName', "Prénom")} / {t('default:service', "Service")} <span className="form_required_asterisk">*</span></label>
                            <input type="text" className="form-control" required minLength="1" id="first_name" name="first_name" value={userCopy?.first_name || user?.first_name || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value))} disabled={("MC".includes(role) || ("MG, MR".includes(role) && "RG".includes(user?.role))) ? true : false}></input>
                        </div>
                        <div className="form_element_container">
                            <label htmlFor="last_name">{t('default:name', "Nom")} / {t('default:compService', "Complément service")} <span className="form_required_asterisk">*</span></label>
                            <input type="text" className="form-control" required minLength="1" id="last_name" name="last_name" value={userCopy?.last_name || user?.last_name || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value))} disabled={("MC".includes(role) || ("MG, MR".includes(role) && "RG".includes(user?.role))) ? true : false}></input>
                        </div>
                    </div>
                    {// RG, MG, AE
                        (("RG, MG, MR, AE".includes(role) && (user?.userId || userCopy?.userId))
                            || ("AE, AG, MG, MR, RG".includes(role) && (!user?.userId && !userCopy?.userId))) ?
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <label htmlFor="language">{t('default:language', "language")} <span className="form_required_asterisk">*</span> </label>

                                    <select className="form-control" id="language" name="language"
                                        value={userCopy?.language || user?.language || 'FR'}
                                        onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value))}
                                        disabled={("MC".includes(role) || ("MG, MR".includes(role) && "RG".includes(user?.role))) ? true : false} >
                                        <option value="FR">FR</option>
                                        <option value="EN">EN</option>
                                    </select>


                                    {/* <input type="text" className="form-control" required minLength="1" id="language" name="language" value={userCopy?.language || user?.language || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value)) } disabled={("MC, MR".includes(role) || ("MG".includes(role) && ("AG".includes(user?.role) || "RG".includes(user?.role))))? true : false}></input> */}
                                </div>
                                <div className="form_element_container">
                                    <input
                                        style={{ margin: "23px 8px 0px 2px" }}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="notification"
                                        value=""
                                        id="notification"
                                        checked={((user?.notification && user?.notification == "1") || (userCopy?.notification && userCopy?.notification == "1")) ? true : false}

                                        onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.checked) : inputUserChange(e.target.name, e.target.checked))}
                                        disabled={("MC".includes(role) || ("MG, MR".includes(role) && "RG".includes(user?.role))) ? true : false}
                                    />
                                    <label className="form-check-label" htmlFor="notification">
                                        {t('default:userNotifications', "userNotifications")}
                                    </label>
                                </div>
                            </div>
                            : ""
                    }
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label htmlFor="mail">{t('default:mailAddress', "Adresse mail")} <span className="form_required_asterisk">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="mail"
                                required
                                minLength="1"
                                name="email"
                                value={userCopy?.email || user?.email || ''}
                                //onChange={(e) => inputUserChange(e.target.name, e.target.value)} 
                                onChange={(e) => emailChangeHandler(e)}
                                disabled={
                                    (
                                        "MC".includes(role)
                                        || (
                                            "MG, MR".includes(role)
                                            && (user?.role === "AC" || user?.role === "AG")
                                            && user?.userId
                                            && user?.status !== "Nouveau"
                                        )
                                        || userCopy?.userId
                                        //|| ("MG, MR".includes(role) && ("RG".includes(user?.role)))    // inutile à priori car les mandataires voient pas le utilisateurs regie 
                                    ) ? true : false}
                            />
                            {
                                mailError ?
                                    <small style={{ color: "red" }} >{mailError}</small>
                                    : null
                            }
                        </div>
                        <div className="form_element_container">
                            <label htmlFor="phone" style={{ display: "flex", alignItems: "baseline" }} >
                                <span>{t('default:mobileNumber', "Numéro de portable")}</span>
                                <TooltipInfo
                                    content={t('masterData:mobileNumberTooltip', "Le numéro de téléphone n’est pas obligatoire ici, mais il est préconisé de le saisir pour des signataires, pour éviter sa saisie au moment de la signature électronique. Il est possible de le masquer")}
                                    dropdownZindex={100}
                                />
                            </label>
                            {/* <input 
                                type="text" 
                                className="form-control" 
                                id="phone" 
                                required minLength="1" 
                                name="telephone_number" 
                                value={userCopy?.telephone_number || user?.telephone_number || ''} 
                                onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value)) } 
                                disabled={( "MC, MR".includes(role))? true : false} ></input> */}
                            {
                                (!user?.userId || (user?.telVisible && parseInt(user?.telVisible) === 1) || user?.userUid === authUserId) ? (
                                    <PhoneInput
                                        disabled={("MC".includes(role) || ("MG, MR".includes(role) && "RG".includes(user?.role))) ? true : false}
                                        localization={fr}
                                        country={'fr'}
                                        inputClass={"form-control w-100"}
                                        dropdownStyle={{ width: "auto" }}
                                        preferredCountries={['fr', 'us']}
                                        enableSearch={false}
                                        inputProps={{ name: "telephone_number" }}
                                        placeholder={"33 0 00 00 00 00"}
                                        value={userCopy?.telephone_number?.substr(2) || user?.telephone_number?.substr(2) || ''}
                                        onChange={phone => (
                                            userCopy?.userId ?
                                                inputUserCopyChange("telephone_number", phone ? "00" + phone : null)
                                                :
                                                inputUserChange("telephone_number", phone ? "00" + phone : null))}
                                    />) : <p><strong>Masqué par l'utilisateur</strong></p>
                            }
                        </div>
                    </div>
                    {/* les 2 cards */}
                    <div className="form_twoElements_container">

                        <div className="form_element_container">
                            <label>{t('default:role', "Rôle")} <span className="form_required_asterisk">*</span> </label>

                            {(
                                typeof userCopy?.role === "undefined" && typeof user?.role === "undefined") ||
                                filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length > 0 ? (

                                <select className="form-control" required id="role" aria-label="Default select example" name="role"
                                    value={userCopy?.role || user?.role || ''}
                                    onChange={(e) => roleClickHandler(e)}
                                    disabled={disableRoleInupt()}
                                // disabled={( "MC, MR".includes(role) || userCopy?.userId || ("MG".includes(role) && (user?.userId) || userCopy?.userId))? true : false} 
                                >
                                    {
                                        getUserRoleOptions().map(option => (
                                            <option value={option.key}>{option.value}</option>
                                        ))
                                    }

                                </select>

                            ) :
                                <input type="text" className="form-control" id="role" name="role"
                                    value={userRolesDetails[userCopy?.role] || userRolesDetails[user?.role] || ''}
                                    onChange={(e) => (userCopy?.userId
                                        ? inputUserCopyChange(e.target.name, e.target.value)
                                        : inputUserChange(e.target.name, e.target.value))}
                                    disabled="true" ></input>
                            }
                            {
                                showCreatorAndRoleModal ? (
                                    <EditCreatorAndRoleModal
                                        show={showCreatorAndRoleModal}
                                        handleClose={() => setShowCreatorAndRoleModal(false)}
                                        userUid={user?.userUid}
                                        userRole={userRoleToEdit}
                                        roleUpdateHandler={roleUpdateHandler}
                                        setUser={setUser}
                                    />
                                ) : null
                            }
                            {
                                user?.role !== 'RG' && user?.role !== 'RC' && user?.role !== 'AE' ? (
                                    <div className="entityUser_roles" >
                                        {
                                            (user?.entities?.length > 1 || userCopy?.entities?.length > 1) &&
                                            <div className="">
                                                <input
                                                    style={{ margin: "6px 8px 0px 2px" }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="group_entities_roles"
                                                    value=""
                                                    id="group_entities_roles"
                                                    checked={(
                                                        (user && user?.status === "Nouveau" && typeof user.group_entities_roles === 'undefined') ?
                                                            true
                                                            : ((user?.group_entities_roles && user?.group_entities_roles == "1") ? true : false)
                                                    )}
                                                    disabled={(
                                                        "MC".includes(role) ||
                                                        userCopy?.userId
                                                        // || ("MG, MR".includes(role) && "RG".includes(user?.role))   // inutile à priori car les mandataires voient pas le utilisateurs regie 
                                                    ) ? true : false
                                                    }
                                                    onChange={(e) => checkGroupEntitiesRoles(e.target.name, e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="group_entities_roles">
                                                    {t('mandat:groupEntitiesRoles', "Définir le même rôle sur toutes les sociétés")}
                                                </label>
                                            </div>
                                        }
                                        <div className="" style={{ paddingLeft: "20px" }}>
                                            <input
                                                style={{ margin: "6px 8px 0px 2px" }}
                                                className="form-check-input"
                                                type="checkbox"
                                                name="is_signataire"
                                                value=""
                                                id="is_signataire"
                                                checked={((user?.is_signataire && user?.is_signataire == "1") || (userCopy?.is_signataire && userCopy?.is_signataire == "1")) ? true : false}
                                                //disabled
                                                onChange={(e) => inputUserChange(e.target.name, e.target.checked)}
                                                disabled={(
                                                    "MC".includes(role) ||
                                                    userCopy?.userId ||
                                                    //("MG, MR".includes(role) && ("RG".includes(user?.role))) ||  // inutile à priori car les mandataires voient pas le utilisateurs regie
                                                    (user?.group_entities_roles === "0" || user?.group_entities_roles === false) ||
                                                    ((user?.group_entities_roles && user?.group_entities_roles === "1") && user?.entities?.some(row => row.mandatorySignatory === "1"))
                                                ) ? true : false
                                                }
                                            />
                                            <label className="form-check-label" htmlFor="is_signataire">
                                                {t('default:signatory', "Signataire")}
                                            </label>
                                        </div>
                                        <div className="" style={{ paddingLeft: "20px" }}>
                                            <input
                                                style={{ margin: "6px 8px 0px 2px" }}
                                                className="form-check-input"
                                                type="checkbox"
                                                name="is_legal_representative"
                                                value=""
                                                id="is_legal_representative"
                                                checked={((user?.is_legal_representative && user?.is_legal_representative == "1") || (userCopy?.is_legal_representative && userCopy?.is_legal_representative == "1")) ? true : false}
                                                disabled={(
                                                    "MC".includes(role) ||
                                                    userCopy?.userId ||
                                                    //("MG, MR".includes(role) && ("RG".includes(user?.role))) ||  // inutile à priori les mandataires voient pas le utilisateurs regie
                                                    (user?.group_entities_roles === "0" || user?.group_entities_roles === false) ||
                                                    ((user?.group_entities_roles && user?.group_entities_roles === "1") && user?.entities?.some(row => row.mandatoryLegalRepresentative === "1"))
                                                ) ? true : false
                                                }
                                                onChange={(e) => inputUserChange(e.target.name, e.target.checked)}
                                            // disabled={( "MC, MR".includes(role) || userCopy?.userId || ("MG".includes(role) && user?.role === "AC") || ("MG".includes(role) && ("AG".includes(user?.role) || "RG".includes(user?.role))))? true : false}
                                            />
                                            <label className="form-check-label" htmlFor="is_legal_representative">
                                                {t('mandat:legalRepresentative', "Représentant légal")}
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            showConfirmRoleModal ? (
                                <ConfirmRoleModal
                                    show={showConfirmRoleModal}
                                    role={roleTemp}
                                    handleClose={() => setShowConfirmRoleModal(false)}
                                    updateRole={roleUpdateHandler}
                                />
                            ) : null
                        }

                        {user?.role !== 'AE' ? (
                            <div className="form_element_container">
                                <label >
                                    {t('default:society', "Société")}  <span className="form_required_asterisk">*</span>
                                    {
                                        (user?.role &&
                                            (
                                                (
                                                    !("MC".includes(role))
                                                    && !userCopy?.userId
                                                    // && !("MG, MR".includes(role) && "RG".includes(user?.role)) // inutile à priori les mandataires voient pas le utilisateurs regie
                                                )
                                            )) ?
                                            (
                                                <i className="fas fa-plus addEntities_icon" onClick={() => setShowEntityModal(true)} ></i>
                                            ) : null
                                    }
                                    {showEntityModal ? (
                                        <EntityUserModal
                                            handleClose={() => setShowEntityModal(false)}
                                            show={showEntityModal}
                                            addEntitiesUser={inputUserChange}
                                            userEntities={user?.entities ? user?.entities : userCopy?.entities}
                                            userRole={user?.role ? user?.role : userCopy?.role}
                                        />
                                    ) : null}
                                </label>
                                <div className="form_pillsElements_container" >
                                    {
                                        (user?.entities?.length > 0 || userCopy?.entities?.length > 0) ? (userCopy?.entities?.length > 0 ? userCopy?.entities : user?.entities).map(entity => (
                                            <div className="form_pillElement_container" key={entity.uid} style={{ height: "100%" }}>
                                                <div className="pillElement_infos" style={{ height: "100%" }}>
                                                    <div className="pillElement_label">
                                                        <img className="pillElement_icon" src={iconEntity} ></img>
                                                        {entity.commercial_name}
                                                        {
                                                            (user?.group_entities_roles === "0" || user?.group_entities_roles === false) &&
                                                            <>
                                                                <div className="" style={{ paddingLeft: "26px" }}>
                                                                    <input
                                                                        style={{ margin: "6px 8px 0px 2px" }}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="is_signataire"
                                                                        value=""
                                                                        id="is_signataire"
                                                                        checked={(entity?.is_signataire && entity?.is_signataire == "1") ? true : false}
                                                                        onChange={(e) => inputUserChange("entities", checkEntityField(entity, e.target.name, e.target.checked))}
                                                                        disabled={(
                                                                            "MC".includes(role) ||
                                                                            userCopy?.userId ||
                                                                            //  ( "MG, MR".includes(role) && "RG".includes(user?.role) )  || // inutile à priori les mandataires voient pas le utilisateurs regie                                                                                    
                                                                            (entity.mandatorySignatory == '1')
                                                                        ) ? true : false
                                                                        }
                                                                    />
                                                                    <label className="form-check-label" htmlFor="is_signataire">
                                                                        {t('default:signatory', "Signataire")}
                                                                    </label>
                                                                    <span>&nbsp;&nbsp;</span>
                                                                    <input
                                                                        style={{ margin: "6px 8px 0px 2px", paddingLeft: "30px" }}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="is_legal_representative"
                                                                        value=""
                                                                        id="is_legal_representative"
                                                                        checked={(entity?.is_legal_representative && entity?.is_legal_representative == "1") ? true : false}
                                                                        disabled={(
                                                                            "MC".includes(role) ||
                                                                            userCopy?.userId ||
                                                                            // ( "MG, MR".includes(role) && "RG".includes(user?.role) )  || // inutile à priori les mandataires voient pas le utilisateurs regie                                                                                    
                                                                            (entity.mandatoryLegalRepresentative == '1')
                                                                        ) ? true : false
                                                                        }
                                                                        onChange={(e) => inputUserChange("entities", checkEntityField(entity, e.target.name, e.target.checked))}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="is_legal_representative">
                                                                        {t('mandat:legalRepresentative', "Représentant légal")}
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    (
                                                        !("MC".includes(role)) && !userCopy?.userId
                                                        // && !("MG, MR".includes(role) && "RG".includes(user?.role))  // inutile à priori car les mandataires voient pas le utilisateurs regie 
                                                    ) ? (
                                                        <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteEntityclickHandler(entity)} />
                                                    ) : null
                                                }
                                            </div>
                                        )) : null
                                    }

                                    {
                                        showEntityCreatorModal ? (
                                            <EditEntityCreatorModal
                                                show={showEntityCreatorModal}
                                                handleClose={() => setShowEntityCreatorModal(false)}
                                                userUid={user?.userUid}
                                                entityToDelete={entityToDelete}
                                                deleteEntity={deleteEntity}
                                                entityPossibleCreators={entityPossibleCreators}
                                                setUser={setUser}
                                            />
                                        ) : null
                                    }

                                </div>
                                {
                                    showAddUser2EntityAgencyModal ? (
                                        <AddUser2EntityAgencyModal
                                            show={showAddUser2EntityAgencyModal}
                                            handleClose={() => setShowAddUser2EntityAgencyModal(false)}
                                            addUser2EntityAgency={User2EntityAgencyAddHandler}
                                        />
                                    ) : null
                                }

                            </div>
                        ) : null
                        }
                    </div>

                    {userCopy?.role || user?.role ?
                        "AE, MR, MG, MC".includes(role) ?
                            "MR,MG,MC".includes(user?.role) || "MR,MG,MC".includes(userCopy?.role) ?
                                // <DepartmentFunctions className=""
                                //     inputUserChange={inputUserChange}
                                //     inputUserCopyChange={inputUserCopyChange}
                                //     inputDepartementChange={inputDepartementChange}
                                //     filtredUserRoles={filtredUserRoles}
                                //     user={user}
                                //     userCopy={userCopy}
                                //     departements={departements}
                                //     fonctions={fonctions}
                                //     activeValidation={activeValidation} 
                                ///>
                                <div className="form_functions_departement">
                                    <div className="form_oneElement_container" style={{ width: "46.5%" }}>
                                        <label htmlFor="departementId">{t('default:departement', "Département")} </label>

                                        <select className="form-control" name="departementId" style={{ paddingLeft: "0" }}
                                            onChange={(e) => {
                                                userCopy?.userId
                                                    ? inputUserCopyChange(e.target.name, e.target.value)
                                                    : inputUserChange(e.target.name, e.target.value)

                                                inputDepartementChange(e.target.name, e.target.value)
                                            }}
                                            disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                            value={(user?.departementId != "" || userCopy?.departementId != "")
                                                ? (userCopy?.departementId || user?.departementId)
                                                : ""}>
                                            <option value=""></option>
                                            {
                                                departements?.map(d => (
                                                    d.nbAgenceF > 0 ?
                                                        <option value={d.id}>{d.name}</option>
                                                        : ""
                                                ))
                                            }
                                        </select>

                                    </div>
                                    {
                                        activeFunctions || user?.role != "" ?
                                            <div className="form_oneElement_container" style={{ width: "46.5%" }}>
                                                <label htmlFor="functionId">{t('default:function', "Fonction")} </label>
                                                <select className="form-control" name="functionId" style={{ paddingLeft: "0" }}
                                                    onChange={(e) => (userCopy?.userId
                                                        ? inputUserCopyChange(e.target.name, e.target.value)
                                                        : inputUserChange(e.target.name, e.target.value))}
                                                    disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                                    value={(user?.functionId != "" || userCopy?.functionId != "")
                                                        ? (userCopy?.functionId || user?.functionId) : ""
                                                    }>
                                                    <option value=""></option>
                                                    {
                                                        fonctions?.sort((functionA, functionB) => functionA.label_agence?.toLowerCase().localeCompare(functionB.label_agence?.toLowerCase()))
                                                            .map(f => (f?.label_agence != "" || f?.label_regie != "" || f?.label ?
                                                                <option value={f.functionId}>{f?.label_agence || f?.label_regie || f?.label}</option>
                                                                : ""
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            : ""

                                    }
                                </div>
                                : "AG,AC".includes(user?.role) || "AG,AC".includes(userCopy?.role) ?
                                    <div className="form_oneElement_container">
                                        <label htmlFor="fonction">{t('default:function', "Fonction")}</label>
                                        <input type="text" className="form-control" id="fonction" minLength="1" name="fonctionLabel" value={userCopy?.fonctionLabel || user?.fonctionLabel || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value))} disabled={("MC".includes(role)) ? true : false} ></input>
                                    </div>

                                    : "RC, RG".includes(user?.role) || "RC, RG".includes(userCopy?.role) ?
                                        <div className="form_functions_departement">
                                            <div className="form_oneElement_container" style={{ width: "43%" }}>
                                                <label htmlFor="departementId">{t('default:departement', "Département")}</label>

                                                <select className="form-control" name="departementId" style={{ maxWidth: "184px", paddingLeft: "0" }}
                                                    onChange={(e) => {
                                                        userCopy?.userId
                                                            ? inputUserCopyChange(e.target.name, e.target.value)
                                                            : inputUserChange(e.target.name, e.target.value)

                                                        inputDepartementChange(e.target.name, e.target.value)
                                                    }}
                                                    disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                                    value={(user?.departementId != "" || userCopy?.departementId != "")
                                                        ? (userCopy?.departementId || user?.departementId)
                                                        : ""}>
                                                    <option value=""></option>
                                                    {
                                                        departements?.map(d => (
                                                            d.nbRegieF > 0 ?
                                                                <option value={d.id}>{d.name}</option>
                                                                : ""
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {
                                                activeFunctions || user?.role != "" ?
                                                    <div className="form_oneElement_container" style={{ width: "47%" }}>
                                                        <label htmlFor="functionId">{t('default:function', "Fonction")}</label>
                                                        <select className="form-control" name="functionId" style={{ paddingLeft: "0" }}
                                                            onChange={(e) => (userCopy?.userId
                                                                ? inputUserCopyChange(e.target.name, e.target.value)
                                                                : inputUserChange(e.target.name, e.target.value))}
                                                            disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                                            value={(user?.functionId != "" || userCopy?.functionId != "")
                                                                ? (userCopy?.functionId || user?.functionId) : ""
                                                            }>
                                                            <option value=""></option>
                                                            {
                                                                fonctions?.sort((functionA, functionB) => functionA.label_regie?.toLowerCase().localeCompare(functionB.label_regie?.toLowerCase()))
                                                                    .map(f => (
                                                                        f?.label_agence || f?.label_regie || f?.label ?
                                                                            <option selected={((f.label_regie == userCopy?.label_regie || f.label_regie == user?.label_regie) ||
                                                                                (f.label_agence == userCopy?.label_agence || f.label_agence == user?.label_agence) ||
                                                                                (f.label == userCopy?.label || f.label_regie == user?.label)) ? true : false}
                                                                                value={f.functionId}>{f?.label_regie || f?.label_agence || f?.label}</option>
                                                                            : ""
                                                                    ))
                                                            }
                                                        </select>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                        : ""
                            : "AG".includes(role) ?
                                "AG, AC".includes(user?.role) || "AC, AG".includes(userCopy?.role) ?
                                    <div className="form_oneElement_container">
                                        <label htmlFor="fonction">{t('default:function', "Fonction")}</label>
                                        <input type="text" className="form-control" id="fonction" minLength="1" name="fonctionLabel" value={userCopy?.fonctionLabel || user?.fonctionLabel || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value))} disabled={("MG, MC, MR".includes(role)) ? true : false} ></input>
                                    </div>
                                    : ""
                                : "RG".includes(role) ?
                                    "RG, RC".includes(user?.role) || "RC, RG".includes(userCopy?.role) ?
                                        <div className="form_functions_departement">
                                            <div className="form_oneElement_container" style={{ width: "47%" }}>
                                                <label htmlFor="departementId">{t('default:departement', "Département")}</label>
                                                <select className="form-control" name="departementId" style={{ paddingLeft: "0" }}
                                                    onChange={(e) => {
                                                        userCopy?.userId
                                                            ? inputUserCopyChange(e.target.name, e.target.value)
                                                            : inputUserChange(e.target.name, e.target.value)

                                                        inputDepartementChange(e.target.name, e.target.value)
                                                    }}
                                                    disabled={("MC".includes(role)) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0 ? true : false}
                                                    value={(user?.departementId != "" || userCopy?.departementId != "")
                                                        ? (userCopy?.departementId || user?.departementId)
                                                        : ""}>
                                                    <option value=""></option>
                                                    {
                                                        departements?.map(d => (
                                                            d.nbRegieF > 0 ?
                                                                <option value={d.id}>{d.name}</option>
                                                                : ""
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {
                                                activeFunctions || user?.role != "" ?
                                                    <div className="form_oneElement_container" style={{ width: "47%" }}>
                                                        <label htmlFor="functionId">{t('default:function', "Fonction")}</label>
                                                        <select className="form-control" name="functionId" style={{ paddingLeft: "0" }}
                                                            onChange={(e) => (userCopy?.userId
                                                                ? inputUserCopyChange(e.target.name, e.target.value)
                                                                : inputUserChange(e.target.name, e.target.value))}
                                                            disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                                            value={(user?.functionId != "" || userCopy?.functionId != "")
                                                                ? (userCopy?.functionId || user?.functionId) : ""
                                                            }>
                                                            <option value=""></option>
                                                            {
                                                                fonctions?.sort((functionA, functionB) => functionA.label_regie?.toLowerCase().localeCompare(functionB.label_regie?.toLowerCase()))
                                                                    .map(f => (
                                                                        f?.label_regie || f?.label_agence || f?.label ?
                                                                            <option selected={((f.label_regie == userCopy?.label_regie || f.label_regie == user?.label_regie) ||
                                                                                (f.label_agence == userCopy?.label_agence || f.label_agence == user?.label_agence) ||
                                                                                (f.label == userCopy?.label || f.label_regie == user?.label)) ? true : false}
                                                                                value={f.functionId}>{f?.label_regie || f?.label_agence || f?.label}</option>
                                                                            : ""
                                                                        // f?.label_agence || f?.label_regie || f?.label ?
                                                                        // <option selected={((f.label_regie == userCopy?.label_regie || f.label_regie == user?.label_regie) || 
                                                                        //                     (f.label_agence == userCopy?.label_agence || f.label_agence == user?.label_agence) ||
                                                                        //                     (f.label == userCopy?.label || f.label_regie == user?.label)) ? true : false} 
                                                                        //     value={f.functionId}>{f?.label_regie || f?.label_agence || f?.label}</option> 
                                                                    ))
                                                            }
                                                        </select>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                        : ""
                                    : ""
                        :
                        <div className="form_oneElement_container" style={{ width: "47%" }}>
                            <label htmlFor="departementId">{t('default:departement', "Département")}</label>
                            <select className="form-control" name="departementId" style={{ paddingLeft: "0" }}
                                onChange={(e) => {
                                    userCopy?.userId
                                        ? inputUserCopyChange(e.target.name, e.target.value)
                                        : inputUserChange(e.target.name, e.target.value)

                                    inputDepartementChange(e.target.name, e.target.value)
                                }}
                                disabled={("MC".includes(role) || filtredUserRoles.filter(uRoles => uRoles.value.includes(userCopy?.role || user?.role)).length == 0) ? true : false}
                                value={(user?.departementId != "" || userCopy?.departementId != "")
                                    ? (userCopy?.departementId || user?.departementId)
                                    : ""}>
                                <option value=""></option>
                                {
                                    departements?.map(d => (
                                        d.nbRegieF > 0 ?
                                            <option value={d.id}>{d.name}</option>
                                            : ""
                                    ))
                                }
                            </select>
                        </div>
                    }

                    {(role === 'RG' && user?.role === "RC") ? (
                        <div className="form_twoElements_container">
                            <div className="form_element_container_filteragency">
                                <input
                                    style={{ margin: "23px 8px 0px 2px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="filter_agencies"
                                    value=""
                                    id="filter_agencies"
                                    checked={(user?.filter_agencies && (user?.filter_agencies == "1")) ? true : false}
                                    onChange={(e) => inputUserChange(e.target.name, e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="filter_agencies">
                                    {t('masterData:filterAgencies', "Filtrer les agences médias")}
                                </label>


                            </div>
                            {
                                (user?.filter_agencies && (user?.filter_agencies == "1")) &&
                                <div className="form_element_container">
                                    <label htmlFor="userregie2agency">{t('masterData:userAgenciesFilter', "Agences médias visibles")}
                                        <i className="fas fa-plus addEntities_icon" onClick={() => { setShowEntityAgenciesModal(true); }} ></i>
                                    </label>
                                    {showEntityAgenciesModal ? (
                                        <EntityAgenciesUserModal
                                            userUid={user.userUid}
                                            handleClose={() => setShowEntityAgenciesModal(false)}
                                            show={showEntityAgenciesModal}
                                            addAuthorizedAgenciesUser={inputUserChange}
                                            userEntities={user?.authorizedAgencies}
                                            userRole={user?.role ? user?.role : userCopy?.role}
                                        />
                                    ) : null
                                    }
                                    <div className="form_pillsElements_container" >
                                        {
                                            user?.authorizedAgencies?.map(entity => (
                                                <div className="form_pillElement_container" key={entity.uid}>
                                                    <div className="pillElement_infos">
                                                        <img className="pillElement_icon" src={iconEntity} ></img>
                                                        <div className="pillElement_label">{entity.commercial_name}</div>
                                                    </div>
                                                    {
                                                        <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteAuthorizedAgencyClickHandler(entity)} />
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    ) : ''
                    }

                    {
                        (!"MC".includes(role)) ? (
                            loading ?
                                <div className="rightSide_form_submit_container">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                                : (!userCopy?.userId && !user?.userId) ? (
                                    (role === "AE" || role === "AG" || role === "MG" || role === "MR" || role === "RG") ? (
                                        <div className="rightSide_form_submit_container">
                                            <input
                                                type="submit"
                                                onClick={(e) => addUserclickHandler(e)}
                                                value={t('default:add', "Ajouter")}
                                                className="btn btn-primary rightSide_form_submitBtn"></input>
                                        </div>
                                    ) : null
                                ) : (
                                    (user?.userId && user?.pending === "1" && "AE, AG".includes(role)) ? (
                                        <div className="rightSide_form_submit_container">
                                            <input
                                                type="submit"
                                                onClick={(e) => validateUserclickHandler(e, user?.userUid)}
                                                value={t('default:validate', "Valider")}
                                                className="btn btn-primary rightSide_form_submitBtn"></input>
                                            <input
                                                type="submit"
                                                onClick={(e) => desactivateUserclickHandler(e, user?.userUid)}
                                                value={t('default:deny', "Refuser")}
                                                className="btn btn-primary rightSide_form_submitBtn"></input>
                                        </div>
                                    ) : (
                                        <div className="rightSide_form_submit_container">
                                            {
                                                showVisualisationBtn() ? (
                                                    <button type="button" className="btn btn-outline-success btn-sm" onClick={() => getTokenVisualisation()}>
                                                        <VisibilityIcon />
                                                        <strong className="ml-1">Visualiser</strong>
                                                    </button>
                                                ) : null
                                            }
                                            <input
                                                type="submit"
                                                onClick={
                                                    (e) => {
                                                        if (userCopy?.userId) {
                                                            updateUserCopyclickHandler(e, userCopy?.userUid)
                                                        }
                                                        else if (
                                                            role === "AE"
                                                            || (role === "AG" && "AG ,AC".includes(user?.role))
                                                            || (role === "MG" && "MG ,MR, MC".includes(user?.role))
                                                            || ((role === "MG" || role === "MR") && ("AG ,AC".includes(user?.role)) && (user?.status === "Nouveau" || activeValidation === 0))
                                                            || (role === "RG" && "RG ,RC".includes(user?.role))
                                                        ) {
                                                            updateUserclickHandler(e, user?.userUid)
                                                        }
                                                        else if ((role === "MG" || role === "MR") && user?.role === "AC") {
                                                            addUserCopyclickHandler(e, user?.userUid)
                                                        } else {
                                                            e.preventDefault();
                                                        }
                                                    }
                                                }
                                                value={t('default:update', "Modifier")}
                                                className="btn btn-primary rightSide_form_submitBtn"
                                            />
                                            {
                                                showDeleteUserBtn() ?
                                                    <input
                                                        type="submit"
                                                        value={t('default:delete', "Supprimer")}
                                                        className="btn btn-danger rightSide_form_submitBtn"
                                                        onClick={(e) => deleteUserClickHandler(e, user?.userUid)}
                                                    />
                                                    : null
                                            }
                                            {
                                                showDeleteConfirmModal ? (
                                                    <ConfirmDeleteModal
                                                        show={showDeleteConfirmModal}
                                                        handleClose={() => setShowDeleteConfirmModal(false)}
                                                        deleteUser={deleteUser}
                                                        userUid={user?.userUid}
                                                    />
                                                ) : null
                                            }
                                            {
                                                showCreatorModal ? (
                                                    <DeleteUserModal
                                                        show={showCreatorModal}
                                                        handleClose={() => setShowCreatorModal(false)}
                                                        userUid={user?.userUid}
                                                        deleteUser={deleteUser}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    )
                                )
                        ) : ""
                    }
                </form>
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.auth.role,
    authUserId: state.auth.userId
})
const mapDispatchToProps = dispatch => ({
    setUserVisualisation: (userVisToken, userVisUid, userVisRole, userVisLogin) => dispatch(actions.setUserVisualisation(userVisToken, userVisUid, userVisRole, userVisLogin))
})
export default connect(mapStateToProps, mapDispatchToProps)(UserForm)