import React, { useCallback, useRef, useState } from 'react'
import './TableActions.css';
import Axios from '../../../axios-edipub';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import ArchiveIcon from '@material-ui/icons/Archive';
import ReplayIcon from '@material-ui/icons/Replay';

import { Tooltip, withStyles } from '@material-ui/core';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);


function TableActions(props) {
    const { filterState, setShowFilters, resetData, getCompressedDoc, selectedRows, formatDate, exportSelectionCsv, exportAllCsv, csvBtn, csvData, showCSVIcon, showAllCSVIcon } = props;
    const { t } = useTranslation();

    const csvHeaders = [
        { label: "Statut", key: "status" },
        { label: "Numéro document", key: "id" },
        { label: "Date de création", key: "creationDate" },
        { label: "Mandataire", key: "mandataire" },
        { label: "Département", key: 'agencyDep' },
        { label: "Responsable", key: 'emailMR' },
        { label: "Groupe annonceur", key: 'advertiserGroup' },
        { label: "Annonceur", key: "official_name" },
        { label: "N° identification Annonceur", key: "idNumber" },
        { label: "Dernière signature", key: 'lastSignatureDate' },
        { label: "Date début", key: "start_period" },
        { label: "Date fin", key: "end_period" },
        { label: "Description", key: "description" },
        { label: "Medias", key: "medias" },
        { label: "Produits", key: "products" },
        { label: "Sous Mandataires", key: "sousMandataires" },
        { label: "Lien vers le document", key: "documentLink" }
    ]




    let stepsRef = {
        "DRAFT": "Brouillon", "TO_BE_APPROVED": "Approbation",
        "TO_BE_SIGNED": "Signature", "SIGNED": "Signé",
        "ABANDONED": "Abandon", "EXPIRED": "Expirée"
    }


    const showExportZip = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }

    function handleFilters() {
        setShowFilters(!filterState)
    }
    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action" style={{ border: "none" }}>
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon">
                        <ReplayIcon onClick={() => resetData()} fontSize="small" />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon" style={{ color: filterState ? 'rgb(38, 146, 235)' : '' }}>
                        <FilterListIcon onClick={() => handleFilters()} fontSize="small" />
                    </CTooltip>
                </div>
                {/* <div className="tableActions__action" style={{display: showExportZip(selectedRows) ? 'flex' : 'none'}}>
                    <CTooltip title="Exporter Zip" className="tableActions__icon" onClick={() => exportSelectionZip(selectedRows)}>
                        <ArchiveIcon  fontSize="small"/>
                    </CTooltip>
                </div>*/}
                {showAllCSVIcon ?
                    <div className="tableActions__action" style={{ display: 'flex' }} onClick={() => exportAllCsv(selectedRows)}>
                        <CTooltip title={t('tableAction:exportAllCsv', "Exporter toutes les ADM en CSV")} className="tableActions__icon" >
                            <SystemUpdateAltIcon fontSize="small" />
                        </CTooltip>
                    </div>
                    : null
                }
                {showCSVIcon ?
                    <div className="tableActions__action" style={{ display: 'flex' }} onClick={() => exportSelectionCsv(selectedRows)}>
                        <CTooltip title={t('tableAction:exportCsv', "Exporter la sélection en CSV")} className="tableActions__icon" >
                            <ImportExportIcon fontSize="small" />
                        </CTooltip>
                    </div>
                    : null
                }
                {csvData ? <CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"donneesCsv.csv"}
                    headers={csvHeaders}
                    separator={";"}>
                </CSVLink> : null}
            </div>
        </div>
    )
}

export default TableActions
