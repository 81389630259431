import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { checkValidity, getNotyfObject } from '../../../../shared/utility'
import { connect } from 'react-redux';

function AddListItemModal({ fieldColumnNames, uniqueFields, mandatoryFields, textFields, fieldsMaxLen, addNewTitle, addNewAlreadyExistLabel, items, handleClose, show, newItem, itemAddHandler, newItemUpdateHandler, role, selectFields, itemsForSelectList }) {
    const { t } = useTranslation();

    let notyf = getNotyfObject()


    const isItemFilledWithMandatoryFields = (item) => {
        let nonNullFields = 0;
        let nonNullMandatoryFields = 0;

        Object.keys(fieldColumnNames).forEach(field => {
            if (newItem[field] && newItem[field].trim() != '') {
                nonNullFields++
                if (mandatoryFields.includes(field))
                    nonNullMandatoryFields++
            }
        })

        let toAdd = (nonNullFields > 0) && (nonNullMandatoryFields == mandatoryFields.length)
        return toAdd

    }
    const isItemExistingAlready = (item) => {
        // let itemExists = false  
        let toAdd = true;
        let nonNullFields = 0;
        let nonNullMandatoryFields = 0;
        Object.keys(fieldColumnNames).forEach(field => {
            if (newItem[field] && newItem[field].trim() != '') {
                nonNullFields++
                if (mandatoryFields.includes(field))
                    nonNullMandatoryFields++
            }
        })
        toAdd = (nonNullFields > 0) && (nonNullMandatoryFields == mandatoryFields.length)


        if (toAdd) {
            items.forEach(parsedItem => {
                let differentCounter = 0
                Object.keys(fieldColumnNames).forEach(field => {
                    if (
                        (
                            (parsedItem[field] !== null && newItem[field] === null)
                            || (parsedItem[field] === null && newItem[field] !== null)
                        )
                        || (
                            // parsedItem[field] !== null && newItem[field] !== null
                            parsedItem[field] && newItem[field] && parsedItem[field].toUpperCase() !== newItem[field].toUpperCase()
                        )
                    ) {
                        differentCounter++
                    }
                })
                if (differentCounter < items.counter)
                    toAdd = false
            })
        }
        return !toAdd
    }

    const isItemExistingAlreadyOnUniqueField = (item) => {
        let itemExistsOnUniqueField = false
        let doubleField = ''
        items.forEach((parsedItem) => {
            Object.keys(fieldColumnNames).forEach(field => {
                // if (uniqueFields?.includes(field) && parsedItem[field]!==null && item[field].toUpperCase() === parsedItem[field].toUpperCase()) {
                if (uniqueFields?.includes(field) && parsedItem[field] && item[field].toUpperCase() === parsedItem[field].toUpperCase()) {
                    doubleField = field
                    itemExistsOnUniqueField = true;
                }
            })
        });
        if (itemExistsOnUniqueField)
            notyf.error(t("default:dontDoDuplicatesOnUniqueFieldMessage", { "field": fieldColumnNames[doubleField] }))

        return itemExistsOnUniqueField
    }

    const addClickHandler = () => {
        let empty = 0
        Object.keys(fieldColumnNames).forEach(field => {
            if (newItem[field] === null || (newItem[field]).length == 0) {
                empty++
            }

        })
        let isAllEmpty = (empty == Object.keys(fieldColumnNames).length)
        if ((role == 'AE') && (empty > 0)) {
            notyf.open({
                duration: 4000,
                dismissible: true,
                type: 'warning',
                background: 'orange',
                message: t("default:fillAllInputsMessage")
            });
            return
        } else if ((role != 'AE') && isAllEmpty) {
            notyf.open({
                duration: 4000,
                dismissible: true,
                type: 'warning',
                background: 'orange',
                message: t("default:fillMinOneInputMessage")
            });
            return
        }

        if (!isItemFilledWithMandatoryFields(newItem)) {
            let mandatoryFieldsLabels = []
            mandatoryFields.forEach(field => {
                mandatoryFieldsLabels.push(fieldColumnNames[field])
            })
            notyf.error(t("default:fillAllMandatoryInputsMessage", { "mandatoryFields": mandatoryFieldsLabels }))
        }
        else if (isItemExistingAlready(newItem)) {
            notyf.error(addNewAlreadyExistLabel)
        } else if (!isItemExistingAlreadyOnUniqueField(newItem)) {
            itemAddHandler()
            handleClose();
        }
    }

    const cancelClickHandler = () => {
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{addNewTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    <div className="form_twoElements_container">
                        {
                            Object.keys(fieldColumnNames).map(field => {
                                return (
                                    textFields.includes(field) ? (
                                        <div className="form_element_container">
                                            <label htmlFor={field}>{fieldColumnNames[field]}</label>
                                            <textarea
                                                id={field}
                                                name={field}
                                                className="form-control"
                                                value={newItem[field]}
                                                style={{ height: "100px" }}
                                                maxLength={Object.keys(fieldsMaxLen).includes(field) ? fieldsMaxLen[field] : "128"}
                                                onChange={(e) => newItemUpdateHandler(e)}
                                            />
                                        </div>
                                    ) : (
                                        selectFields?.includes(field) ?
                                            <div className="form_element_container">
                                                <label htmlFor={field}>{fieldColumnNames[field]}</label>
                                                <select
                                                    style={{ width: (Object.keys(fieldsMaxLen).includes(field) ? fieldsMaxLen[field] : "250px") }}
                                                    className="form-control"
                                                    name={field}
                                                    onChange={(e) => newItemUpdateHandler(e)}
                                                    value={newItem[field]}
                                                >
                                                    <option value={""} key={0}>-- Select département --</option>
                                                    {
                                                        itemsForSelectList[0][field]?.map(item => {
                                                            return <option value={item?.id} key={item?.id}>{item?.name}</option>
                                                        })
                                                    }
                                                </select>

                                            </div>
                                            :
                                            <div className="form_element_container">
                                                <label htmlFor={field}>{fieldColumnNames[field]}</label>
                                                <input
                                                    id={field}
                                                    type="text"
                                                    name={field}
                                                    className="form-control"
                                                    value={newItem[field]}
                                                    maxLength={Object.keys(fieldsMaxLen).includes(field) ? fieldsMaxLen[field] : "128"}
                                                    onChange={(e) => newItemUpdateHandler(e)}
                                                />
                                            </div>

                                    )
                                )
                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelClickHandler}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={() => addClickHandler()}>
                        {t('default:add', "Ajouter")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(AddListItemModal)
