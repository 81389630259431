import React, { useCallback, useState } from 'react'
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function DescriptionModal({handleClose, show, mandatUid,admDescription, updateAdmDescription}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject();
    const [description, setDescription] = useState(admDescription)


    const addClickHandler = () => {
        updateAdmDescription(mandatUid, description)
        handleClose();
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:descModal_title', "Modification de la description du mandat")} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div className="ADMCreationModal__description__container">
                        <h5>{t('default:description', "Description")}</h5>
                        <textarea 
                            className="form-control" 
                            name="description"
                            rows="3"
                            maxLength={500}
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:update', "Modifier")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => ({
    mandatUid: state.mandat.admData?.uid,
    admDescription : state?.mandat?.admData?.description
})
const mapDispatchToProps = dispatch => ({
    updateAdmDescription: (mandatUid ,description) => dispatch(actions.updateAdmDescription(mandatUid ,description)),
})
export default connect(mapStateToProps, mapDispatchToProps) (DescriptionModal)