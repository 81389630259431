import React, { useEffect, useRef, useState } from 'react'
import './UserDropdown.css';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';

function UserDropdown({show, setShow, zIndex, isAuthenticated, logout, isSso, ssoDisconnectUrl, setQuitAppAndDisconnect}) {
    const divRef = useRef();
    const { t } = useTranslation();
    console.log("UserDropdown ssoDisconnectUrl = ", ssoDisconnectUrl)

    const clickOutSideHandler = e => {
        if (divRef.current.contains(e.target)) {
            return
        }
        setShow(false)
    }

    const disconnectClickHandler = () => {
        if (isSso) {
            console.log("UserDropdown setQuitAppAndDisconnect(true)")  
            setQuitAppAndDisconnect(true)
        } else {
            console.log("UserDropdown  logout()")
            logout()
        } 
    }



    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    return (
        <div ref={divRef} className="header__profilDropdown" style={{ zIndex: zIndex, display: show ? "flex" : "none" }}>
            <NavLink className="header__logoutBtn" to="/profile">{t("default:monCompte")}</NavLink>
            <div className="header__profilItem" onClick={() => disconnectClickHandler()}> 
                <div>
                    <NavLink className="header__logoutBtn" to="/">
                        {isAuthenticated ? t("default:seDeconnecter") : t("default:seConnecter")}
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        login: state.auth.login,
		isSso: state.auth.isSso,
        ssoDisconnectUrl: state.auth.ssoDisconnectUrl    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown)
