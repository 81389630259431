import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ConfirmDeleteLastEntityModal({handleClose, show, deleteEntity, entityToDel}) {
    const {t} = useTranslation();

    const deleteClickHandler = () => {         
        deleteEntity(entityToDel)
        handleClose();
    }

    const cancelClickHandler = () => { 
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t('masterData:confirmDeletelastMGEntity', "ATTENTION, en supprimant cette agence, ce groupe disparaîtra de votre liste.\nVoulez-vous supprimer quand même ?")} 
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={cancelClickHandler}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => deleteClickHandler()}>                     
                    {t('default:confirm', "Confirmer")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmDeleteLastEntityModal
