import React, { useState, useEffect, useCallback } from 'react'
import './HomeBody.css'
import StepProgressBar from './StepProgressBar/StepProgressBar'
import AdmInfoCard from './AdmInfoCard/AdmInfoCard'
import Axios from '../../../axios-edipub';
import { formatDate } from './../../../shared/utility';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import HomeSearchView from '../../../containers/HomeSearchView/HomeSearchView';
import { useTranslation } from 'react-i18next';
import { getNotyfObject } from '../../../shared/utility'
import * as actions from '../../../store/actions/index';

function HomeBody({stepsInitialStatus, refresh_adms_list, adms_list, role, searchText, setSearchText, stepFilter, loading, setAdm, activeHeaderBarSearchInput, initAdmSteps}) {
    const {t} = useTranslation();
    const [advertisers, setAdvertisers] = useState()
    const [searchQuery, setSearchQuery] = useState(null);
    const [groups, setGroups] = useState(null);
    const [searchQueryGroup, setSearchQueryGroup] = useState(null);
    let notyf = getNotyfObject();


    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        })
        .then(res => {
            setAdvertisers(res.data.entities)
        })
        .catch(err => notyf.error(t("mandat:generatedErrorMessage")))  
        
        return () => {

           // setAdm([])
        }
    }, [searchQuery])

    useEffect(() => {
        let filter = {
            category: "Annonceur",
            searchQuery: searchQueryGroup
        }
        Axios.get('/group', {
            params: filter
        })
        .then(res => {
            setGroups(res.data.groupes)
        })
        .catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [searchQueryGroup])    
    
    return (
        (activeHeaderBarSearchInput || searchText || !stepsInitialStatus) ? 
            <HomeSearchView documents={adms_list}
                            stepsInitialStatus={stepsInitialStatus}
                            loading={loading}
                            refreshAdmList={() => {initAdmSteps();setSearchText("");}}
                            refreshAdmListAndResetFilters={() => {initAdmSteps();setSearchText("");}} />
        :
        <div className="homeBody">
            <StepProgressBar 
                brouillons={(!"AG, AC, RG, RC".includes(role) 
                                                ? adms_list?.filter(invoice => invoice.status === 'DRAFT').length
                                                : 0)} 
                AAprouver={adms_list?.filter(invoice => invoice.status === 'TO_BE_APPROVED').length} 
                aprouve={adms_list?.filter(invoice => invoice.status === 'TO_BE_SIGNED').length} 
                signe={adms_list?.filter(invoice => invoice.status === 'SIGNED'  ).length} 
            />
            <div className="homeBody__admListContainer">
                {
                    !"AG, AC".includes(role) ? (
                        <Fragment >
                        <div className="homeBody__admList" style={{width: (!"AG, AC".includes(role))? "" : "32%"}}>
                            { 
                            
                            adms_list?.filter( adm_list => adm_list.status === 'DRAFT' && 
                            !"AG, AC, RG, RC".includes(role)).map( (adm,index) => (

                                <AdmInfoCard 
                                    key={adm.id}
                                    notyf = {notyf}
                                    advertisers={advertisers}
                                    setSearchQuery={setSearchQuery}
                                    groups={groups}
                                    setSearchQueryGroup={setSearchQueryGroup}
                                    stepsInitialStatus={stepsInitialStatus}
                                    i={index}
                                    refreshContent={refresh_adms_list}
                                    refreshContentForDupplicate={refresh_adms_list}
                                    adm={adm}
                                    // uid = {adm.uid}
                                    // admStatus={adm.status}
                                    // documentId={adm.id}
                                    // company={adm.official_name}
                                    // mandataire={adm.mandataire}
                                    // period={ formatDate(+adm?.start_period) +" - "+formatDate(+adm?.end_period) }
                                    // products={adm.products}
                                    // medias={adm.medias}
                                    // events={adm.nbEvents}
                                    // advertiserUid={adm.advertiserUid}
                                    // isCreator={+adm.isCreator}
                                    // hasNotif={adm.hasNotif}
                                    />
                                    ))
                        
                                }
                            </div>
                        </Fragment>
                        ):("")
                }
                <div className="homeBody__admList" style={{width: (!"AG, AC".includes(role))? "" : "32%"}}>
                    {
                        adms_list?.filter( adm_list => adm_list.status === 'TO_BE_APPROVED').map( (adm,index) => (
                            <AdmInfoCard 
                                key={adm.id}
                                notyf = {notyf}
                                advertisers={advertisers}
                                setSearchQuery={setSearchQuery}
                                groups={groups}
                                setSearchQueryGroup={setSearchQueryGroup}
                                stepsInitialStatus={stepsInitialStatus}
                                i={index}
                                refreshContent={refresh_adms_list}
                                refreshContentForDupplicate={refresh_adms_list}
                                adm={adm}
                                // uid = {adm.uid}
                                // admStatus={adm.status}
                                // documentId={adm.id}
                                // company={adm.official_name}
                                // mandataire={adm.mandataire}
                                // period={ formatDate(+adm?.start_period) +" - "+formatDate(+adm?.end_period)}
                                // products={adm.products}
                                // medias={adm.medias}
                                // events={adm.nbEvents}
                                // hasAction={adm.hasAction}
                                // advertiserUid={adm.advertiserUid}
                                // isCreator={+adm.isCreator}
                                // hasNotif={adm.hasNotif} 
                                />
                                ))
                    }
                </div>
                <div className="homeBody__admList" style={{width: (!"AG, AC".includes(role))? "" : "32%"}}>
                    {
                        adms_list?.filter( adm_list => ( 
                            (adm_list.status === 'TO_BE_SIGNED')) 
                                ).map( (adm,index) => (
                                <AdmInfoCard
                                    key={adm.id}
                                    notyf = {notyf} 
                                    advertisers={advertisers}
                                    setSearchQuery={setSearchQuery}
                                    groups={groups}
                                    setSearchQueryGroup={setSearchQueryGroup}
                                    stepsInitialStatus={stepsInitialStatus}
                                    i={index}
                                    refreshContent={refresh_adms_list}
                                    refreshContentForDupplicate={refresh_adms_list}
                                    adm={adm}

                                    // uid = {adm.uid}
                                    // admStatus={adm.status}
                                    // documentId={adm.id}
                                    // company={adm.official_name}
                                    // mandataire={adm.mandataire}
                                    // period={formatDate(+adm?.start_period) +" - "+formatDate(+adm?.end_period)}
                                    // products={adm.products}
                                    // medias={adm.medias}
                                    // events={adm.nbEvents}
                                    // hasAction={adm.hasAction}
                                    // advertiserUid={adm.advertiserUid}
                                    // isCreator={+adm.isCreator}
                                    // hasNotif={adm.hasNotif} 
                                />
                            )
                        )
                        
                    } 
                </div>
                <div className="homeBody__admList" style={{width: (!"AG, AC".includes(role))? "" : "32%"}}>
                    {
                        adms_list?.filter( adm_list => adm_list.status === 'SIGNED').map( (adm,index) => (
                            <AdmInfoCard 
                            key={adm.id}
                            advertisers={advertisers}
                            setSearchQuery={setSearchQuery}
                            groups={groups}
                            setSearchQueryGroup={setSearchQueryGroup}
                            stepsInitialStatus={stepsInitialStatus}
                            i={index}
                            notyf = {notyf}
                            refreshContent={refresh_adms_list}
                            refreshContentForDupplicate={refresh_adms_list}
                            adm={adm}

                            // uid = {adm.uid}
                            // admStatus={adm.status}
                            // documentId={adm.id}
                            // company={adm.official_name}
                            // mandataire={adm.mandataire}
                            // period={ formatDate(+adm?.start_period) +" - "+formatDate(+adm?.end_period) }
                            // products={adm.products}
                            // medias={adm.medias}
                            // events={adm.nbEvents}
                            // published = {adm.published}
                            // advertiserUid={adm.advertiserUid}
                            // isCreator={+adm.isCreator}
                            // hasNotif={adm.hasNotif} 
                            />
                            ))
                    }
                </div>
            </div>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    role: state.auth.role,
    isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    adms_list: state.mandat.adm
})
const mapDispatchToProps = dispatch => ({
    setAdm: (a) => dispatch(actions.setAdm(a)) 
})
export default connect(mapStateToProps, mapDispatchToProps)(HomeBody)
