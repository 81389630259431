import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Axios from '../../../../../../axios-edipub';

import { useTranslation } from 'react-i18next';

function RelanceSignatureModal({ handleClose, show, relanceSignatureForUser, signUser, mandatUid, userContact, contributor }) {

    const [canAdd, setCanAdd] = useState(false)

    const { t } = useTranslation();





    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{t('default:alert', "alert")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    {signUser?.first_name ?
                        <div className="ADMCreationModal__description__container" style={{ marginBottom: "15px" }}>
                            <p style={{ fontSize: "17px" }}>
                                {contributor ?
                                    t("mandat:remindContributor", `Voulez-vous relancer ${signUser?.first_name} ? Un email lui sera envoyé lui rappelant d'approuver l’attestation de mandat.`, { firstName: signUser?.first_name + " " + signUser?.last_name })
                                    :
                                    t("mandat:remindSigner", `Voulez-vous relancer ${signUser?.first_name} ? Un email lui sera envoyé lui rappelant de signer l’attestation de mandat.`, { firstName: signUser?.first_name + " " + signUser?.last_name })

                                }

                            </p>
                        </div>
                        :
                        <div className="ADMCreationModal__description__container" style={{ marginBottom: "15px" }}>
                            <p style={{ fontSize: "17px" }}>

                                {t("mandat:remindSigner", `Voulez-vous relancer ${userContact?.first_name} ? Un email lui sera envoyé lui rappelant de signer l’attestation de mandat.`, { firstName: userContact?.first_name + " " + userContact?.last_name })}

                            </p>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={relanceSignatureForUser} >
                        {t('default:confirm', "confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RelanceSignatureModal
