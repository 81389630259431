import React, { useCallback, useEffect, useState } from 'react'
import './CreationEvent.css'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function CreationEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <DescriptionIcon style={{backgroundColor: "rgb(0, 112, 210)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    <div className="timelineCard__header_label">{t('timeline:creationModal_title', "Création du mandat")} </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)} </span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)} </span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(0, 112, 210)",display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        {
                            isCreator ? 
                                t('timeline:createdMandat_creator', "Vous avez créé ce mandat")  
                            : 
                                eventData?.data?.user?.first_name + ' ' + eventData?.data?.user?.last_name + ' ' + t('timeline:createdMandat_other', " a créé ce mandat")
                        } </div>
                    <div className="timelineCard__content__body">
                        <div className="creationEvent__content__body">
                            <div className="creationEvent__item">
                                <div className="creationEvent_item_label">Annonceur</div>
                                <div className="creationEvent_item_value">{eventData?.data?.official_name}</div>
                            </div>
                            <div className="creationEvent__item">
                                <div className="creationEvent_item_label">Description</div>
                                <div className="creationEvent_item_description">{eventData?.data?.description}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default CreationEvent
