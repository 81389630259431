import React, { useCallback, useEffect, useState, useRef } from 'react';
import Axios from '../../axios-edipub';
import { connect } from 'react-redux';
import { getNotyfObject, updateObject } from '../../shared/utility';
import Spinner from '../../components/UI/Spinner/Spinner';
import './KPI.css';


import DescriptionIcon from '@material-ui/icons/Description';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import TableBody from '../../components/KPI/TableBody/TableBody';
import TableActions from '../../components/KPI/TableAction/TableActions';
import TableFilters from '../../components/KPI/TableFilters/TableFilters';
import TableHead from '../../components/KPI/TableHead/TableHead';
import { formatDate } from '../../shared/utility';
import TablePagination from '../../components/KPI/TablePagination/TablePagination';
import { useTranslation } from 'react-i18next';

function KPI({ role }) {

    // useEffect(() => {
    //   getQuestionCategories();
    //}, [])

    const handleSortOrder = () => {
        //const newSortOrder = !sortOrder;
        // setSortOrder(newSortOrder);
        // getQuestionCategories(searchQuery, newSortOrder);
    }

    //const {t} = useTranslation();   
    const { t, i18n } = useTranslation();
    const notyf = getNotyfObject();

    //  i18n.language



    const lang = "FR"
    const baseColumns = [
        { title: t('newHome:admNumber'), field: 'id', width: "170px" },
        { title: t('default:creationDate', "Date de création"), field: 'creationDate', width: "150px" },
        { title: t('newHome:mandataire', "Mandataire"), field: 'mandataire', width: "200px" },
        { title: t('newHome:annonceur', "Annonceur"), field: 'annonceur', width: "200px" },
    ]

    const initialFiltersState = {
        status: "", creationDate: "", official_name: "", mandataire: "", start_period: "", end_period: "", description: "",
        medias: "", products: "", orderBy: "creationDate", sortOrder: "DESC", page: 1, selectedRows: [], selectedAllRows: false,
        selectedAllPerPage: []
    }

    const reverseTable = {
        'Status': false, 'creationDate': false, 'official_name': false,
        'mandataire': false, 'start_period': false, 'end_period': false,
        'description': false, 'medias': false, 'produits': false
    }
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [columns, setColumns] = useState([]);
    const [adms, setAdms] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [onlyUnpublished, setOnlyUnpublished] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState(reverseTable);
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();


    useEffect(() => {
        refreshContent(initialFiltersState)
    }, [])

    useEffect(() => {
        refreshContent(initialFiltersState)
    }, [onlyUnpublished])


    const refreshContent = (filters) => {
        setLoading(true);
        Axios.get('/document//kpiDocs', { params: { ...filters, onlyUnpublished } }
        ).then(res => {
            let data = res.data?.mandats
            let countItems = res.data?.nbItems
            if (data) {

                let columnsTemp = [...baseColumns];
                res.data?.alias2Names.forEach(media => {
                    columnsTemp.push({ title: lang === "FR" ? media.label : media.label_en, field: media.fieldName, width: "150px" });
                })
                setColumns(columnsTemp)
                setAdms(data)
            }
            setCount(countItems)
        }).finally(() => {
            setLoading(false);
        })
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
            selectedAllPerPage: [],
            selectedRows: []
        }
        setFilters(newFilters)
        refreshContent(newFilters)
    }


    const handleReset = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        refreshContent(initialFiltersState)
    }

    const exportAllCsv = () => {
        Axios.get('/document//kpiDocs', { params: { ...filters, onlyUnpublished, getAll: 1 } }).then((response) => {
            let csvDataTemp = response.data.mandats?.map(row => {
                const newRow = {
                    ...row,
                    creationDate: formatDate(+row.creationDate)
                }
                Object.keys(newRow).forEach(key => {
                    if (key.startsWith("media") && !newRow[key])
                        newRow[key] = 0;
                })
                return newRow;
            })
            setCsvData(csvDataTemp);
            csvBtn.current.link.click();
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    let tBody = null;

    if (loading)
        tBody = <tr><td colSpan="12"><Spinner /></td></tr>
    else if (adms?.length > 0) {
        tBody = (
            <TableBody
                columns={columns}
                data={adms}
                formatDate={formatDate}
            />
        )
    }

    return (
        <div className="home">
            <div className="home__container">
                <div className="headerBar__containerkpi">
                    <div className="headerBar__left">
                        <div className="header_actionTypes_containerkpi">
                            <InsertChartIcon className="header_icon_kpi" style={{ width: "50px", height: "95%" }} />
                        </div>
                        <div className="left__elementskpi">
                            <h1 className="leftElement_title">{t('kpi:kpi').toLocaleUpperCase()}   </h1>
                        </div>
                    </div>
                    <div className="headerBar__right">
                    </div>
                </div>
                <div className="invoiceHeader">
                    <TableActions
                        filterState={showFilters}
                        setShowFilters={setShowFilters}
                        resetData={handleReset}
                        onlyUnpublished={onlyUnpublished}
                        setOnlyUnpublished={setOnlyUnpublished}
                        exportAllCsv={exportAllCsv}
                        csvBtn={csvBtn}
                        csvData={csvData}
                        columns={columns}
                    />
                    <TablePagination
                        filters={filters}
                        filtersChange={setFilters}
                        applyFilters={refreshContent}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalElement={count}
                        perPage={25} />
                </div>

                <div className="home__homeBodyContainer scroll-container" style={{ marginTop: "7px" }}>
                    <div className="home__homeBodyMain content">
                        <table className="table tableFixHead">
                            <TableHead
                                columns={columns}
                                refreshContent={refreshContent}
                                reverse={reverse}
                                sortQuery={sortQuery}
                                setSortOrder={setSortOrder}
                                sortOrder={sortOrder}
                                setReverse={setReverse}
                                setSortQuery={setSortQuery}
                                filters={filters}
                                setFilters={setFilters}

                            />
                            <tbody>
                                {showFilters &&
                                    <TableFilters
                                        show={showFilters}
                                        setShowFilters={setShowFilters}
                                        formatDate={formatDate}
                                        filters={filters}
                                        inputChangeHandler={inputChangeHandler}
                                        filtersChange={setFilters}
                                        applyFilters={refreshContent}
                                        role={role}
                                    />
                                }
                                {tBody}
                            </tbody>
                        </table>
                    </div>
                </div>




            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(KPI)