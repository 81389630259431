import React, { useEffect, useRef, useState } from 'react'
import '../DropDown.css'
import './PersonDropDown.css';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

function PersonDropDown({ entityUid, personsList, userAddHandler, agencyType, subMandIndex, zIndex }) {
    const [personL, setPersonL] = useState(personsList);
    const [showDropdown, setShowDropdown] = useState(false)
    const divRef = useRef();
    const { t } = useTranslation();

    const clickOutSideHandler = e => {
        if (divRef.current.contains(e.target)) {
            // inside the div
            return
        }
        // outside click
        setShowDropdown(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    const personFilterHandler = (querySearch) => {
        let personFilrerd = personsList;
        if (querySearch == null) {
            setPersonL(personFilrerd)
        } else {
            personFilrerd = personsList.filter(person => {
                if (person?.first_name.toLowerCase().includes(querySearch.toLowerCase())
                    ||
                    person?.last_name.toLowerCase().includes(querySearch.toLowerCase())
                    ||
                    person?.functionLabel?.toLowerCase().includes(querySearch.toLowerCase()))
                    return true
                else return false
            })
            setPersonL(personFilrerd)
        }
    }






    return (
        <>
            <div ref={divRef} className="UIdropDown__container" style={{ zIndex: zIndex }}>
                <div className="UIdropDown__icon" onClick={() => setShowDropdown(!showDropdown)}>
                    <PersonIcon fontSize="small" />
                </div>

                <div className="UIdropDown__content" style={{ display: showDropdown ? "flex" : "none" }}>
                    <div className="dropDownSearchInput input-group input-group-sm mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("default:search", "Rechercher")}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            style={{ height: "100%" }}
                            onChange={(e) => personFilterHandler(e.target.value)}
                        />
                        <div className="input-group-append" style={{ height: "100%" }}>
                            <span className="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                        </div>
                    </div>
                    <div className="dropDownSearch__container">
                        <div className="dropDownSearch__title">
                            {t('default:users', "Utilisateurs")}
                        </div>
                        <div className="dropDownSearch__content">
                            {
                                personL?.map(person => (
                                    <div
                                        className="dropDownSearch__item"
                                        key={person.userLinkUid}//on met person.userLinkUid au lieu de person.id car la requete retourne des doublons (on se souvient plus pourquoi et soizic a pas remonté erreur donc on laisse les doublon et met key=person.userLinkUid)
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            userAddHandler(agencyType, person, entityUid, subMandIndex);
                                            setShowDropdown(false)
                                        }
                                        }>
                                        <div className="dropDownSearch__itemIcon">
                                            <PersonIcon fontSize="small" />
                                        </div>
                                        <div className="dropDownSearch__value">
                                            <p>{person?.first_name + ' ' + person?.last_name} </p>
                                            <p>{person?.functionLabel} </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PersonDropDown
