import React, { useEffect, useState } from 'react'
import './RecipientsChangedEvent.css'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import SignatureIcon from '../../../../../../../assets/images/signatureIcon.png'
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function RecipientsChangedEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <div className="recipientsEvent_icon_container">
                        <i className="fas fa-eye" ></i>
                    </div>
                    {/* <img src={SignatureIcon} style={{width: "24px", height: "24px", color: "#FFFFFF", borderRadius: "5px"}}  /> */}
                    <div className="timelineCard__header_label">
                        {t('timeline:recipientsChanged', "Destinataires modifiés")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid #4DCA76",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                    {
                        isCreator ? 
                            t('timeline:recipientsChanged_creator', "Vous avez modifié la liste des destinataires")  
                        : 
                            eventData?.data?.publisher?.first_name + ' ' + eventData?.data?.publisher?.last_name + t('timeline:recipientsChangedMessage', " vous a invité à participer à l'ADM :")
                    } </div>
                    <div className="timelineCard__content__body">
                        <strong>{t('timeline:recipientsList', "Liste des destinataires")} </strong>
                        {
                            eventData?.data?.recipientsUsers  && eventData?.data?.recipientsUsers.length > 0 ?
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('default:name', "Nom")}</th>
                                            <th scope="col">{t('default:societies', "Sociétés")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            eventData?.data?.recipientsUsers  && eventData?.data?.recipientsUsers.length > 0 ?
                                            eventData?.data?.recipientsUsers?.map(user => (
                                                <tr>
                                                        <td>{user?.first_name + ' ' + user?.last_name} </td>
                                                        <td>{user.entities}</td>
                                                    </tr>
                                                ))
                                                : null
                                        }
                                    </tbody>
                                </table>
                            : <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.5rem"}}>
                                <p>{t('timeline:noSelectedRecipient',"Aucun destinataire n'a été selectionné")}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default RecipientsChangedEvent