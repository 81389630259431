import React from 'react'
import DropDown from '../../../components/UI/DropDown/DropDown';
import './AEListGuide.css';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';

function AEListGuide() {
    const {t} = useTranslation();
    return (
        <div>
            <DropDown 
                icon={<HelpIcon style={{width: "15px", marginLeft: "4px"}} className='aeListGuide_helpIcon' />}
                zIndex={100}
            >
                <div className="aeListGuide_symbolesList_container">
                    <div className="aeListGuide_symbole_container">
                        <div 
                            className="aeListGuide_symbole_icon"
                            style={{
                                backgroundColor: "orange"
                            }}
                        >?
                        </div>
                        <div className="aeListGuide_symbole_definition">Modification</div>
                    </div>
                    <div className="aeListGuide_symbole_container">
                        <div 
                            className="aeListGuide_symbole_icon"
                            style={{
                                backgroundColor: "lightcoral",
                                margin: "0"
                            }}
                        >?
                        </div>
                        <div className="aeListGuide_symbole_definition">{t("default:adding")}</div>
                    </div>
                </div>
            </DropDown>
        </div>
    )
}

export default AEListGuide
