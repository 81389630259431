import React, { useEffect, useState } from 'react'
import './EditEntityCreatorModal.css'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-edipub';
import { Tooltip, withStyles } from '@material-ui/core';
import { getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner';

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function EditEntityCreatorModal({handleClose, show, userUid, entityToDelete, deleteEntity, setUser, entityPossibleCreators}) {
    const {t} = useTranslation();
    const [agencies, setAgencies] = useState(null)
    const [agencyCreator, setAgencyCreator] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);

    let notyf = getNotyfObject();

    const updateClickHandler = () => {
        // setUser(user => ({
        //     ...user,
        //     creators : {
        //         ...user?.creators,
        //         [entityToDelete.uid]: agencyCreator
        //     }
        // }))
        const creator = {[entityToDelete.uid]: agencyCreator} 
        deleteEntity(entityToDelete, creator);
        handleClose()
    }
    

    const agencyCreatorChangeHandler = (creatorUid) => {
        setAgencyCreator(creatorUid)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:creatorModal_title', "Modification du responsable du mandat")} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                <div className="alert alert-warning" role="alert">
                    <p>
                        {t('masterData:editCreatorWarning1', "ATTENTION : cet utilisateur est responsable de mandats qui ne sont pas encore signés.")}<br/>
                        {t('masterData:editEntityCreatorWarning2', "Vous devez définir un nouveau responsable.")}
                    </p>
                </div>
                    <div className="editCreatorModal__body__container">
                        <div className="editCreatorModal__header__container">
                            <h5 className="editCreatorModal__title__container">{t('default:mandate', "Mandat")}</h5>
                            {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                        </div>
                        <div className="editCreatorModal_possibleCreators_container">
                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        <p>
                                            {error}
                                        </p>
                                    </div>)
                            }
                        
                            <div className="editCreatorModal_possibleCreators_item"> 
                                <div className="possibleCreators_label_container">
                                    <div className="possibleCreators_label_name">
                                        {entityToDelete?.commercial_name}
                                    </div>
                                    <CTooltip title={entityToDelete?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon">
                                        <div className="possibleCreators_label_suspendedDocs">
                                            {entityToDelete?.suspendedDocs}
                                        </div>
                                    </CTooltip>
                                </div>
                                <div className="possibleCreators_list_container">
                                    <select 
                                        className="form-control " 
                                        value={agencyCreator} 
                                        onChange={(e) => agencyCreatorChangeHandler(e.target.value)}
                                    >
                                        <option value="">
                                            {t('mandat:selectCreator', "Sélectionnez un nouveau responsable")}
                                        </option>
                                        {
                                            entityPossibleCreators?.map(user => 
                                                <option value={user.uid}>
                                                    {user.name + " (" + user.email + ")"}
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {
                    loading ?
                        <Spinner animation="border" variant="secondary" />
                    : 
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                {t('default:cancel', "Annuler")}
                            </Button>
                            <Button 
                                variant="primary" 
                                onClick={() => updateClickHandler()}
                                disabled={!agencyCreator}
                            >
                                {t('masterData:update', "Modifier")}
                            </Button>
                        </>
                }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditEntityCreatorModal