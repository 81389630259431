import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Icone.png'
import './Signin.css';

const Signin = props => {
    return (
        <div className="signin__container">
            <div className="signin__FromWrap">
                <Link to="/" className="signin__Icon">
                    <img className="signin_logo_img" src={logo} alt="logo" />
                </Link>
                <div className="signin__fromContent">
                    <form className="signin__form" action="#">
                        <h1 className="signin__formH1">
                            Identification
                        </h1>
                        <span className="signin__error"> 
                            {props.signinError ? "Login ou mot de passe incorrect" : null} 
                        </span>
                        <label 
                            className="signin__formLabel" 
                            htmlFor='username'>
                            Login :
                        </label>
                        <input 
                            className="signin__formInput" 
                            type="email" 
                            name="email" 
                            onChange={props.changed} 
                            required 
                            autoFocus />
                        <label 
                            className="signin__formLabel" 
                            htmlFor='password'
                        >
                            Mot de passe :
                        </label>
                        <input 
                            className="signin__formInput" 
                            type="password" 
                            name="password" 
                            onChange={props.changed} 
                            required />
                        <button 
                            className="signin__formButton" 
                            type="submit" 
                            onClick={props.submited}
                            >
                            Connexion
                        </button>
                        <Link to="/forgotPassword" className="forgotPWD__link">Créer / réinitialiser mot de passe</Link>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

export default Signin
