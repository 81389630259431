import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = "6GrGCDwK4oiADiyp6s1lwQ";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;


i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || "fr",
    fallbackLng: "fr",

    ns: ["default", "home", "mandat", "missions", "timeline", "masterData", "client", "dashboard", "newHome", "notifications", "notification", "emails", "tableAction", "tableHead", "profile", "faq", "kpi","template","manualEntity"],
    defaultNS: "default",

    supportedLngs: ["fr","en"],
    
    detection: {
      order: [ 'localStorage', 'path', 'cookie','navigator', 'htmlTag']
    },
    backend: {
      //loadPath: loadPath
      loadPath: '/locales/{{ns}}/{{lng}}.json'
    }
  })