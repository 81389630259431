import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddUser2EntityAgencyModal({handleClose, show, addUser2EntityAgency/*, cancelUpdate*/}) {
    const {t} = useTranslation();

    const addClickHandler = () => {
        addUser2EntityAgency()
        handleClose();
    }

    const cancelClickHandler = () => {
       // cancelUpdate();
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t('masterData:existedUserModal', "La personne est déjà présente dans la base. Souhaitez vous l’ajouter à votre référentiel Agence ? ")} 
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={cancelClickHandler}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUser2EntityAgencyModal
