import React, { useEffect, useState } from 'react'
import './NewHomeHeader.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded'
import ADMCreationModal from '../../Home/HeaderBar/ADMCreationModal/ADMCreationModal';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from 'react-bootstrap';
import { Refresh } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { CTooltip, getNotyfObject } from '../../../shared/utility';
import DocDuplicateModal from '../../Template/TableBody/DocDuplicateModal/DocDuplicateModal';
import Axios from '../../../axios-edipub';

function NewHomeHeader({ role }) {

    const { t } = useTranslation();
    const history = useHistory();
    const notyf = getNotyfObject()
    const [showADMModal, setShowAdmModal] = useState(false)
    const [showGrantAdmModal, setShowGrantAdmModal] = useState(false)
    const [showADMSteps, setShowAdmSteps] = useState(false)
    const [showADMTemplateModal, setShowADMTemplateModal] = useState(false)
    const [advertiserType, setAdvertiserType] = useState("advertiser")
    const [selectedAdvertiser, setSelectedAdvertiser] = useState()
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null)
    const [advertisers, setAdvertisers] = useState()
    const currentDate = new Date();
    const defaultStartDate = new Date((currentDate.getMonth() >= 8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const defaultEndDate = new Date(defaultStartDate.getFullYear(), 12, 0);
    const [selectedStartDateDuplicate, setSelectedStartDateDuplicate] = useState(defaultStartDate)
    const [selectedEndDateDuplicate, setSelectedEndDateDuplicate] = useState(defaultEndDate)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        }).then(res => {
            setAdvertisers(res.data.entities)
        }).catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [searchQuery])

    const createFromTemplate = () => {
        if (selectedGroup) {
            Axios.post('/document/' + selectedTemplate + '/duplicate', {
                advertiserGroupRef: selectedGroup,
                startPeriod: selectedStartDateDuplicate.getTime(),
                endPeriod: selectedEndDateDuplicate.getTime()
            }).then(response => {
                if (response?.data?.duplicatedinfo)
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée"
                    });
                if (response.data.Success) {
                    notyf.success(response.data.Success)
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else
                    notyf.error(response.data.Failed)
            })
        } else {
            Axios.post('/document/' + selectedTemplate + '/duplicate', {
                advertiserRef: selectedAdvertiser,
                startPeriod: selectedStartDateDuplicate.getTime(),
                endPeriod: selectedEndDateDuplicate.getTime()
            }).then(response => {
                if (response?.data?.duplicatedinfo)
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée"
                    });
                if (response.data.Success) {
                    notyf.success(response.data.Success)
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else
                    notyf.error(response.data.Failed)
            })
        }
        setShowADMTemplateModal(false)
    }

    const handleShowADMTemplateModalTrue = () => {
        setSelectedAdvertiser(null)
        setSelectedGroup(null)
        setSelectedTemplate(null)
        setAdvertiserType("advertiser")
        setShowADMTemplateModal(true)
    }

    return (
        <div className="headerBar__container">
            <div className="headerBar__left">
                <div className="left__icon">
                    <DescriptionIcon className="headerBar__icon" style={{ width: "40px", height: "95%" }} />
                </div>

                <div className="left__elements">
                    <h1 className="leftElement_title">{t('default:adm')}</h1>
                </div>

            </div>
            <div className="headerBar__right">
                {/* <div className="right__element">
                    <div className="rightElement__title">
                        Créer une ADM
                    </div>
                </div> */}

                <Link to={{ pathname: "/" }} className="btn-sm">
                    <ViewColumnRoundedIcon />
                </Link>

                {
                    "MG, MR".includes(role) ? (
                        <>
                            <Button className="btn-sm new_mandat" onClick={() => setShowAdmModal(true)}>
                                <AddIcon style={{ color: "#1589EE", marginBottom: "2px" }} />
                                &nbsp;&nbsp; {t('home:createAdmBtn', 'Créer une ADM')}
                            </Button>
                            <Button className="btn-sm new_mandat" onClick={() => handleShowADMTemplateModalTrue()}>
                                <AddIcon style={{ color: "#1589EE", marginBottom: "2px" }} />
                                &nbsp;&nbsp; {t('home:createAdmTemplateBtn', 'Créer une ADM depuis un modèle')}
                            </Button>
                        </>
                    ) : ""
                }
                {
                    // "RG, RC".includes(role) ? (
                    //     <Button className="btn-sm grant_mandat" onClick={() => setShowGrantAdmModal(true)}>
                    //         <AddIcon style={{color: "#1589EE", marginBottom: "2px"}} />
                    //         &nbsp;&nbsp; {t('home:createAdmBtn', 'Créer une ADM')} 
                    //     </Button>
                    // ) : ""
                }

                {
                    showADMModal ? (
                        <ADMCreationModal
                            handleClose={() => setShowAdmModal(false)}
                            show={showADMModal}
                        />
                    ) : null
                }
                {
                    showGrantAdmModal ? (
                        <ADMCreationModal
                            handleClose={() => setShowAdmModal(false)}
                            show={showGrantAdmModal}
                        />
                    ) : null
                }
                {
                    showADMTemplateModal ? (
                        <DocDuplicateModal
                            handleClose={() => setShowADMTemplateModal(false)}
                            show={showADMTemplateModal}
                            selectedAdvertiser={selectedAdvertiser}
                            setSelectedAdvertiser={setSelectedAdvertiser}
                            selectedGroup={selectedGroup}
                            setSelectedGroup={setSelectedGroup}
                            advertiserType={advertiserType}
                            setAdvertiserType={setAdvertiserType}
                            setSearchQuery={setSearchQuery}
                            advertisers={advertisers}
                            selectedStartDate={selectedStartDateDuplicate}
                            selectedEndDate={selectedEndDateDuplicate}
                            setSelectedStartDate={setSelectedStartDateDuplicate}
                            setSelectedEndDate={setSelectedEndDateDuplicate}
                            duplicateDocument={createFromTemplate}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                        />
                    ) : null
                }

            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    role: state.auth.role,
    isCreator: state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(NewHomeHeader)
