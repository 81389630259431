import React, { useContext, useEffect, useState } from 'react'
import './TabAction.css';
import axios from 'axios'
import Axios from '../../../../axios-edipub'
import DoneIcon from '@material-ui/icons/Done';
import { Notyf } from 'notyf';
import { Link } from 'react-router-dom';
import { checkValidity, getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import EmailIcon from '@material-ui/icons/Email';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ReasonModal from './ReasonModal/ReasonModal'

function TabAction({ accessToken, approveMandatHandle, user, admScrolledEnd, admData, actionTitle, tabSideActive, pdfPages, setAdmScrolledEnd, setTabSideActive, getMandatData }) {
    const notyf = getNotyfObject();
    const [randSmsCode, setRandSmsCode] = useState(Math.floor(100000 + Math.random() * 900000));
    const [confirmIdentity, setConfirmIdentity] = useState(false);////// to false
    const [docApproved, setDocApproved] = useState(false);
    const [smsCode, setSmsCode] = useState(null);
    const [matchedSmsCode, setMatchedSmsCode] = useState(false);/// to false
    const [telNum, setTelNum] = useState(user?.telephone_number)
    const [telNumError, setTelNumError] = useState(null);
    const [errors, setErrors] = useState(null)
    const [signatureDataB64, setSignatureDataB64] = useState(null);
    const [sendSmsLoading, setSendSmsLoading] = useState(false);
    const [signADMLoading, setSignADMLoading] = useState(false);
    const { t } = useTranslation();
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [sendEmail, setSendEmail] = useState(false)

    useEffect(() => {
        if (tabSideActive === "Action") {
            if (pdfPages === 1)
                setAdmScrolledEnd(true)
            else
                setAdmScrolledEnd(false)
        }
        if (tabSideActive === "timeline")
            setTabSideActive("timeline")
    }, [tabSideActive])

    useEffect(() => {
        setTelNum(user?.telephone_number)
    }, [])

    //var ttt=user?.user_id;
    // var post = {
    //     docid: admData?.uid,
    //     userUid: accessToken ? user?.uid : user?.userUid,
    //     tokenSignature: accessToken
    // }

    const apprMandatHandle = (uid) => {
        let errors = checkValidityForm();
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            approveMandatHandle(uid)
        }

    }



    const handleAction = (reasonMessage) => {
        Axios.put(`/document/${admData?.uid}/refuseDocumentAction`, {
            reasonMessage,
            accessToken: true,
            userUid: user?.userUid
        }).then(response => {
            if (!accessToken)
                setTabSideActive("timeline")
            getMandatData(admData?.uid)
        }).catch(err => {

        })
    }

    const sendSmsCode = (messageText) => {
        Axios.get('/document//sendSmsToSign', {
            params: {
                phoneNumber: telNum,
                smsMessage: messageText
            }
        }).then(response => {
            notyf.success(t("mandat:sendMessageText"))
            setSendSmsLoading(false)
            setConfirmIdentity(true)
        }).catch(response => {
            setSendSmsLoading(false)
            notyf.error(t("mandat:failedSendingMessageText"));
        })
    }

    const telNumChangeHandler = (phone) => {
        setTelNumError(null)
        setTelNum(phone)
    }

    const checkIdentityClickHandler = (checked) => {
        if (!confirmIdentity && checked) {
            if (!telNum || !checkValidity(telNum, { required: true, isPhone: true })) {
                setTelNumError(t("mandat:error_telNum", "Veuillez saisir un numéro de portable valide"));
            } else {
                setTelNumError(null)
                let messageText = t("mandat:confirmationCodeMessageText") + randSmsCode
                setSendSmsLoading(true);
                sendSmsCode(messageText)
            }
        }
    }

    const smsCodeChangeHandler = (e) => {
        let code = e.target.value;
        if (code.length < 7) {
            setSmsCode(code)
            if (code.length == 6 && +code == randSmsCode) {
                setMatchedSmsCode(true)
                setConfirmIdentity(true)
            }
        }
    }

    const checkValidityForm = () => {
        let errors = []
        if (!confirmIdentity && !matchedSmsCode) {
            errors.push(t("mandat:confirmIdentityError"));
        }
        if (!admScrolledEnd) {
            errors.push(t("mandat:readDocumentMessage"));
        }
        if (!docApproved) {
            errors.push(t("mandat:approuveDocMessage"));
        }
        if (!matchedSmsCode) {
            errors.push(t("mandat:checkCodeMessage"));
        }
        // if (!signatureDataB64) {
        //     errors.push(t("mandat:noSignDetectedMessage"));
        // }
        return errors;
    }


    const sendEmailHandle = (checked) => {
        if (!sendEmail && !matchedSmsCode && checked) {
            Axios.get('/signature2user/' + user?.email + '/authenticateEmailCode', { params: { 'code': randSmsCode } }).then(response => {
                notyf.success(t("mandat:savedSignature", "Le code vous a été envoyé par email à l'adresse " + user?.email))
                setSendEmail(checked)
            }).catch(err => {
                notyf.error(t("mandat:savedSignature", "Email KO"))
            })
        } else if (!matchedSmsCode) {
            notyf.error(t("mandat:savedSignature", "Un mail vous a été envoyé à l'adresse " + user?.email + ".<br/><br/> Veuillez réessayer dans une minute !!"))
        }
    }

    const toggleValueForEmailTimer = () => {
        if (sendEmail)
            setSendEmail(false)
    };
    useEffect(() => {
        const intervalId = setInterval(toggleValueForEmailTimer, 60000);
        return () => clearInterval(intervalId);
    }, [sendEmail])

    return (
        <>

            <div className="signatureTab__container">

                <div className="signatureTab__header">
                    <h5 className="signatureTab__header__title">
                        {actionTitle}
                    </h5>
                    {
                        errors && errors.length > 0 ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : (
                            <div className="alert alert-light" role="alert">
                                {t('mandat:signatureAlert', "Avant de poursuivre, assurez vous d'avoir bien verifier et respecter toutes les étapes")}
                            </div>
                        )
                    }
                </div>
                <div className="signatureTab__steps">
                    <div className="signatureTab__step">
                        <div className="singatureTab__step_header">
                            {
                                confirmIdentity ?
                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    : sendSmsLoading ?
                                        <Spinner animation="border" variant="primary" />
                                        :
                                        <>
                                            <div className="signatureTab__stepNumber">
                                                <span>1</span>
                                            </div>
                                        </>
                            }
                            <div className="signatureTab__stepCheckbox__container">
                                <input
                                    type="checkbox"
                                    className="step__checkbox__input"
                                    id="identityConfirmCheckbox"
                                    checked={confirmIdentity}
                                    disabled={confirmIdentity}
                                    onChange={(e) => checkIdentityClickHandler(e.target.checked)}
                                />
                                <label
                                    className="step__checkbox__label"
                                    htmlFor="identityConfirmCheckbox">
                                    {t('mandat:confirmIdentity', "Je confirme mon identité")}
                                </label>
                            </div>

                        </div>
                        <div className="signatureTab__stepContent">
                            <div className="stepContent__identityContent">
                                <fieldset disabled>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm stepContent__identityInput"
                                        placeholder="NOM PRENOM"
                                        value={user?.last_name + ' ' + user?.first_name} />
                                    <input
                                        type="text"
                                        className="form-control form-control-sm stepContent__identityInput"
                                        placeholder="Email"
                                        value={user?.email}
                                    />
                                </fieldset>
                                <PhoneInput
                                    disabled={user?.telephone_number || confirmIdentity}
                                    localization={fr}
                                    country={'fr'}
                                    inputClass={"form-control w-100"}
                                    dropdownStyle={{ width: "auto" }}
                                    preferredCountries={['fr', 'us']}
                                    enableSearch={false}
                                    inputProps={{ name: "telephone_number" }}
                                    placeholder={"33 0 00 00 00 00"}
                                    value={telNum?.substr(2) || ''}
                                    onChange={phone => telNumChangeHandler("00" + phone)}
                                />
                                {
                                    telNumError ?
                                        <span style={{ color: "red" }}>{telNumError}</span>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="signatureTab__step">
                        <div className="singatureTab__step_header">
                            {
                                admScrolledEnd ?
                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    :
                                    <div className="signatureTab__stepNumber"><span>2</span></div>
                            }
                            <div className="step__header__title">
                                {t('mandat:pdfLectureM', "Scroller le document jusqu’en bas, pour justifier de votre lecture ")}
                            </div>
                        </div>
                    </div>

                    <div className="signatureTab__step">
                        <div className="singatureTab__step_header">
                            {
                                docApproved ?
                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    :
                                    <div className="signatureTab__stepNumber"><span>3</span></div>
                            }
                            <div className="signatureTab__stepCheckbox__container">
                                <input
                                    type="checkbox"
                                    className="step__checkbox__input"
                                    id="docConfirmCheckbox"
                                    checked={docApproved}
                                    onChange={(e) => setDocApproved(e.target.checked)}
                                />
                                <label
                                    className="step__checkbox__label"
                                    htmlFor="docConfirmCheckbox">
                                    {t('mandat:approvePdfContent', "J’ai pris connaissance du document et j’approuve le contenu")}
                                </label>
                            </div>

                        </div>
                    </div>

                    <div className="signatureTab__step">
                        <div className="singatureTab__step_header">
                            {
                                matchedSmsCode ?
                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    :
                                    <div className="signatureTab__stepNumber"><span>4</span></div>
                            }
                            <div className="step__header__title">
                                {t('mandat:enterSmsCode', "Entrez le code reçu par SMS/Email")}
                            </div>
                        </div>
                        <div className="signatureTab__stepContent">
                            <div className="stepContent__identityContent">
                                <input
                                    type="text"
                                    className="form-control form-control-sm "
                                    placeholder={t('mandat:receivedCode', "Code reçu")}
                                    value={smsCode || ''}
                                    disabled={matchedSmsCode}
                                    onChange={(e) => smsCodeChangeHandler(e)} />
                                <input
                                    style={{ marginTop: "5px" }}
                                    type='checkbox'
                                    className="step__checkbox__input"
                                    id="docConfirmCheckbox"
                                    checked={sendEmail}
                                    onChange={(e) => sendEmailHandle(e.target.checked)}
                                    disabled={matchedSmsCode}
                                />
                                <label
                                    className="step__checkbox__label"
                                    style={{ fontSize: "13px", color: "#2d2c2c !important", fontWeight: "100" }}
                                    htmlFor="docConfirmCheckbox">
                                    {t('mandat:receiveEmailCode', "Recevoir le code par email")}
                                    <AlternateEmailIcon style={{ color: "rgb(89 147 179)", fontSize: "17px" }} />
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="signatureTab__signButton__container">
                    {
                        signADMLoading ?
                            <Spinner animation="border" variant="success" />
                            :
                            <>
                                <button className="btn btn-success signatureTab__signButton"
                                    onClick={() => apprMandatHandle(admData?.uid)}
                                >
                                    {t('default:confirm', "Confirmer")}
                                </button>

                                {/* <button
                                    style={{ marginLeft: "5px" }}
                                    className="btn btn-danger btn-sm signatureTab__header__resignButton"
                                    onClick={() => setShowReasonModal(true)}
                                >
                                    {t('default:deny', "Refuser")}
                                </button> */}
                            </>
                    }
                </div>
            </div >


            {
                showReasonModal ?
                    <ReasonModal
                        show={showReasonModal}
                        handleClose={() => setShowReasonModal(false)}
                        handleAction={handleAction}
                    />
                    : null
            }

            {/* <div className="signatureTab__step">
                <div
                    className="singatureTab__step_header"
                    style={{
                        justifyContent: "center",
                        margin: "35px",
                        fontSize: 80,
                        marginTop: "150px"
                    }}
                >

                    <div className="signatureTab__stepDoneIcon" style={{ justifyContent: "center", padding: "30px" }} >
                        <DoneIcon style={{ fontSize: "1.9rem" }} />
                    </div>
                </div>
                <div >
                    {//t('mandat:pdfLecture', "Lisez attentivement l’attestation de mandat jusqu’à la fin du document")
                    }
                    <h2 style={{ textAlign: "center" }}>{t("mandat:justSigned")}<br></br><br></br></h2>


                </div>
            </div> */}


        </>
    )
}




const mapStateToProps = (state) => ({
    status: state.mandat?.admData?.status,
    admScrolledEnd: state.mandat.admScrolledEnd,
    pdfPages: state.mandat.pdfPages,
    tabSideActive: state.mandat.tabSideActive
})
const mapDispatchToProps = dispatch => ({
    setTabActive: (tabActive) => dispatch(actions.setTabActive(tabActive)),
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setAdmScrolledEnd: (scrolledAnd) => dispatch(actions.setAdmScrolledEnd(scrolledAnd))
})
export default connect(mapStateToProps, mapDispatchToProps)(TabAction)