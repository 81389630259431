import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import TamponIcon from '../../../../../../../assets/images/tampon.png'
import { formatDate, getTimeDate } from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ValidatedEvent({ isCreator, eventData, isLast }) {
    const [showContent, setShowContent] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])
    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ?
                        <ExpandMoreIcon />
                        :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <img src={TamponIcon} style={{ width: "24px", height: "24px", color: "#FFFFFF", borderRadius: "5px" }} />
                    <div className="timelineCard__header_label">{t('timeline:validatedStatus', "Statut Validé")} </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span>
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span>
                    </div>
                </div>
                <div className="timelineCard__content" style={{ borderLeft: "2px solid rgb(80, 204, 122)", display: showContent ? "block" : "none" }}>
                    <div className="timelineCard__content__info">
                        {/* {
                        isCreator ? "Vous avez" : eventData?.data?.user?.first_name + ' ' + eventData?.data?.user?.last_name + ' a '
                    } partagé ce mandat */}
                        {t('timeline:approvedByAll', "Ce mandat est approuvé par tous les approbateurs")}
                    </div>
                    <div className="timelineCard__content__body">
                        <strong>{t('timeline:approversList', "Liste des approbateurs")} </strong>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{t('default:name', "Nom")}</th>
                                    <th scope="col">{t('default:society', "Société")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    eventData?.data?.contributorUsers && eventData?.data?.contributorUsers.length > 0 ?
                                        eventData?.data?.contributorUsers.map(user => (
                                            <tr>
                                                <td>{user?.first_name + ' ' + user?.last_name} </td>
                                                <td>{user.entities}</td>
                                            </tr>
                                        ))
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ValidatedEvent