import React, { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import {isMobile} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const SignatureCanvas = ({canvasHeight, canvasWidth,setSignatureDataB64, signatureDataB64, saveSignature, selectedSignature, setSelectedSignature,accessToken }) => {
  const [isDrawing, setIsDrawing] = useState(false)
  const [isEmptyCanvas, setIsEmptyCanvas] = useState(false)
  const [signatureToSave, setSignatureToSave] = useState(null)
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const {t} = useTranslation();
  let m = {}

  useEffect(() => {
    setSignatureToSave(null)
    if(selectedSignature) {
      setSignatureDataB64(selectedSignature)
      let image = new Image();
      image.src = selectedSignature;
      image.onload = () => {
        contextRef.current.drawImage(image, 0, 0)
      }
    }
  }, [selectedSignature])

  function markDroppableZone(){
    let ctx = contextRef.current;
    ctx.beginPath();
    ctx.rect(0,0,300,160);
  }

  const  onEvent = (e) => {
    m = oMousePos(canvasRef.current, e);
    markDroppableZone();
    let ctx = contextRef.current;
    if (ctx.isPointInPath(m.x, m.y)){ 
      e.stopPropagation();
      e.preventDefault();
    }
}


  const  oMousePos = (canvas, evt) => {
    var ClientRect = canvas.getBoundingClientRect();
    return { //objeto
      x: Math.round(evt.clientX - ClientRect.left),
      y: Math.round(evt.clientY - ClientRect.top)
    }
  }


  // Prevent scrolling when touching the canvas
  document.body.addEventListener("touchstart", function (e) {
    if (e.target == canvasRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, false);
  document.body.addEventListener("touchend", function (e) {
    if (e.target == canvasRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, false);
  document.body.addEventListener("touchmove", function (e) {
    if (e.target == canvasRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, false);

  function dragenter(e) {
    onEvent(e);
  }
  
  function dragover(e) {
      onEvent(e);
    }
    
  function drop(e) {
    onEvent(e);
    
    let data = e.dataTransfer;
    let files = data.files;
    // handle files
    handleFiles(files);
  }

  function handleFiles(files){
    clearCanvas()
    for (var i = 0; i < files.length; i++) {
       var theFile = files[i];
       // check if the file is an image
       var isImagen = /^image\//;
       // if it's not an image continu
       if (!isImagen.test(theFile.type)) {
         continue;
       }
       
       var img = new Image(); 
       img.src = window.URL.createObjectURL(theFile);
       img.onload = function() {
        let ctx = contextRef.current;
        
        ctx.save();  
        //  markDroppableZone();
        // clip the context
        ctx.clip();
        // draw the image 
        ctx.drawImage(this, 0, 0, 300, 150);
        ctx.restore();
        window.URL.revokeObjectURL(this.src); 
        setSignatureDataB64(canvasRef.current.toDataURL())  
        setSignatureToSave(canvasRef.current.toDataURL())  
       }
    }
  }

  const prepareCanvas = () => {
    const canvas = canvasRef.current

    const context = canvas.getContext("2d")
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = 1;
    contextRef.current = context;

    canvas.addEventListener("dragenter", dragenter, false);
    canvas.addEventListener("dragover", dragover, false);
    canvas.addEventListener("drop", drop, false);
  };

  const startDrawing = ({ nativeEvent }) => {
    nativeEvent.preventDefault()
    //const { offsetX, offsetY } = nativeEvent;
    const { offsetX, offsetY } = getMousePos(nativeEvent);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const mobileStartDrawing = ({ nativeEvent }) => {
    nativeEvent.preventDefault()
    let touch = nativeEvent.touches[0];
    const { offsetX, offsetY } = getTouchPos(nativeEvent);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    window.removeEventListener('mousemove', mousemoveOutCanvasHandler)
    setIsDrawing(false);
    setSignatureDataB64(canvasRef.current.toDataURL())
    setSignatureToSave(canvasRef.current.toDataURL())
  };
  
  const mousemoveOutCanvasHandler = useCallback(
    (e) => {
      const { offsetX, offsetY } = getMousePos(e);
        if(
          isDrawing &&
            ( 
              (offsetX <= 1 || offsetX >= 300) 
              || 
              (offsetY <= 1 || offsetY >= 150)
            )
          ) {
          finishDrawing()
        }
    },
    [isDrawing],
  )

  const draw = ({ nativeEvent }) => {
    nativeEvent.preventDefault()
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = getMousePos(nativeEvent);
    window.addEventListener('mousemove', mousemoveOutCanvasHandler)
    contextRef.current.lineTo(offsetX, offsetY); 
    contextRef.current.stroke();  
  };

  const mobileDraw = (nativeEvent) => {
    nativeEvent.preventDefault()
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = getTouchPos(nativeEvent);
    contextRef.current.lineTo(offsetX, offsetY); 
    contextRef.current.stroke(); 
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d")
    context.fillStyle = "white" 
    context.fillRect(0, 0, canvas.width, canvas.height) 
    setSignatureDataB64(null)
    setSelectedSignature(null)
    setSignatureToSave(null)
  }

  // Get the position of the mouse relative to the canvas
  function getMousePos( mouseEvent) {
    var rect = canvasRef.current.getBoundingClientRect();
    return {
      offsetX: mouseEvent.clientX - rect.left,
      offsetY: mouseEvent.clientY - rect.top
    };
  }

  // Get the position of a touch relative to the canvas
function getTouchPos(touchEvent) {
  var rect = canvasRef.current.getBoundingClientRect();
  return {
    offsetX: touchEvent.touches[0].clientX - rect.left,
    offsetY: touchEvent.touches[0].clientY - rect.top
  };
}
  useEffect(() => {
    prepareCanvas();
  }, []);

  return (
    <div >
      <canvas
        style={{border: "2px solid rgb(0, 121, 188)", borderRadius: '5px'}}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        onTouchStart={mobileStartDrawing}
        onTouchEnd={finishDrawing}
        onTouchMove={(e) => mobileDraw(e)}
        ref={canvasRef}
        className="signatureCanvas"
        width={isMobile ? "200px" : "300px"}
        //height={canvasHeight || "150px"}
      />
      <div 
        className="signatureCanvas_btnActions"
      >
        {
          !accessToken ? (
            <button 
              className="btn btn-secondary btn-sm" 
              style={{
                display: "flex", 
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.25rem"
                // width: "100%"
              }}
              onClick={() => saveSignature()}
              disabled={!signatureToSave || !signatureDataB64}
            >
              {t('default:save', "Enregistrer")} 
            </button>
          ) : null
        }
        

        <button 
          className="btn btn-danger btn-sm" 
          style={{
            display: "flex", 
            justifyContent: "center",
            alignItems: "center",
            // width: "100%"
          }}
          onClick={() => clearCanvas()}
        >
          {t('default:erase', "Effacer")} 
        </button>
      </div>
    </div>
  );
};

export default SignatureCanvas

