import React, { useCallback, useState } from 'react';
import Axios from '../../../axios-edipub';
import logo from '../../../assets/images/Icone.png'
import { Link } from 'react-router-dom';
import './ForgottenPassword.css'
function ForgottenPassword() {
    const [login, setLogin] = useState('');
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);

    const submitHandler = (e) => {
        e.preventDefault();
        checkLogin(login);
    }
    const checkLogin = useCallback((Login) => {
        setError(null)
        Axios.get('/user/'+login+'/sendResetMail').then(response => {
            setAlert(response.data.message)
        }).catch(error => {
            setError('le login indiqué n\'existe pas ou est desactivé');
        })
    }, [login])

    

    let content = (<>
        <div className="forgotPassword__title"></div>
        <div className="forgotPassword__formContainer">
            <form className="signin__form" action="#">
            <h1 className="signin__formH1">Saisissez votre login</h1>
                <label className="signin__formLabel" htmlFor='username'>Login : </label>
                <input className="signin__formInput" type="email" name="email" onChange={(e) => setLogin(e.target.value)} value={login}  required autoFocus />
                <span className="signin__error"> {error} </span>
                <button className="signin__formButton" type="submit" onClick={(e) => submitHandler(e)} >Valider</button>
            </form>
        </div>
    </>)


    if(alert) {
        content = (<>
            <h4>{alert} </h4>
            <Link to="/">Se connecter</Link>
        </>)
    }

    return (
        <div className="forgotPassword__container" style={{padding: "30px"}}>
            <Link to="/" className="signin__Icon">
                <img className="signin_logo_img" src={logo} alt="logo" />
            </Link>
            {content}
            
            
        </div>
    )
}

export default ForgottenPassword
