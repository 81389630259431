import axios from 'axios';
import * as actionTypes from './actionTypes';
import { Base64 } from 'js-base64';
import Axios from '../../axios-edipub';
import { getNotyfObject } from '../../shared/utility';
import { env } from './../../env'

const notyf = getNotyfObject();

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (login, token, userId, role) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        login: login,
        userId: userId,
        role: role
    }
}

export const authSuccessSso = (login, token, userId, role, ssoName, ssoReconnectUrl, ssoDisconnectUrl) => {
    return {
        type: actionTypes.AUTH_SUCCESS_SSO,
        token: token,
        login: login,
        userId: userId,
        role: role,
        ssoName: ssoName,
        ssoReconnectUrl: ssoReconnectUrl,
        ssoDisconnectUrl: ssoDisconnectUrl
    }
}

export const authVisSuccess = (login, token, userUId, role, connectedUserUid, connectedUserLogin) => {
    return {
        type: actionTypes.AUTH_VIS_SUCCESS,
        token: token,
        login: login,
        userId: userUId,
        role: role,
        connectedUserUid,
        connectedUserLogin
    }
}

export const setUserVisualisation = (userVisToken, userVisUid, userVisRole, userVisLogin) => {
    Axios.defaults.headers.authorization = 'Bearer ' + userVisToken;
    localStorage.setItem('token', userVisToken)
    localStorage.setItem('userId', userVisUid)
    localStorage.setItem('login', userVisLogin)
    localStorage.setItem('role', userVisRole)
    return {
        type: actionTypes.SET_VISUALISATION_USER,
        userVisToken,
        userVisUid,
        userVisRole,
        userVisLogin
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('login')
    localStorage.removeItem('role')
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

 
 


export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime)
    }
}

export const exitVisualisation = (userUid) => {
    return dispatch => {
        dispatch(authStart());
        Axios.get('/user/' + userUid + '/userTokenExitVisualisation')
            .then(response => {
                const token = response?.data?.token;
                Axios.defaults.headers.authorization = 'Bearer ' + token;
                const login = response?.data?.email;
                const expireIn = response?.data?.expireIn;
                const userUid = response?.data?.uid;
                const role = response?.data?.role;
                localStorage.setItem('token', token)
                localStorage.setItem('expirationDate', expireIn)
                localStorage.setItem('userId', userUid)
                localStorage.setItem('login', login)
                localStorage.setItem('role', role)
                dispatch(authSuccess(login, token, userUid, role))
            }).catch(response => {
                notyf.error('Une erreur s\'est produite !')
                dispatch(authFail("Erreur"))
            })
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        let authAuthorization = Base64.encode(email + ":" + password)
        let config = {
            headers: {
                'Authorization': 'Basic ' + authAuthorization
            }
        }
        axios.get(env.REACT_APP_BASE_URL + '/user/' + email + '/authenticate/', config)
            .then(response => {
                const expirationDate = new Date(response.data.expireIn * 1000);
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('expirationDate', response.data.expireIn)
                //localStorage.setItem('userId', response.data.id)
                localStorage.setItem('userId', response.data.uid)
                localStorage.setItem('login', response.data.email)
                localStorage.setItem('role', response.data.role)
                dispatch(authSuccess(response.data.email, response.data.token, response.data.uid, response.data.role));
                //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            })
            .catch(err => {
                notyf.error('Une erreur s\'est produite !')
                dispatch(authFail(err.response?.data.message))
            })
    }
}

export const authFromJwt = (token) => { //sso provide jwt in get variable token
    console.log(" authFromJwt -- debut ")
    return dispatch => {
        if (!token) {
            dispatch(logout())
        } else {
            console.log(" authFromJwt -- token existe")
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            console.log(" authFromJwt -- appel /user//checkAuth")
            instance.get('/user//checkAuth')
                .then(response => {
                    const access = response?.data?.access;
                    console.log("acces =", access)
                    console.log(" response?.data =",  response?.data)

                    if (access) {
                        console.log("authFromJwt ssoName = ", response.data.ssoName , ", ssoReconnectUrl=",response.data.ssoReconnectUrl,", ssoDisconnectUrl=",response.data.ssoDisconnectUrl)
                        localStorage.setItem('token', response.data.token)
                        localStorage.setItem('expirationDate', response.data.exp)
                        localStorage.setItem('userId', response.data.userUid)
                        localStorage.setItem('login', response.data.login)
                        localStorage.setItem('role', response.data.role)
                        localStorage.setItem('login', response.data.login)
                        localStorage.setItem('role', response.data.role)
                        localStorage.setItem('ssoName', response.data.ssoName)
                        localStorage.setItem('ssoReconnectUrl', response.data.ssoReconnectUrl)
                        localStorage.setItem('isSso', 'true')
                        dispatch(authSuccessSso(response.data.login ,response.data.token, response.data.userUid, response.data.role, response.data.ssoName, response.data.ssoReconnectUrl, response.data.ssoDisconnectUrl));
                    } else {
                        dispatch(logout());
                    }
                })
                .catch(err => {
                    notyf.error('Une erreur s\'est produite !')
                    dispatch(authFail(err.response?.data.message + ' ' + err.response?.data?.error))
                })
        }
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    console.log(" ontryautosignup authCheckState -- debut")
    return dispatch => {
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('login');
        if (!token) {
            dispatch(logout())   
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            console.log(" ontryautosignup authCheckState -- appel /user/"+email+"/checkAuth")
            instance.get('/user/' + email + '/checkAuth')
                .then(response => {
                    const access = response?.data?.access;
                    if (access) {
                        const token = response?.data?.token;
                        const login = response?.data?.login;
                        const role = response?.data?.role;
                        const userUid = response?.data?.userUid;
                        const visualisation = response?.data?.visualisation;
                        const isSso = response?.data?.isSso;
                        if (visualisation) {
                            const userVisLogin = response?.data?.userVisLogin;
                            const userVisRole = response?.data?.userVisRole;
                            const userVisUid = response?.data?.userVisUid;
                            dispatch(authVisSuccess(userVisLogin, token, userVisUid, userVisRole, userUid, login))
                        } else {
                            if(isSso){
                                const ssoName = response.data.ssoName ;
                                const ssoReconnectUrl = response.data.ssoReconnectUrl ;
                                const ssoDisconnectUrl = response.data.ssoDisconnectUrl ; 
                                console.log("authCheckState ssoName = ", ssoName , ", ssoReconnectUrl=", ssoReconnectUrl,", ssoDisconnectUrl=", ssoDisconnectUrl)                       
                                dispatch(authSuccessSso(login ,token,  userUid,  role,  ssoName,  ssoReconnectUrl,  ssoDisconnectUrl));
                            }
                            else
                                dispatch(authSuccess(login, token, userUid, role))
                        }
                    } else {
                        dispatch(logout());
                    }
                })
                .catch(err => {
                  	console.log("authCheckState catch error ")
                    dispatch(logout());
                })
            // const expirationDate = new Date(localStorage.getItem('expirationDate') * 1000)
            // if (expirationDate <= new Date()) {
            //     dispatch(logout())
            // } else {
            //     const userId = localStorage.getItem('userId')
            //     dispatch(authSuccess(token, userId))
            //     //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            // }
        }
    }
}