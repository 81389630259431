import React from 'react'
import './InputInfo.css'
import InfoIcon from '@material-ui/icons/Info';
import DropDown from '../../../components/UI/DropDown/DropDown'
import { useTranslation } from 'react-i18next';


function InputInfo({infoMessage, dropdownZindex}) {
    const {t} = useTranslation();


    return (
        <div className="askedBy_container">
            <DropDown 
                icon={<InfoIcon style={{width: "15px", marginLeft: "4px"}} className='infoIcon' />}
                zIndex={dropdownZindex}
            >
                <div className="askedBy_dropDown_container">
                    <p>
                        {infoMessage}
                    </p>
                </div>
                
            </DropDown>
        </div>
    )
}

export default InputInfo
