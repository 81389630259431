import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-edipub';
import './Emails.css';
import TableBody from '../../components/Emails/TableBody/TableBody';
import TableActions from '../../components/Emails/TableAction/TableActions';
import TableFilters from '../../components/Emails/TableFilters/TableFilters';
import TableHead from '../../components/Emails/TableHead/TableHead';
import { formatDateD } from '../../shared/utility';
import TablePagination from '../../components/Emails/TablePagination/TablePagination';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

function Emails() {
    const {t} = useTranslation();
    
    const columns = [
        {title: t('default:creationDate', "Date de création"), field: 'creation_date', width: "100px"},
        {title: "Nom destinataire", field: 'to_name', width: "150px"},
        {title: "Email destinataire", field: 'to_email', width: "150px"},
        {title: "Langue", field: 'lang', width: "50px"},
        {title: "Objet", field: 'subject', width: "250px"},
        {title: "Contenu", field: 'message'},
        {title: "Accusé de réception SMTP", field: 'send', width: "50px"},
        {title: "Date d'envoi", field: 'date_sent', width: "100px"}
    ]
    
    const initialFiltersState = {
        creationDate: "", toName: "", toEmail: "",lang: "", subject: "", message: "",
        sent: "", sentDate: "", orderBy: "creation_date", sortOrder: "DESC", page:1
    }
    
    const reverseTable = {
        'creation_date' : true ,'to_name': false,'to_email': false,
        'lang': false,'subject': false,'message': false,
        'send': false,'date_sent': false
    }
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creation_date");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [emails, setEmails] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState(reverseTable);
    const [showContentModal, setShowContentModal] = useState(false)
    const [currentEmail, setCurrentEmail] = useState(null)


    useEffect(() => {
        refreshContent(initialFiltersState)
    }, [])
    
    const refreshContent = (filters) => {
        Axios.get('/email', {  params : {...filters} }).then(res => {
            let data = res.data?.emails
            let countItems = res.data?.nbItems
            if(data)
                setEmails(data)
            setCount(+countItems)
        })
    }
    
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
            page: 1
        }
        setFilters(newFilters)
        setCurrentPage(1)
        refreshContent(newFilters)
    }


    const handleReset = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        refreshContent(initialFiltersState)
    }


    const handleChangePage = useCallback((newPage) => {
        
    }, []);

    const handleOpenModal = email => {
        setCurrentEmail(email);
        setShowContentModal(true);
    }

    const handleCloseModal = () => {
        setShowContentModal(false);
        setCurrentEmail(null);
    }

    let tBody = null;
    
    if( emails?.length > 0){
        tBody = (
            <TableBody 
                columns={columns} 
                data={emails} 
                formatDate={formatDateD}
                modalOpenHandler={handleOpenModal}/>
            )
    }

    return (
            <div className="home">
                <div className="home__container">
                    <div className="emailsTableHeader">
                        <TableActions 
                            filterState={showFilters} 
                            setShowFilters={setShowFilters} 
                            resetFilters={handleReset}
                            formatDate={formatDateD}/>
                        <TablePagination
                            filters={filters} 
                            filtersChange={setFilters} 
                            applyFilters={refreshContent} 
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                            pageChange={handleChangePage} 
                            totalElement={count} 
                            perPage={25}/>
                    </div>
                    <div className="home__homeBodyContainer" style={{marginTop: "7px"}}>
                        <div className="home__homeBodyMain">
                            <table className="table">
                                <TableHead 
                                    columns={columns} 
                                    refreshContent ={refreshContent} 
                                    reverse={reverse}
                                    sortQuery={sortQuery}
                                    setSortOrder={setSortOrder}
                                    sortOrder={sortOrder}
                                    setReverse={setReverse}
                                    setSortQuery={setSortQuery}
                                    filters={filters}
                                    setFilters={setFilters}
                                     />
                                <tbody>
                                    {showFilters && 
                                        <TableFilters 
                                        show={showFilters} 
                                        setShowFilters={setShowFilters} 
                                        formatDate={formatDateD} 
                                        filters={filters} 
                                        inputChangeHandler={inputChangeHandler}
                                        filtersChange={setFilters} 
                                        applyFilters={refreshContent}
                                        />
                                    }
                                    {tBody}
                                </tbody>            
                            </table>
                        </div>
                    </div>
                </div>
                <Modal show={showContentModal} onHide={handleCloseModal} dialogClassName="emailsTableModalSize" >
                    <Modal.Header closeButton>
                    <Modal.Title>{currentEmail?.subject}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: '<p> ' + currentEmail?.name + ", </p><br>" + currentEmail?.message}}></Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            </div>
    )
}
export default Emails



