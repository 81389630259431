import React, { useCallback, useEffect, useState, useRef } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility';


function ListManagerDirectionDepartments(props) {
    let {
        handleFormChanged,
    } = props

    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [itemsToUpdate, setItemsToUpdate] = useState([]);
    const [itemsToDelete, setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({});
    const [updateLoading, setUpdateLoading] = useState(false);

    const getDepartmentsList = useCallback(() => {
        Axios.get('department',
        ).then(response => {
            setItems(response.data)
        })
    }, [])

    const departmentAddHandler = () => {
        Axios.post('/department', newItem
        ).then(response => {
            setItemsToUpdate([])
            setItemsToDelete([])
            if (response?.data?.departmentExist) {
                notyf.error(t("masterData:existDepartmentLabel", "Ce département existe déjà!"))
            } else {
                getDepartmentsList()
                notyf.success('Ajout sauvegardé')
            }
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })
    }

    const departmentsUpdateHandler = () => {
        setUpdateLoading(true)
        let data = {
            'departmentsToUpdate': itemsToUpdate,
            'departmentsToDelete': itemsToDelete
        }
        Axios.put(`/department//editAll`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getDepartmentsList()
                setUpdateLoading(false)
                handleFormChanged(false)
                notyf.success('Modifications sauvegardées')
            }).catch(err => {
                setUpdateLoading(false)
                notyf.error('Une erreur s\'est produite !')
            })
    }

    return (
        <ListManagerAbstract
            title={t('masterData:directionDepartments', "Département & direction")}
            addNewTitle={t('masterData:addNewDepartment', "Ajouter un nouveau département")}
            addNewAlreadyExistLabel={t("masterData:existDepartmentLabel", "Ce département existe déjà!")}
            fieldColumnNames={{ name: t('masterData:departmentName', 'Nom département :') }}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            uniqueFields={["name"]}
            mandatoryFields={["name"]}
            setNewItem={setNewItem}
            getItemList={getDepartmentsList}
            itemAddHandler={departmentAddHandler}
            itemsUpdateHandler={departmentsUpdateHandler}
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading}
        />
    )
}

export default ListManagerDirectionDepartments