import React, { useCallback, useEffect, useState, useRef } from 'react';
import { getNotyfObject } from '../../shared/utility';
import Axios from '../../axios-edipub';
import { connect } from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';
import './Template.css';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import TableBody from '../../components/Template/TableBody/TableBody';
import TableActions from '../../components/Template/TableAction/TableActions';
import TableFilters from '../../components/Template/TableFilters/TableFilters';
import TableHead from '../../components/Template/TableHead/TableHead';
import { formatDate } from '../../shared/utility';
import TablePagination from '../../components/Template/TablePagination/TablePagination';
import { useTranslation } from 'react-i18next';

function Template({ role }) {
    const { t } = useTranslation();

    const columns = [
        // {title: t('newHome:admNumber'), field: 'id', width: "120px"}, 
        // {title: t('newHome:official_name', "Annonceur"), field: 'official_name', width: "150px"},
        // {title: t('newHome:start_period', "Date début"), field: 'start_period', width: "150px"},
        // {title: t('newHome:end_period', "Date fin"), field: 'end_period', width: "150px"},
        { title: t('newHome:description', "Description"), field: 'description', width: "auto" },
        // { title: t('newHome:mandataire', "Mandataire"), field: 'mandataire', width: "150px" },
        { title: t('newHome:medias', "Médias"), field: 'medias', width: "200px" },
        // { title: t('newHome:products', "Produits"), field: 'products', width: "200px" }
    ]
    const [agencyFilter, setAgencyFilter] = useState(null)
    const [subAgencyFilter, setSubAgencyFilter] = useState(null)

    const initialFiltersState = {
        status: "", creationDate: "", official_name: "", mandataire: "", start_period: "", end_period: "", description: "",
        medias: "", products: "", orderBy: "creationDate", sortOrder: "DESC", page: 1, selectedRows: [], selectedAllRows: false,
        selectedAllPerPage: []
    }

    const reverseTable = {
        'Status': false, 'creationDate': false, 'official_name': false,
        'mandataire': false, 'start_period': false, 'end_period': false,
        'description': false, 'medias': false, 'produits': false
    }
    const [filterOptions, setFilterOptions] = useState([])
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [adms, setAdms] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [loading, setLoading] = useState(false);
    const notyf = getNotyfObject()

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState(reverseTable);


    useEffect(() => {

        refreshContent(initialFiltersState)
        Axios.get('/entity//agenciesAndSubAgencies', { params: { subAgencyFilter: subAgencyFilter, agencyFilter: agencyFilter } }
        ).then(res => {
            setFilterOptions(res?.data)
        })
            .catch(err => {
            })

    }, [subAgencyFilter, agencyFilter])

    const handleSubAgencyFilterChange = useCallback((subagency) => {
        setSubAgencyFilter(subagency);
    }, [])
    const handleAgencyFilterChange = useCallback((agency) => {
        setAgencyFilter(agency);
    }, [])


    const refreshContent = (filters) => {
        setLoading(true);
        Axios.get('/document//templateDocs', {
            params: {
                ...filters,
                subAgencyFilter: subAgencyFilter,
                agencyFilter: agencyFilter
            }
        }
        ).then(res => {
            let data = res.data?.mandats
            let countItems = res.data?.nbItems
            if (data) {
                setAdms(data)
            }
            setCount(countItems)
        })
            .catch(err => {
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
            selectedAllPerPage: [],
            selectedRows: []
        }
        setFilters(newFilters)
        refreshContent(newFilters)
    }

    const handleReset = () => {
        setFilters(initialFiltersState)
        setSubAgencyFilter(null)
        setAgencyFilter(null)
        setCurrentPage(1)
        refreshContent(initialFiltersState)
    }

    let tBody = null;

    if (loading)
        tBody = <tr><td colSpan="12"><Spinner /></td></tr>
    else if (adms?.length > 0) {
        tBody = (
            // const {data, formatDate, refreshContent} = props;
            <TableBody
                data={adms}
                formatDate={formatDate}
                refreshContent={refreshContent}
                filters={filters}
            />
        )
    }

    return (
        <div className="home">
            <div className="home__container">
                <div className="headerBar__containerkpi">
                    <div className="headerBar__left">
                        <div className="header_actionTypes_containerkpi">
                            <NoteAddIcon className="header_icon_kpi" style={{ width: "50px", height: "95%" }} />
                        </div>
                        <div className="left__elementskpi">
                            <h1 className="leftElement_title">{t('template:templates')}   </h1>
                        </div>
                    </div>
                    <div className="headerBar__right">
                    </div>
                </div>
                <div className="invoiceHeader">
                    <TableActions
                        filterState={showFilters}
                        setShowFilters={setShowFilters}
                        resetData={handleReset}
                        formatDate={formatDate} />
                    <select
                        value={(agencyFilter != null) ? agencyFilter : ""}
                        onChange={e => handleAgencyFilterChange(e.target.value)}
                        className="headerBarFilter__select headerBarFilter__selectEntity" name="selectAgence">
                        <option key='all' value="">{t('home:allAgencies', "Tous les agences")}</option>
                        {
                            filterOptions?.agencies?.sort((a, b) => a.name.localeCompare(b.name)).map((a, i) => (
                                <option key={i} value={a.uid}>{a.name}</option>
                            ))
                        }
                    </select>
                    <select
                        value={(subAgencyFilter != null) ? subAgencyFilter : ""}
                        onChange={e => handleSubAgencyFilterChange(e.target.value)}
                        className="headerBarFilter__select headerBarFilter__selectEntity" name="selectSubAgence">
                        <option key='all' value="">{t('home:allSubAgencies')}</option>
                        {
                            filterOptions?.subAgencies?.sort((a, b) => a.name.localeCompare(b.name)).map((a, i) => (
                                <option key={i} value={a.uid}>{a.name}</option>
                            ))
                        }
                    </select>
                    <TablePagination
                        filters={filters}
                        filtersChange={setFilters}
                        applyFilters={refreshContent}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalElement={count}
                        perPage={25} />
                </div>

                <div className="home__homeBodyContainer" style={{ marginTop: "7px" }}>
                    <div className="home__homeBodyMain">
                        <table className="table tableFixHead">
                            <TableHead
                                columns={columns}
                                refreshContent={refreshContent}
                                reverse={reverse}
                                sortQuery={sortQuery}
                                setSortOrder={setSortOrder}
                                sortOrder={sortOrder}
                                setReverse={setReverse}
                                setSortQuery={setSortQuery}
                                filters={filters}
                                setFilters={setFilters} />
                            <tbody>
                                {showFilters &&
                                    <TableFilters
                                        show={showFilters}
                                        formatDate={formatDate}
                                        filters={filters}
                                        inputChangeHandler={inputChangeHandler}
                                        filtersChange={setFilters}
                                        applyFilters={refreshContent}
                                    />
                                }
                                {tBody}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Template)



