import React from 'react'
import './LanguageList.css'
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';

const languages = [
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    // {
    //   code: 'de',
    //   name: 'Deutsch',
    //   country_code: 'de',
    // },
  ]
  
function LanguageList() {
    const currentLanguageCode = localStorage.getItem('i18nextLng') || 'fr'
    const { t, i18n } = useTranslation();

    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
    }
    return (
        <>
            <div className="dropdown">
                <button
                    className="btn btn-link dropdown-toggle language_button"
                    type="button"
                    id="dropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                >
                <LanguageIcon  />
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                
                <a className="dropdown-item-text mx-4" style={{fontWeight: "bold"}}>{t('default:language')}</a>
    
                {languages.map(({ code, name, country_code }) => (
                    <a
                        key={code}
                        href="#"
                        className= {`dropdown-item ${(currentLanguageCode === code) ? 'disabled' : null}` }
                        onClick={() => handleClick(code)}
                    >
                        {/* <FlagIcon code={country_code} /> */}
                        <span
                            className={`flag-icon flag-icon-${country_code} mx-2`}
                            style={{
                                opacity: currentLanguageCode === code ? 0.5 : 1,
                            }}
                        ></span>
                        {name}
                    </a>
                ))}
                </div>
            </div>
        </>
    )
}

export default LanguageList
