import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './MasterData.css';
import { Notyf } from 'notyf';

import iconUser from '../../assets/images/icon-user.png'
import iconEntity from '../../assets/images/icon-entity.png'
import iconList from '../../assets/images/icon-list.svg'
import Axios from '../../axios-edipub';
import UserForm from '../../components/MasterData/UserForm/UserForm';
import { fi } from 'date-fns/locale';
import CompanyForm from '../../components/MasterData/CompanyForm/CompanyForm';
import ListManagerGeo from '../../components/MasterData/ListManager/ListManagerGeo';
import ListManagerMedia from '../../components/MasterData/ListManager/ListManagerMedia';
import ListManagerMarketing from '../../components/MasterData/ListManager/ListManagerMarketing';
import ListManagerMission from '../../components/MasterData/ListManager/ListManagerMission';
import ListManagerClause from '../../components/MasterData/ListManager/ListManagerClause';
import ListManagerDirectionFunctions from '../../components/MasterData/ListManager/ListManagerDirectionFunctions';
import ListManagerDirectionDepartments from '../../components/MasterData/ListManager/ListManagerDirectionDepartments';
import GroupIcon from '@material-ui/icons/Group';
import GroupForm from '../../components/MasterData/GroupForm/GroupForm'
import { checkValidity, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { useTranslation } from 'react-i18next';
import UserFormValidation from '../../components/MasterData/UserForm/UserFormValidation/UserFormValidation';
import SiretsModal from '../../components/MasterData/CompanyForm/Modals/SiretsModal/SiretsModal'
import PageChangeModal from './PageChangeModal/PageChangeModal';
import RedirectEntityModal from './RedirectEntityModal/RedirectEntityModal';
import { Prompt } from 'react-router-dom';
import AEListGuide from './AEListGuide/AEListGuide';
import Spinner from 'react-bootstrap/Spinner'


function MasterData(props) {
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const TYPE_USERS = "utilisateurs";
    const TYPE_COMPANIES = "entreprises";
    const TYPE_GROUPS = "groupes";
    const TYPE_PARAMETERS = "parametres";
    const PARAMETER_GEO = "geo";
    const PARAMETER_MEDIA = "media";
    const PARAMETER_DIRECTION_FUNCTIONS = "FUNCTIONS_DIRECTION";
    const PARAMETER_DIRECTION_DEPARTMENT = "DEPARTMENT_DIRECTION"
    const PARAMETER_MARKETING = "marketing";
    const PARAMETER_MISSION = "mission";
    const PARAMETER_CLAUSE = "clause";
    const [typeMasterData, setTypeMasterData] = useState(TYPE_USERS)
    const [usersSearchQuery, setUsersSearchQuery] = useState("");
    const [entitiesSearchQuery, setEntitiesSearchQuery] = useState("");
    const [entitiesCategory, setEntitiesCategory] = useState(null);
    const [groupesSearchQuery, setGroupesSearchQuery] = useState("");
    const [usersList, setUsersList] = useState(null);
    const [entitiesList, setEntitiesList] = useState(null);
    const [entitiesRefList, setEntitiesRefList] = useState([]);
    const [entityRefToScroll, setEntityRefToScroll] = useState(null);
    const [groupesList, setGroupesList] = useState(null);
    const [sortCompany, setSortCompany] = useState(false);
    const [sortUsers, setSortUsers] = useState(false);
    const [sortGroups, setSortGroups] = useState(false);
    const [validEntityData, setValidEntityData] = useState(true);
    const userRole = localStorage.getItem('role');
    const [user, setUser] = useState({
        is_legal_representative: false,
        is_signataire: false,
        status: "Nouveau"
    });
    const [userCopy, setUserCopy] = useState(null);
    const [usersPending, setUsersPending] = useState(null);
    const [entity, setEntity] = useState({ category: "Annonceur" });
    const [group, setGroup] = useState({ category: "Annonceur" });
    const [functionsList, setFunctions] = useState([]);
    const [departmentsList, setDepartements] = useState([]);
    const [userRoleChanged, setUserRoleChanged] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [pageChangeModal, setPageChangeModal] = useState(null);
    const [redirectEntityModal, setRedirectEntityModal] = useState(null);
    const [getLoading, setGetLoading] = useState(false);
    const [putLoading, setPutLoading] = useState(false);
    const [activeValidation, setActiveValidation] = useState(null);
    const [parameter, setParameter] = useState(props.role == "AE" ? PARAMETER_MEDIA : PARAMETER_CLAUSE);
    const [entitySirets, setEntitySirets] = useState(null);
    const [showEntitySiretsModal, setShowEntitySiretsModal] = useState(false);


    const allowedToViewGroups = useMemo(() => (["AE", "MG", "MR", "MC"]), [props.role]);
    const allowedToAddGroups = useMemo(() => (["AE", "MG", "MR"]), [props.role]);

    const getActiveValidationState = () => {
        Axios.get('/settings//activateValidation').then(response => {
            let validation = +response?.data?.validation;
            setActiveValidation(validation);
        }).catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }

    const getFunctions = (departement, userUidParam = "") => {
        Axios.get("/user/" + departement + "/usersFunctions", { params: { ue: (userUidParam != "") ? (userUidParam) : null } }
        ).then(response => {
            if (response?.data?.functions && response?.data?.departements) {
                setFunctions(response?.data?.functions);
                setDepartements(response?.data?.departements);
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    useEffect(() => {
        getFunctions(1);
        getActiveValidationState();
    }, [])

    useEffect(() => {
        if (entityRefToScroll !== null && entitiesRefList !== null && (entityRefToScroll in entitiesRefList)) {
            entitiesRefList[entityRefToScroll].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setEntityRefToScroll(null)
        }
    });

    function handleSortedCompany() {
        let sortCompanyVar = !sortCompany;
        setSortCompany(sortCompanyVar)
        getEntitiesList(entitiesSearchQuery, sortCompanyVar)
    }

    function handleSortedUsers() {
        let sortUserVar = !sortUsers;
        setSortUsers(sortUserVar);
        getUsersList(usersSearchQuery, sortUserVar);
    }

    function handleSortedGroups() {
        let sortGroupsVar = !sortGroups;
        setSortGroups(sortGroupsVar)
        getgroupesList(groupesSearchQuery, sortGroupsVar)
    }

    function getUsersListContent(searchQ, order) {
        setGetLoading(true)
        Axios.get("/user//entitiesUsers", {
            params: {
                searchQuery: searchQ,
                sortOrder: (order) ? "DESC" : "ASC"
            }
        }).then(response => {
            setGetLoading(false)
            if (response?.data?.users)
                setUsersList(response?.data?.users);
            //pas utilisé car on utilise pas flag pending
            // if("AE, AG".includes(props.role))
            //     props.getCountMasterDatasPending()
        }).catch(response => {
            setGetLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const getUsersList = useCallback((searchQ = usersSearchQuery, order = false) => {
        getUsersListContent(searchQ, order)
    }, [usersList])

    function getUserListFunc(searchQ = usersSearchQuery, order = false) {
        getUsersListContent(searchQ, order)
    }


    function getEntitiesListContent(searchQ, order, category) {
        let filter = {
            searchQuery: searchQ,
            category: category,
            sortOrder: (order) ? "DESC" : "ASC"
        }
        setGetLoading(true)
        Axios.get('/entity', {
            params: filter
        })
            .then(res => {
                const refs = res.data.entities.reduce((acc, value) => {
                    acc[value.uid] = React.createRef();
                    return acc;
                }, {});
                setGetLoading(false)
                setEntitiesList(res.data.entities)
                setEntitiesRefList(refs)
            }).catch(response => {
                setGetLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"));
            })
    }

    const getEntitiesList = useCallback((searchQ = entitiesSearchQuery, order = false, category = entitiesCategory,) => {
        getEntitiesListContent(searchQ, order, category);
    }, [entitiesList, entitiesCategory])

    function getEntitiesListFunc(searchQ = entitiesSearchQuery, order = false, category = entitiesCategory) {
        getEntitiesListContent(searchQ, order, category);
    }

    function getgroupesListContent(searchQ, order) {
        let filter = {
            searchQuery: searchQ,
            sortOrder: (order) ? "DESC" : "ASC"
        }
        setGetLoading(true)
        Axios.get('/group', {
            params: filter
        })
            .then(res => {
                setGetLoading(false)
                setGroupesList(res.data.groupes)
            }).catch(response => {
                setGetLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"));
            })
    }

    const getgroupesList = useCallback((searchQ = groupesSearchQuery, order = false) => {
        getgroupesListContent(searchQ, order)
    }, [groupesList])

    function getgroupesListFunc(searchQ = groupesSearchQuery, order = false) {
        getgroupesListContent(searchQ, order)
    }

    const getUserDetails = (userUid, saved = false) => {
        const callback = () => {
            setUserRoleChanged(false)
            Axios.get("user/" + userUid + "/user").then(response => {
                if (response?.data.hasOwnProperty('usersPending')) {
                    if (response.data.usersPending.length === 1) {
                        setUser(response.data.usersPending[0])
                    } else {
                        setUser({
                            is_legal_representative: false,
                            is_signataire: false,
                            pending: 1
                        })
                    }
                    setUsersPending(response.data.usersPending)
                } else if (response?.data.hasOwnProperty('userscopyPending')) {
                    setUser(response?.data?.user);
                    setUsersPending(response?.data?.userscopyPending)
                } else {
                    setUser(response?.data?.user);
                    setUsersPending(null)
                    setUserCopy({});
                    getFunctions(response?.data?.user.departementId, response?.data?.user?.userUid)
                }
            }).catch(response => {
                notyf.error(t("default:errorMessage"));
            })
        }
        if (saved)
            callback();
        else
            handlePageChangeModal(() => callback())
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            switch (typeMasterData) {
                case TYPE_USERS:
                    getUserListFunc();
                    break;
                case TYPE_COMPANIES:
                    getEntitiesList();
                    break;
                case TYPE_GROUPS:
                    getgroupesList();
                    break;
                default:
                    break;
            }
        }
    };


    const getUserCopyDetails = (userCopyUid, saved = false) => {
        const callback = () => {
            Axios.get("userCopy/" + userCopyUid + "/userCopy").then(response => {
                setUserCopy(response?.data?.userCopy);
                setUser({
                    is_legal_representative: false,
                    is_signataire: false
                });
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage"));
            })
        }
        if (saved)
            callback();
        else
            handlePageChangeModal(() => callback())
    }

    const getEntityDetails = (entityUid, saved = false, redirect = false) => {
        const callback = () => {
            Axios.get("entity/" + entityUid + "/entity").then(response => {
                setEntity(response?.data?.entity);
                if (saved) {
                    const newEntitiesList = [...entitiesList];
                    const editedEntity = newEntitiesList.find(e => e.uid === entityUid);
                    editedEntity.official_name = response?.data?.entity.official_name;
                    editedEntity.commercial_name = response?.data?.entity.commercial_name;
                    setEntitiesList(newEntitiesList);
                }
            }).catch(response => {
                notyf.error(t("default:errorMessage"));
            })
        }
        if (saved)
            callback();
        else if (redirect)
            handleRedirectEntityModal(() => callback());
        else
            handlePageChangeModal(() => callback());
    }

    const getGroupDetails = (groupUid) => {
        handlePageChangeModal(() => {
            Axios.get("group/" + groupUid + "/group").then(response => {
                setGroup(response?.data?.group);
            }).catch(response => {
                notyf.error(t("default:errorMessage"));
            })
        })
    }

    useEffect(() => {
        switch (typeMasterData) {
            case TYPE_USERS:
                getUsersList();
                break;
            case TYPE_COMPANIES:
                getEntitiesList();
                break;
            case TYPE_GROUPS:
                getgroupesList();
                break;
            default:
                break;
        }

    }, [typeMasterData])

    useEffect(() => {
        if (typeMasterData === TYPE_PARAMETERS && props.role !== "AE") {
            getEntitiesList("", false, "Agence");
        }
    }, [typeMasterData])


    // const refreshUsersHandler = () => {
    //     setUsersSearchQuery(null);
    //     getUsersList();
    // }

    // const refreshEntitiesHandler = () => {
    //     setEntitiesSearchQuery(null);
    //     getEntitiesList();
    // }

    const searchUsersHandler = (e) => {
        setUsersSearchQuery(e.target.value);
        // getUsersList(e.target.value)
    }

    const searchEntitiesHandler = (e) => {

        setEntitiesSearchQuery(e.target.value);
        // getEntitiesList(e.target.value)
    }

    const entitiesCategoryChangeHandler = (category) => {
        setEntitiesCategory(category);
        getEntitiesList(entitiesSearchQuery, false, category)
    }

    const searchGroupesHandler = (e) => {
        setGroupesSearchQuery(e.target.value);
        // getgroupesList(e.target.value)
    }

    const mapUserRole2CompanyCategory = new Map();
    mapUserRole2CompanyCategory.set('AG', 'Annonceur');
    mapUserRole2CompanyCategory.set('AC', 'Annonceur');
    mapUserRole2CompanyCategory.set('MC', 'Agence');
    mapUserRole2CompanyCategory.set('MR', 'Agence');
    mapUserRole2CompanyCategory.set('MG', 'Agence');
    mapUserRole2CompanyCategory.set('RG', 'Regie');
    mapUserRole2CompanyCategory.set('RC', 'Regie');

    const inputUserChangeHandler = (field, value) => {
        let userTemp = { ...user }
        if (field === "role") {
            userTemp = {
                ...userTemp,
                functionId: "",
                departementId: "",
                lang: "FR",
                role: value
            }
            if (userTemp.entities) {
                let entities = []
                let entitiesToDelete = [];
                if (value !== "") {
                    let companyCategory = mapUserRole2CompanyCategory.get(value)
                    userTemp.entities.forEach(element => {
                        if (element.category == companyCategory)
                            entities.push(element)
                        else
                            entitiesToDelete.push(element?.uid)

                    });
                    userTemp.entities = entities;
                    if (userTemp?.entitiesToDelete)
                        userTemp.entitiesToDelete.concat(entitiesToDelete);
                    else
                        userTemp.entitiesToDelete = entitiesToDelete;
                }
            }
        } else {
            userTemp = {
                ...userTemp,
                [field]: value
            }
        }

        setUser(userTemp)
        setFormChanged(true);

    }

    const inputUserCopyChangeHandler = (field, value) => {
        let userCopyTemp = {
            ...userCopy,
            [field]: value
        }
        setUserCopy(userCopyTemp)
        setFormChanged(true);
    }

    const addUserclickHandler = (e) => {
        e.preventDefault();

        if (user) {
            setPutLoading(true)
            Axios.post('user', user).then(response => {
                setPutLoading(false)
                if (response.data.hasOwnProperty('error')) {
                    notyf.error(t("default:emailAlreadyExist"));
                } else {
                    notyf.success(t("default:userAdded"))
                    setUser(null);
                    getUsersList();
                }
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
        setUserRoleChanged(false)
    }

    const updateUserclickHandler = (e, userUid) => {
        e.preventDefault();

        if (user) {
            setPutLoading(true)
            Axios.put('user/' + userUid + '/user', user).then(response => {
                setPutLoading(false)
                const newUsersList = [...usersList];
                const editedUser = newUsersList.find(u => u.userUid === userUid);
                editedUser.first_name = user.first_name;
                editedUser.last_name = user.last_name;
                editedUser.entities = user.entities;
                setUsersList(newUsersList);
                getUserDetails(userUid, true)
                notyf.success(t("default:userUpdate"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const deleteUserClickHandler = (userUid, data) => {
        setPutLoading(true)
        Axios.put(`user/${userUid}/deleteUserAndEditDocs`, data).then(response => {
            setPutLoading(false)
            const newUsersList = [...usersList];
            const index = newUsersList.findIndex(u => u.userUid === userUid);
            newUsersList.splice(index, 1);
            setUsersList(newUsersList);
            setUser({
                is_legal_representative: false,
                is_signataire: false,
                status: "Nouveau"
            })
            notyf.success(t("masterData:userDeleted", "L'utilisateur a bien été supprimé"))
            if (response?.data?.lostValidatorsAdms?.length > 0) {
                let warningMessage = "Une approbation était attendue par cet utilisateur Pensez à choisir un nouvel approbateur dans le workflow d’approbation des ADM : " + response.data.lostValidatorsAdms.join()
                notyf.open({
                    closeWith: ['click'],
                    type: 'warning',
                    background: 'orange',
                    message: warningMessage
                });
            }
        }).catch(response => {
            setPutLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const validateUsercopy = (e, userUid) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.put('user/' + userUid + '/validateUsercopy', user).then(response => {
                setPutLoading(false)
                getUsersList();
                getUserDetails(userUid, true)
                notyf.success(t("default:userValidation"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("default:errorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const resignUsercopy = (e, userUid) => {
        e.preventDefault();
        setPutLoading(true)
        Axios.put('user/' + userUid + '/resignUsercopy', user).then(response => {
            setPutLoading(false)
            getUsersList();
            getUserDetails(userUid, true)
            notyf.success(t("default:abandonedChanges"))
        }).catch(response => {
            setPutLoading(false)
            notyf.error(t("default:errorMessage"))
        })
    }

    const validateUserAdd = (e, email) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.put('user/' + email + '/validateUserAdd', user).then(response => {
                setPutLoading(false)
                getUsersList();
                getUserDetails(response?.data?.userUid, true)
                notyf.success(t("default:userValidation"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("default:errorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const resignUserAdd = (e, email) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.delete('user/' + email + '/resignUserAdd', {
                data: {
                    email
                }
            }).then(response => {
                setPutLoading(false)
                getUsersList();
                setUser({
                    is_legal_representative: false,
                    is_signataire: false
                })
                //getUserDetails(response?.data?.userUid) 
                notyf.success(t("default:userDeletion"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("default:errorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const clearSearch = () => {
        switch (typeMasterData) {
            case TYPE_USERS:
                setUsersSearchQuery('')
                getUserListFunc('')
                break;
            case TYPE_COMPANIES:
                setEntitiesSearchQuery('')
                getEntitiesListFunc('');
                break;
            case TYPE_GROUPS:
                setGroupesSearchQuery('')
                getgroupesListFunc('');
                break;
            default:
                break;
        }
    }

    const validateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.put('user/' + userUid + '/userValidation', user).then(response => {
                setPutLoading(false)
                getUsersList();
                getUserDetails(userUid, true)
                notyf.success(t("default:userValidation"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const desactivateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.put('user/' + userUid + '/userDesactivation', user).then(response => {
                setPutLoading(false)
                getUsersList();
                getUserDetails(userUid, true)
                notyf.success(t("default:userDisabled"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
        setUserRoleChanged(false)
    }

    const addUserCopyclickHandler = (e, userUid) => {
        e.preventDefault();
        if (user) {
            setPutLoading(true)
            Axios.post('user/' + userUid + '/userCopy', user).then(response => {
                setPutLoading(false)
                if (response.data.hasOwnProperty('error')) {
                    notyf.error("erreur !");
                } else {
                    notyf.success(t("default:userUpdate"))
                    getUsersList();
                    getUserCopyDetails(response?.data?.userCopy?.userUid, true)
                }
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const updateUserCopyclickHandler = (e, userCopyUid) => {
        e.preventDefault();

        if (userCopy) {
            setPutLoading(true)
            Axios.put('userCopy/' + userCopyUid + '/userCopy', userCopy).then(response => {
                setPutLoading(false)
                getUserCopyDetails(userCopyUid, true)
                notyf.success(t("default:userUpdate"))

            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
        setUserRoleChanged(false)
    }

    const addEntityclickHandler = (e) => {
        e.preventDefault();
        if (entity) {
            setPutLoading(true)
            Axios.post('entity', entity).then(response => {
                setPutLoading(false)
                notyf.success(t("default:newEntity"))
                setEntity({ category: "Annonceur" });
                getEntitiesList();
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const validateEntityclickHandler = (e) => {
        e.preventDefault();
        let entityUid = entity.uid
        if (entity) {
            setPutLoading(true)
            Axios.put('entity/' + entityUid + '/entityValidation', entity).then(response => {
                setPutLoading(false)
                getEntitiesList(entitiesSearchQuery);
                getEntityDetails(entityUid, true)
                notyf.success(t("default:entityValidation"))
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const disableEntityclickHandler = (e) => {
        e.preventDefault();
        let entityUid = entity.uid
        if (entity) {
            setPutLoading(true)
            Axios.put('entity/' + entityUid + '/entityDisabling', entity).then(response => {
                setPutLoading(false)
                notyf.success(t("default:entityDisabled"))
                setEntity({ category: "Annonceur" })
                getEntitiesList();
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
        setUserRoleChanged(false)
    }

    const updateEntityclickHandler = (e) => {
        e.preventDefault();
        let entityUid = entity.uid
        if (entity) {
            setPutLoading(true)
            Axios.put('entity/' + entityUid + '/entity', entity).then(response => {
                setPutLoading(false)
                //getEntitiesList();
                getEntityDetails(entityUid, true);
                notyf.success(t("default:updateEntity"))

            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const cancelEntityclickHandler = (e) => {
        e.preventDefault();
        let entityUid = entity.uid
        if (entity) {
            getEntityDetails(entityUid, true);
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const updateEntityWithApiResult = (entityApi) => {
        let entityTemp = { ...entity }
        if (entity?.id) {
            let filteredIdentNumToDelete = entity?.identNumbers ? entity?.identNumbers?.filter(u => (entityApi?.identNumbers.some(u2 => (u2.type === u.type && u2.number !== u.number)))).map(a => a.uid) : []
            let filteredIdentNumbersToAdd1 = entity?.identNumbers ? entityApi?.identNumbers?.filter(u => (entity?.identNumbers.some(u2 => (u2.type === u.type && u2.number === u.number)) === false)) : entityApi?.identNumbers
            let filteredIdentNumbersToAdd2 = entity?.identNumToAdd ? entity?.identNumToAdd?.filter(u => (entityApi?.identNumbers.some(u2 => u2.type === u.type) === false)) : []
            let filteredIdentNumbers = entity?.identNumbers ? entity?.identNumbers?.filter(u => (entityApi?.identNumbers.some(u2 => u2.type === u.type) === false)) : []
            //affecte
            entityApi.identNumToDelete = (entity?.identNumToDelete ? entity?.identNumToDelete : []).concat(filteredIdentNumToDelete)
            entityApi.identNumToAdd = (filteredIdentNumbersToAdd1).concat(filteredIdentNumbersToAdd2)
            if (entityApi?.identNumbers)
                entityApi.identNumbers = entityApi.identNumbers.concat(filteredIdentNumbers);
        } else {
            let filteredIdentNumbers = entity?.identNumbers ? entity?.identNumbers?.filter(u => (entityApi?.identNumbers.some(u2 => u2.type === u.type) === false)) : []
            let filteredIdentNumbersToAdd = entity?.identNumToAdd ? entity?.identNumToAdd?.filter(u => (entityApi?.identNumbers.some(u2 => u2.type === u.type) === false)) : []
            if (entityApi?.identNumbers)
                entityApi.identNumbers = entityApi.identNumbers.concat(filteredIdentNumbers);
            if (entityApi?.identNumToAdd)
                entityApi.identNumToAdd = entityApi.identNumToAdd.concat(filteredIdentNumbersToAdd);
        }
        entityTemp = {
            ...entityTemp,
            ...entityApi
        }
        setEntity(entityTemp)
    }

    const updateEntityWithApiClickHandler = (e, entityTemp = null) => {
        setPutLoading(true)
        Axios.post('/entity//getApiDataFromDenominationOrSirenSiretOrVat',
            (entityTemp ? { ...entityTemp } : { ...entity })
        ).then(response => {
            setPutLoading(false)
            if (entity?.id) {
                updateEntityWithApiResult(response.data.entity);
                setFormChanged(true);
            } else {
                if (response.data?.entity) {
                    updateEntityWithApiResult(response.data.entity);
                    setFormChanged(true);
                } else if (response.data?.siretList) { //err?.response?.data?.siretList && err?.response?.data?.siretList.length>0
                    setEntitySirets(response?.data?.siretList)
                    setShowEntitySiretsModal(true)
                } else if (response.data?.redirectEntityUid) {
                    if (!(response.data?.redirectEntityUid in entitiesRefList)) {
                        setEntitiesSearchQuery('');
                        setEntitiesCategory('');
                        let entitiesSearchQueryTemp = ''
                        let categoryTemp = ''
                        getEntitiesList(entitiesSearchQueryTemp, false, categoryTemp)
                    }
                    setEntityRefToScroll(response.data?.redirectEntityUid)
                    getEntityDetails(response.data?.redirectEntityUid, false, true)
                }
            }
        }).catch(err => {
            setPutLoading(false)
            if (err?.response?.status == "404" && err?.response?.data?.error && err?.response?.data?.error.length > 0) {
                notyf.error(err.response.data.error)
            } else
                notyf.error(t("mandat:generatedErrorMessage"))
        });
        if (e !== null)
            e.preventDefault();
    }

    const searchAdvertiserEntityBySiretOrUpdateNewEntity = (siret) => {
        let filter = {
            siret: siret,
            category: "Annonceur",
        }
        Axios.get('/entity', {
            params: filter
        })
            .then(res => {
                if (res?.data?.entities?.length > 0) {
                    if (!(res.data.entities[0].uid in entitiesRefList)) {
                        setEntitiesSearchQuery('');
                        setEntitiesCategory('');
                        let entitiesSearchQueryTemp = ''
                        let categoryTemp = ''
                        getEntitiesList(entitiesSearchQueryTemp, false, categoryTemp)
                    }
                    setEntityRefToScroll(res.data.entities[0].uid)
                    getEntityDetails(res.data.entities[0].uid, false, true)
                } else {
                    let entityTemp = { ...entity }
                    entityTemp.identNumbers = entity?.identNumbers?.filter(u => u.type !== "SIRET")
                    if (entityTemp.identNumbers === undefined)
                        entityTemp.identNumbers = []
                    entityTemp.identNumbers.push({ 'new': true, 'type': 'SIRET', 'number': siret })
                    entityTemp.identNumToAdd = entityTemp.identNumbers
                    updateEntityWithApiClickHandler(null, entityTemp)
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage"));
            })
    }

    const resetEntityFormHandler = () => {
        setEntity({
            category: "Annonceur"
        })
    }

    const deleteEntityClickHandler = () => {
        return new Promise((resolve, reject) => {
            if (entity) {
                setPutLoading(true)
                Axios.delete('entity/' + entity.uid + '/entity').then(response => {
                    setPutLoading(false)
                    notyf.success(t("masterData:groupDeleted"));
                    const newEntitiesList = [...entitiesList];
                    const index = newEntitiesList.findIndex(e => e.uid === entity.uid);
                    newEntitiesList.splice(index, 1);
                    setEntitiesList(newEntitiesList);
                    setEntity({ category: "Annonceur" });
                    setFormChanged(false);
                    resolve(response);
                }).catch(response => {
                    setPutLoading(false)
                    notyf.error(t("mandat:generatedErrorMessage"))
                    reject(response)
                })
            } else {
                notyf.error(t("default:fillAllInputsMessage"))
                reject("noGroupSelected")
            }
        })
    }

    const addGroupclickHandler = (e) => {
        e.preventDefault();
        let groupToAdd = {
            ...group,
            representative: group?.representative ?
                group.representative
                : (group?.entities && group?.entities?.length > 0) ?
                    group?.entities[0].uid
                    : null
        }
        if (group.representative !== null) {
            let entityIndex = group?.entities?.findIndex(row => row.uid === group.representative);
            if (entityIndex < 0) {
                groupToAdd.representative = group?.entities[0].uid
            }
        }
        if (group) {
            setPutLoading(true)
            Axios.post('group', groupToAdd).then(response => {
                setPutLoading(false)
                if (response.data.hasOwnProperty('existNameError')) {
                    notyf.error(t("default:groupExist"));
                } else {
                    notyf.success(t("default:newGroup"))
                    setGroup({ category: "Annonceur" });
                    getgroupesList();
                }
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const updateGroupclickHandler = (e) => {
        e.preventDefault();
        let groupToUpdate = {
            ...group,
            representative: group?.representative ?
                group.representative
                : (group?.entities && group?.entities?.length > 0) ?
                    group?.entities[0].uid
                    : null
        }
        if (group.representative !== null) {
            let entityIndex = group?.entities?.findIndex(row => row.uid === group.representative);
            if (entityIndex < 0) {
                groupToUpdate.representative = group?.entities[0].uid
            }
        }
        let groupUid = group.uid
        if (entity) {
            setPutLoading(true)
            Axios.put('group/' + groupUid + '/group', groupToUpdate).then(response => {
                setPutLoading(false)
                notyf.success(t("default:groupUpdate"));
                const newGroupList = [...groupesList];
                const editedGroup = newGroupList.find(g => g.uid === group.uid);
                editedGroup.label = groupToUpdate.label;
                setGroupesList(newGroupList);
                setGroup(groupToUpdate);
                setFormChanged(false);
            }).catch(response => {
                setPutLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"))
            })
        } else {
            notyf.error(t("default:fillAllInputsMessage"))
        }
    }

    const deleteGroupClickHandler = () => {
        const promise = new Promise((resolve, reject) => {
            if (group) {
                setPutLoading(true)
                Axios.delete('group/' + group.uid + '/group').then(response => {
                    setPutLoading(false)
                    notyf.success(t("masterData:groupDeleted"));
                    const newGroupList = [...groupesList];
                    const index = newGroupList.findIndex(g => g.uid === group.uid);
                    newGroupList.splice(index, 1);
                    setGroupesList(newGroupList);
                    setGroup({ category: "Annonceur" });
                    setFormChanged(false);
                    resolve(response);
                }).catch(response => {
                    setPutLoading(false)
                    notyf.error(t("mandat:generatedErrorMessage"))
                    reject(response)
                })
            } else {
                notyf.error(t("default:fillAllInputsMessage"))
                reject("noGroupSelected")
            }
        })
        return promise;
    }

    const getUserColor = (user) => {
        if (user?.status === "Nouveau") {
            return "indianred"
        } else if (user?.status === "Modifié")
            return 'goldenrod'
        else
            return "lightblue"
    }

    const checkSelectedUserPending = (userUid) => {
        if (usersPending && usersPending?.length > 0) {
            let usersPendingsUid = usersPending?.map(user => user?.userUid);
            return usersPendingsUid.findIndex(uid => userUid === uid) >= 0
        } else {
            return false
        }
    }

    const handlePageChangeModal = callback => {
        if (formChanged) {
            const modal = React.createElement(PageChangeModal, { handleClose: confirm => { setPageChangeModal(null); if (confirm) setFormChanged(false) }, callback: callback });
            setPageChangeModal(modal);
        } else
            callback();
    }

    const handleRedirectEntityModal = callback => {
        const modal = React.createElement(RedirectEntityModal, { handleClose: confirm => { setRedirectEntityModal(null); /*if(confirm) setFormChanged(false)*/ }, callback: callback });
        setRedirectEntityModal(modal);
    }

    return (
        <div className="masterData_container" style={{ marginBottom: "30px" }}>
            <Prompt when={formChanged} message={(location, action) => location.pathname.startsWith("/masterData") ? true : t('masterData:pageChangeModal', "Voulez-vous vraiment quitter la page ? Toutes les modifications non sauvegardées seront perdues.")} />
            {pageChangeModal}
            {redirectEntityModal}
            <div className="masterData_sides_container" >
                {/* Partie gauche */}
                <div className="masterData_leftSide_container" >
                    <div className="leftSide_header_container">
                        <div className="header_actionTypes_container">
                            {typeMasterData === TYPE_USERS ?
                                (
                                    <div className="header_actionTypes_items ">
                                        <img src={iconUser} className="header_actionTypes_icon"></img>
                                        <span style={{ fontSize: "1rem" }}>
                                            {t('default:users', "Utilisateurs")}
                                        </span>
                                    </div>
                                ) : typeMasterData === TYPE_COMPANIES ?
                                    (
                                        <div className="header_actionTypes_items ">
                                            <img src={iconEntity} className="header_actionTypes_icon"></img>
                                            <span style={{ fontSize: "1rem" }}>
                                                {t('default:companies', "Entreprises")}
                                            </span>
                                        </div>
                                    ) : typeMasterData === TYPE_GROUPS ?
                                        (
                                            <div className="header_actionTypes_items ">
                                                <GroupIcon className="header_actionTypes_icon" style={{ backgroundColor: "green", color: "white", borderRadius: "0.25rem", cursor: "pointer" }} fontSize="large" />
                                                <span style={{ fontSize: "1rem" }}>
                                                    {t('default:groups', "Groupes")}
                                                </span>
                                            </div>
                                        ) : typeMasterData === TYPE_PARAMETERS ?
                                            (
                                                <div className="header_actionTypes_items ">
                                                    <SettingsRoundedIcon className="header_actionTypes_icon" style={{ backgroundColor: "grey", color: "white", borderRadius: "0.25rem", cursor: "pointer" }} fontSize="large" />
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {t('default:parameters', "Paramètres")}
                                                    </span>
                                                </div>
                                            ) : null
                            }
                            <div className="dropdown header_actionTypes_dropdown" >
                                <button className="btn btn-secondary dropdown-toggle text-dark bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#" onClick={() => handlePageChangeModal(() => setTypeMasterData('utilisateurs'))}><img src={iconUser} className="div-icon-user"  ></img>{t('default:users', "Utilisateurs")}</a>
                                    <a className="dropdown-item" href="#" onClick={() => handlePageChangeModal(() => setTypeMasterData('entreprises'))}><img style={{ width: '28%', marginRight: '9px' }} src={iconEntity}  ></img>{t('default:companies', "Entreprises")} </a>
                                    {
                                        allowedToViewGroups.includes(props.role) &&
                                        <a className="dropdown-item" href="#" onClick={() => handlePageChangeModal(() => setTypeMasterData('groupes'))}><GroupIcon style={{ backgroundColor: "green", color: "white", borderRadius: "5px", marginRight: "10px", cursor: "pointer" }} fontSize="large" />{t('default:groups', "Groupes")}</a>
                                    }
                                    {
                                        "MG, AE".includes(props.role) ? (
                                            <a className="dropdown-item" href="#" onClick={() => handlePageChangeModal(() => setTypeMasterData('parametres'))}><SettingsRoundedIcon style={{ backgroundColor: "grey", color: "white", borderRadius: "5px", marginRight: "10px", cursor: "pointer" }} fontSize="large" />{t('default:parameters', "Paramètres")}</a>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        {typeMasterData === TYPE_USERS ? (
                            (props.role === "AE" | props.role === "AG" || props.role === "MG" || props.role === "RG" || props.role === "MR") ? (
                                <div className="header_addIcon_container" onClick={() => {
                                    handlePageChangeModal(() => {
                                        setUser({
                                            is_legal_representative: false,
                                            is_signataire: false,
                                            status: "Nouveau"
                                        })
                                        setUserCopy({})
                                    });
                                }} >
                                    <i style={{ color: '#0070D2' }} className="fas fa-plus"  ></i>
                                </div>
                            ) : null
                        ) : typeMasterData === TYPE_COMPANIES ? (
                            (props.role === "AE" || props.role === "MG" || props.role === "MR") ? (
                                <div className="header_addIcon_container" onClick={() => handlePageChangeModal(resetEntityFormHandler)} >
                                    <i style={{ color: '#0070D2' }} className="fas fa-plus"  ></i>
                                </div>
                            ) : null
                        ) : typeMasterData === TYPE_GROUPS ? (
                            allowedToAddGroups.includes(props.role) && (
                                <div className="header_addIcon_container" onClick={() => handlePageChangeModal(() => setGroup({ category: "Annonceur" }))} >
                                    <i style={{ color: '#0070D2' }} className="fas fa-plus"  ></i>
                                </div>)
                        ) : null}

                    </div>

                    {typeMasterData !== TYPE_PARAMETERS ? (
                        <div className="leftSide_searchInput_container">
                            <label className="searchInput_label" htmlFor="search">{t('default:search', "Rechercher")} </label>
                            <div className="searchInput_input_container">
                                {
                                    typeMasterData === TYPE_USERS ?
                                        (<>
                                            {
                                                usersSearchQuery ?
                                                    <i className="fas fa-times clearInput_input_icon" onClick={() => clearSearch()}></i>
                                                    :
                                                    null
                                            }
                                            <i className="fas fa-search searchInput_input_icon" onClick={() => getUserListFunc()}></i>
                                            <input
                                                type="text"
                                                src={iconList}
                                                className="form-control searchInput_input"
                                                name="searchUser"
                                                id="search"
                                                placeholder={t('masterData:searchPlaceholder', "Nom, fonction, entreprise, Email")}
                                                onChange={(e) => searchUsersHandler(e)}
                                                value={usersSearchQuery || ''}
                                                onKeyDown={handleKeyPress}
                                            ></input>
                                        </>)
                                        : typeMasterData === TYPE_COMPANIES ?
                                            (<>
                                                {
                                                    entitiesSearchQuery ?
                                                        <i className="fas fa-times clearInput_input_icon" onClick={() => clearSearch()}></i>
                                                        :
                                                        null
                                                }
                                                <i className="fas fa-search searchInput_input_icon" onClick={() => getEntitiesListFunc()}></i>

                                                <input
                                                    type="text"
                                                    src={iconList}
                                                    className="form-control input-field"
                                                    name="searchCompagny"
                                                    id="search"
                                                    placeholder={t('masterData:companyName', "Dénomination sociale, nom commercial, SIRET")}
                                                    value={entitiesSearchQuery || ''}
                                                    onChange={(e) => searchEntitiesHandler(e)}
                                                />
                                            </>)
                                            : typeMasterData === TYPE_GROUPS ?
                                                (<>
                                                    {
                                                        groupesSearchQuery ?
                                                            <i className="fas fa-times clearInput_input_icon" onClick={() => clearSearch()}></i>
                                                            :
                                                            null
                                                    }
                                                    <i className="fas fa-search searchInput_input_icon" onClick={() => getgroupesListFunc()}></i>
                                                    <input
                                                        type="text"
                                                        src={iconList}
                                                        className="form-control input-field"
                                                        name="searchGroupes"
                                                        id="search"
                                                        placeholder={t('masterData:groupName', "Nom du groupe")}
                                                        value={groupesSearchQuery || ''}
                                                        onChange={(e) => searchGroupesHandler(e)}
                                                    />
                                                </>)
                                                : null
                                }
                            </div>
                            {
                                ((typeMasterData === TYPE_COMPANIES) && "AE,MG,MR,MC".includes(props.role)) ?
                                    (<div className="searchInput__categorySelect mt-2">
                                        <select
                                            className="form-control"
                                            name="category"
                                            placeholder="Type"
                                            value={entitiesCategory || ''}
                                            onChange={(e) => entitiesCategoryChangeHandler(e.target.value)}
                                        >
                                            <option value="" disabled selected hidden>-- Type --</option>
                                            <option value=""></option>
                                            <option value="Annonceur">{t('default:advertiser', "Annonceur")} </option>
                                            <option value="Agence">{t('default:agency', "Agence")} </option>
                                            <option value="Regie">{t('default:regie', "Régie")}</option>

                                        </select>
                                    </div>)
                                    : null
                            }
                        </div>
                    ) : null

                    }

                    {/* Info list */}

                    <div className="leftSide_listInfos_container">
                        <div className="listInfos_elementsCount_container" style={{ color: "#706E6B" }}>
                            <span className="nbItems">
                                {typeMasterData === TYPE_USERS ?
                                    usersList?.length
                                    : typeMasterData === TYPE_COMPANIES ?
                                        entitiesList?.length
                                        : typeMasterData === TYPE_GROUPS ?
                                            groupesList?.length
                                            : typeMasterData === TYPE_PARAMETERS ?
                                                null : 0} {typeMasterData !== TYPE_PARAMETERS ? (t('default:elements', "Élement(s)")) : null}
                            </span>
                        </div>
                        {
                            (props.role === "AE" || props.role === "AG") && (typeMasterData !== TYPE_PARAMETERS) ?
                                <AEListGuide />
                                : null
                        }

                    </div>

                    {
                        typeMasterData !== TYPE_PARAMETERS ? (

                            <div className="leftSide_listSort_container">
                                <p className="listSort_container" style={{ color: "#706E6B", cursor: "pointer" }}
                                    onClick={(typeMasterData === TYPE_COMPANIES) ? (() => handleSortedCompany(!sortCompany))
                                        : (typeMasterData === TYPE_USERS) ? (() => handleSortedUsers(!sortUsers))
                                            : (() => handleSortedGroups(!sortGroups))}
                                >
                                    <span> {typeMasterData === TYPE_USERS ?
                                        t('default:users', "Utilisateurs").toUpperCase()
                                        : typeMasterData === TYPE_COMPANIES ?
                                            t('default:companies', "Entreprises").toUpperCase()
                                            : t('default:groups', "Groupes")} </span>
                                    {
                                        (typeMasterData === TYPE_COMPANIES) ?
                                            !sortCompany ? (
                                                <ArrowDownwardIcon
                                                    style={{
                                                        fontSize: "1rem",
                                                        color: "darkslategray",
                                                        marginLeft: "10px"
                                                    }} />
                                            ) : (
                                                <ArrowUpwardIcon
                                                    style={{
                                                        fontSize: "1rem",
                                                        color: "darkslategray",
                                                        marginLeft: "10px"
                                                    }}
                                                />
                                            )
                                            : (typeMasterData === TYPE_USERS) ?
                                                !sortUsers ? (
                                                    <ArrowDownwardIcon
                                                        style={{
                                                            fontSize: "1rem",
                                                            color: "darkslategray",
                                                            marginLeft: "10px"
                                                        }} />
                                                ) : (
                                                    <ArrowUpwardIcon
                                                        style={{
                                                            fontSize: "1rem",
                                                            color: "darkslategray",
                                                            marginLeft: "10px"
                                                        }}
                                                    />
                                                )
                                                :
                                                !sortGroups ? (
                                                    <ArrowDownwardIcon
                                                        style={{
                                                            fontSize: "1rem",
                                                            color: "darkslategray",
                                                            marginLeft: "10px"
                                                        }} />
                                                ) : (
                                                    <ArrowUpwardIcon
                                                        style={{
                                                            fontSize: "1rem",
                                                            color: "darkslategray",
                                                            marginLeft: "10px"
                                                        }}
                                                    />
                                                )
                                    }

                                </p>
                            </div>
                        ) : (
                            <>
                                {
                                    props.role == "MG" ?
                                        (
                                            <div className="leftSide_buttons_container">
                                                <div
                                                    className={parameter === PARAMETER_CLAUSE ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                    key="media"
                                                    onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_CLAUSE))}
                                                >
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <div className="listElements_container__element">{t("masterData:clauses", "Clauses")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                }
                                {
                                    props.role == "AE" ?
                                        (
                                            <div className="leftSide_buttons_container">
                                                <div
                                                    className={parameter === PARAMETER_MEDIA ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                    key="media"
                                                    onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_MEDIA))}
                                                >
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <div className="listElements_container__element">{t("masterData:medias", "Médias")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                }{
                                    "MG, AE".includes(props.role) ?
                                        (
                                            <div className="leftSide_buttons_container">
                                                <div
                                                    className={parameter === PARAMETER_MISSION ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                    key="media"
                                                    onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_MISSION))}
                                                >
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <div className="listElements_container__element">{t("masterData:missions", "Missions")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null

                                }{
                                    props.role == "AE" ?
                                        (
                                            <>
                                                <div className="leftSide_buttons_container">
                                                    <div
                                                        className={parameter === PARAMETER_MARKETING ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                        key="media"
                                                        onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_MARKETING))}
                                                    >
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <div className="listElements_container__element">{t("masterData:marketingModes", "Modes de commercialisation")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="leftSide_buttons_container">
                                                    <div
                                                        className={parameter === PARAMETER_GEO ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                        key="geo"
                                                        onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_GEO))}
                                                    >
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <div className="listElements_container__element">{t("masterData:geographicalZones", "Zones géographiques")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="leftSide_buttons_container">
                                                    <div
                                                        className={parameter === PARAMETER_DIRECTION_FUNCTIONS ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                        key="funct"
                                                        onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_DIRECTION_FUNCTIONS))}
                                                    >
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <div className="listElements_container__element">{t("masterData:geograes", "Fonctions & direction")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="leftSide_buttons_container">
                                                    <div
                                                        className={parameter === PARAMETER_DIRECTION_DEPARTMENT ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                                        key="depart"
                                                        onClick={() => handlePageChangeModal(() => setParameter(PARAMETER_DIRECTION_DEPARTMENT))}
                                                    >
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <div className="listElements_container__element">{t("masterData:geograes", "Départements & direction")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        : null
                                }
                            </>

                        )
                    }
                    <div className="leftSide_listElements_container">
                        {
                            getLoading && typeMasterData === TYPE_USERS ?
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                                : (usersList && usersList?.length > 0 && typeMasterData === TYPE_USERS) ?
                                    usersList?.map((userItem, index) =>
                                        <div
                                            className={
                                                (userItem.userUid === user?.userUid || checkSelectedUserPending(userItem?.userUid)) ?
                                                    "col-12 list-group-item-action listElement_container_selected"
                                                    :
                                                    "col-12 list-group-item-action listElement_container"
                                            }
                                            key={index}
                                            onClick={() => {
                                                ((userItem.userCopyUid && activeValidation === 1) ? getUserCopyDetails(userItem.userCopyUid) : getUserDetails(userItem.userUid))
                                            }
                                            }

                                        >
                                            <div className="row" style={{ alignItems: "center" }}>
                                                <div className="col-10">
                                                    <div className="listElements_container__element">
                                                        {
                                                            (userItem?.status === "Nouveau" && +userItem?.userOccurence > 1) ?
                                                                <strong> {userItem?.email} </strong>
                                                                :
                                                                <strong> {`${userItem.first_name} ${userItem.last_name}`}</strong>
                                                        }
                                                        {("AE, AG".includes(props.role) && +userItem.pending === 1) ?
                                                            <p
                                                                className="pending_status"
                                                                style={{
                                                                    backgroundColor: getUserColor(userItem)
                                                                }}
                                                            >?</p>
                                                            : ""
                                                        }
                                                    </div>
                                                    {
                                                        (userItem?.status === "Nouveau" && +userItem?.userOccurence) > 1 ?
                                                            <p> Plusieurs demandes </p>
                                                            :
                                                            <div>{userItem.entities ? userItem?.entities.map(
                                                                (entity, entityIndex) => (entityIndex + 1) !== userItem?.entities.length ? entity.commercial_name + ', ' : entity.commercial_name
                                                            ) : null}
                                                            </div>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {userItem?.role}
                                                </div>
                                                {/* <div className="col-12">
                                            <div><strong> {userItem.first_name} {userItem.last_name}</strong> 
                                                {   userItem.pending === "1"   ?
                                                        <p className="nb_events">!</p>
                                                    : ""
                                                }
                                            </div>
                                        </div> */}
                                            </div>
                                        </div>
                                    )
                                    : null
                        }
                        {
                            getLoading && typeMasterData === TYPE_COMPANIES ?
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                                : (entitiesList && entitiesList.length > 0 && typeMasterData === TYPE_COMPANIES) ?
                                    entitiesList.map((ent, index) =>
                                        <div
                                            className={ent.uid === entity?.uid ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                            key={index}
                                            onClick={() => getEntityDetails(ent.uid)}
                                            ref={entitiesRefList[ent.uid]}
                                        >
                                            <div className="row">
                                                <div className="col-7">
                                                    <div className="listElements_container__element">
                                                        {ent.official_name}
                                                        {
                                                            (props.role === "AE" && ent?.status === "CREATION_REQ") ?
                                                                <p
                                                                    className="pending_status"
                                                                    style={{
                                                                        backgroundColor: 'indianred'
                                                                    }}
                                                                >? </p>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div>{ent.commercial_name}</div>
                                                </div>
                                                <div className="col-4 offset-1 marque">{ent.category == "Regie" ? "Régie" : ent.category} </div>
                                            </div>
                                        </div>)
                                    : null
                        }
                        {
                            getLoading && typeMasterData === TYPE_GROUPS ?
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                                : (groupesList && groupesList.length > 0 && typeMasterData === TYPE_GROUPS) ?
                                    groupesList.map((grp, index) =>
                                        <div
                                            className={grp.uid === group?.uid ? "col-12 list-group-item-action listElement_container_selected" : "col-12 list-group-item-action listElement_container"}
                                            key={index}
                                            onClick={() => getGroupDetails(grp.uid)}
                                        >
                                            <div className="row">
                                                <div className="col-5">
                                                    <strong>{grp.label}</strong>
                                                </div>
                                            </div>
                                        </div>)
                                    : null
                        }

                        {/* {listEntity} */}
                    </div>
                </div>


                {/* Partie droite */}
                <div className="masterData_rightSide_container" >
                    {
                        typeMasterData === TYPE_USERS ?
                            (user?.pending == 1 && usersPending && (props.role === "AE" || props.role === "AG")) ?
                                <UserFormValidation
                                    usersPending={usersPending}
                                    inputUserChange={inputUserChangeHandler}
                                    user={user}
                                    setUser={setUser}
                                    addUser={addUserclickHandler}
                                    updateUser={updateUserclickHandler}
                                    validateUsercopy={validateUsercopy}
                                    resignUsercopy={resignUsercopy}
                                    validateUserAdd={validateUserAdd}
                                    resignUserAdd={resignUserAdd}
                                    validateUser={validateUserclickHandler}
                                    desactivateUser={desactivateUserclickHandler}
                                    handleFormChanged={setFormChanged}
                                    loading={putLoading}
                                />
                                :
                                <UserForm
                                    activeValidation={activeValidation}
                                    setUserRoleChanged={setUserRoleChanged}
                                    userRoleChanged={userRoleChanged}
                                    user={user}
                                    mapUserRole2CompanyCategory={mapUserRole2CompanyCategory}
                                    fonctions={functionsList}
                                    getFunctions={getFunctions}
                                    departements={departmentsList}
                                    getUsersList={getUsersList}
                                    setUser={setUser}
                                    inputUserChange={inputUserChangeHandler}
                                    addUser={addUserclickHandler}
                                    updateUser={updateUserclickHandler}
                                    deleteUser={deleteUserClickHandler}
                                    validateUser={validateUserclickHandler}
                                    desactivateUser={desactivateUserclickHandler}
                                    userCopy={userCopy}
                                    addUserCopy={addUserCopyclickHandler}
                                    inputUserCopyChange={inputUserCopyChangeHandler}
                                    updateUserCopy={updateUserCopyclickHandler}
                                    entitiesList={entitiesList}
                                    reloadUserList={getUsersList}
                                    reloadUserDetails={getUserDetails}
                                    handleFormChanged={setFormChanged}
                                    loading={putLoading}
                                />
                            : typeMasterData === TYPE_COMPANIES ?
                                <>
                                    <CompanyForm
                                        entity={entity}
                                        validEntityData={validEntityData}
                                        setValidEntityData={setValidEntityData}
                                        setEntity={setEntity}
                                        addEntity={addEntityclickHandler}
                                        validateEntity={validateEntityclickHandler}
                                        disableEntity={disableEntityclickHandler}
                                        updateEntity={updateEntityclickHandler}
                                        cancelEntity={cancelEntityclickHandler}
                                        updateEntityWithApi={updateEntityWithApiClickHandler}
                                        deleteEntity={deleteEntityClickHandler}
                                        handleFormChanged={setFormChanged}
                                        formChanged={formChanged}
                                        loading={putLoading}
                                        role={props.role}
                                        authUserId={props.authUserId}
                                    />
                                    {
                                        showEntitySiretsModal &&
                                        <SiretsModal
                                            handleClose={() => setShowEntitySiretsModal(false)}
                                            show={showEntitySiretsModal}
                                            sirets={entitySirets}
                                            searchAdvertiserEntityBySiretOrUpdateNewEntity={searchAdvertiserEntityBySiretOrUpdateNewEntity}
                                        />
                                    }
                                </>
                                : typeMasterData === TYPE_GROUPS ?
                                    <GroupForm
                                        group={group}
                                        setGroup={setGroup}
                                        addGroup={addGroupclickHandler}
                                        updateGroup={updateGroupclickHandler}
                                        deleteGroup={deleteGroupClickHandler}
                                        handleFormChanged={setFormChanged}
                                        loading={putLoading}
                                    />
                                    : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_GEO ?
                                        <ListManagerGeo
                                            handleFormChanged={setFormChanged}
                                        />
                                        : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_MEDIA ?
                                            <ListManagerMedia
                                                handleFormChanged={setFormChanged}
                                            />
                                            : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_MARKETING ?
                                                <ListManagerMarketing
                                                    handleFormChanged={setFormChanged}
                                                />
                                                : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_MISSION ?
                                                    <ListManagerMission
                                                        handleFormChanged={setFormChanged}
                                                        entitiesList={entitiesList}
                                                        role={props.role}
                                                    />
                                                    : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_CLAUSE ?
                                                        <ListManagerClause
                                                            handleFormChanged={setFormChanged}
                                                            entitiesList={entitiesList}
                                                        />
                                                        : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_DIRECTION_FUNCTIONS ?
                                                            <ListManagerDirectionFunctions
                                                                handleFormChanged={setFormChanged}
                                                                // entitiesList={entitiesList}
                                                                role={props.role}
                                                            /> : typeMasterData === TYPE_PARAMETERS && parameter === PARAMETER_DIRECTION_DEPARTMENT ?
                                                                <ListManagerDirectionDepartments
                                                                    handleFormChanged={setFormChanged}
                                                                    // entitiesList={entitiesList}
                                                                    role={props.role}
                                                                />
                                                                : null
                    }
                    {/* {!isCompagnyState ?
                        <AddUser badgeCleaner={handleCleanUserEntityToAdd} badgeEntityCleaner={handleDeleteBadgeEntityToUser} propsUserToForm = {userToForm} userSelected = {userSelected} userEntityToAdd={userEntityToAdd} userFindId={userToForm.id}/>
                        :
                        <AddCompagny cleanAddAddress={cleanAddAddress} cleaner={handleAddNewEntry} propsEntityToForm = {entityToForm} entitySelected = {entitySelected} boolAddNewEntity={boolAddNewEntity} entityFindId={entityToForm.id}/>
                    } */}
                </div>


            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    role: state.auth.role,
    authUserId: state.auth.userId
})
const mapDispatchToProps = dispatch => ({
    // getCountMasterDatasPending: () => dispatch(actions.getCountMasterDatasPending())  //pas utilisé car on utilise pas flag pending
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterData)