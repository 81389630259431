import React, { useEffect, useState } from 'react'
import './CreatorChangedEvent.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PersonIcon from '@material-ui/icons/Person';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function CreatorChangedEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
           <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <PersonIcon style={{backgroundColor: "rgb(19, 214, 136)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:creatorChanged', "Responsable modifié")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(19, 214, 136)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        
                            {
                                isCreator ? t('timeline:creatorChangedBySelf', "Vous avez modifié le responsable de ce mandat")  
                                : 
                                eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:creatorChangedByCreator', "a modifié le responsable de ce mandat")
                            } 
                            <br/><br/>
                            <p>{t('timeline:newCreator', "Nouveau responsable : ") + eventData?.data?.newCreator?.first_name + ' ' + eventData?.data?.newCreator?.last_name }</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default CreatorChangedEvent